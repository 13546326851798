import { t } from "@lingui/macro";

export const sort_list = [
    { key: 8, text: t`Alphabetical`, value: 8 },
    { key: 1, text: t`Alerts (health scores)`, value: 1 },
    { key: 2, text: t`Last value`, value: 2 },
    { key: 3, text: t`Index`, value: 3 },
    { key: 4, text: t`Conso 24h`, value: 4 },
    { key: 5, text: t`Cost 24h`, value: 5 },
    { key: 6, text: t`Emissions 24h`, value: 6 },
    { key: 7, text: t`Last sensor activity`, value: 7 }
];

export const categories_with_units = [
    { category: 2, units: [5, 8, 11, 42, 6, 7, 18, 16, 48, 666] },
    { category: 3, units: [11, 16, 42, 48] },
    { category: 4, units: [11, 16, 42, 48] }
];
