import React, { useMemo, useState } from 'react';
import _ from "lodash";
import { Handle, Position } from 'reactflow';
import { Header, Popup, Segment } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';
import { diagramTypeOptions } from 'modules/hierarchy/utils';

import AddNode from './AddNode';
import UpdateNode from './UpdateNode';
import DeleteNode from './DeleteNode';
import Infos from 'modules/dashboard/components/widgets/Infos';
import NodeContent from './NodeContent';


const GenericNode = (props) => {
    const { data, selected, type, xPos, yPos } = props;
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);

    //Display unit based on diagram_type
    const unit = useMemo(() => {
        return _.chain(diagramTypeOptions)
            .find({ value: data.extra.diagram_type })
            .get('unit')
            .defaultTo('-')
            .value();
    }, [data.extra.diagram_type]);

    const disabled_diagram = data?.extra?.disabled_diagram;

    const dataWithPosition = {
        ...data,
        node_db: {
            ...data.node_db,
            position_x: xPos,
            position_y: yPos
        }
    };

    const customMainStyle = type === "equipmentUnlink" ? { border: 'dashed 1px' } : null;
    const nodeStyle = { flex: 1, display: 'flex', flexDirection: 'column', margin: 0, cursor: selected ? "grab" : "default" };

    const sites = dataWithPosition?.extra?.sites ?? [];
    const usages = dataWithPosition?.extra?.usages ?? [];
    const full_equipment = _.chain(dataWithPosition)
        .get('extra.equipments', [])
        .find({ id: data?.node_db?.equipment })
        .defaultTo(null)
        .value();

    return (
        <>
            {openModalDelete && (
                <DeleteNode
                    openModal={true}
                    setOpenModal={setOpenModalDelete}
                    i18n={i18n}
                    data={dataWithPosition}
                />
            )}
            {openModalUpdate && (
                <UpdateNode
                    openModal={true}
                    setOpenModal={setOpenModalUpdate}
                    i18n={i18n}
                    data={dataWithPosition}
                />
            )}
            {openModalAdd && (
                <AddNode
                    openModal={true}
                    setOpenModal={setOpenModalAdd}
                    i18n={i18n}
                    data={dataWithPosition}
                />
            )}
            {dataWithPosition.node_db.parent && (
                <Handle
                    type="target"
                    position={Position.Top}
                    style={{ zIndex: 1000 }}
                    isConnectable={false}
                />
            )}
            {type === "equipmentUnlink" && (
                <Segment color={selected ? "red" : null}
                    style={{ ...nodeStyle, ...customMainStyle }}
                    onDoubleClick={(e) => { setOpenModalUpdate(true); }}
                    id={`node-container-${props.id}`}
                >
                    <NodeContent
                        id={props.id}
                        selected={selected}
                        dataWithPosition={dataWithPosition}
                        unit={unit}
                        disabled_diagram={disabled_diagram}
                        setOpenModalAdd={setOpenModalAdd}
                        setOpenModalUpdate={setOpenModalUpdate}
                        setOpenModalDelete={setOpenModalDelete}
                    />
                </Segment>
            )}
            {type === "equipmentLink" && (
                <Popup
                    basic
                    position='top center'
                    style={{ padding: 0 }}
                    trigger={
                        <Segment color={selected ? "red" : null}
                            style={{ ...nodeStyle, ...customMainStyle }}
                            onDoubleClick={(e) => { setOpenModalUpdate(true); }}
                            id={`node-container-${props.id}`}
                        >
                            <NodeContent
                                id={props.id}
                                selected={selected}
                                dataWithPosition={dataWithPosition}
                                unit={unit}
                                disabled_diagram={disabled_diagram}
                                setOpenModalAdd={setOpenModalAdd}
                                setOpenModalUpdate={setOpenModalUpdate}
                                setOpenModalDelete={setOpenModalDelete}
                            />
                        </Segment>
                    }
                >
                    <Popup.Content>
                        <Header attached='top' block>{dataWithPosition.node_db.equipment_name}</Header>
                        <Segment attached>
                            {full_equipment !== null && (
                                <Infos equipment={full_equipment} display="list" sites={sites} usages={usages} />
                            )}
                        </Segment>
                    </Popup.Content>
                </Popup>
            )}
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={false}
            />
        </>
    );
};

export default GenericNode;