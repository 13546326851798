import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { t } from "@lingui/macro";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { processAggregateTime } from "modules/time/utils";
import { useGetAnalysisSectionDataQuery } from "../analysisAdvancedService";
import { processAnalysisSectionData } from "../processing";

import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicExtensive from "./view/GraphicExtensive";
import AlertPanel from "./view/AlertPanel";
import SynthesisPanel from "./view/SynthesisPanel";
import RadialPanel from "./view/RadialPanel";

const AnalysisSectionView = (props) => {
    const {
        time_periods: { ref_time, comp_time },
        aggregation,
        org,
        analysis_section,
        sites,
        zones,
        usages,
        tags,
        categories
    } = props;

    const current_lng = useSelector((state) => state.i18n.current);

    const { graph_type, include_alerts, include_synthetic_table, id, reference_level, threshold_min, threshold_max } = analysis_section;
    const thresholds = { reference_level, threshold_min, threshold_max };

    const ref_params = useMemo(() => {
        return {
            org,
            analysis_section_id: id,
            aggregation,
            start: processAggregateTime(ref_time.start, aggregation).format("YYYY-MM-DD"),
            end: ref_time.end.format("YYYY-MM-DD"),
            key: "ref",
            lng: current_lng
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregation, org, ref_time, id]);

    const ref_item = useGetAnalysisSectionDataQuery(ref_params);

    const { data: ref_data, isError: ref_isError, isFetching: ref_isFetching, error: ref_error } = ref_item;

    const comp_params = useMemo(() => {
        return {
            org,
            analysis_section_id: id,
            aggregation,
            start: processAggregateTime(comp_time?.start, aggregation)?.format("YYYY-MM-DD"),
            end: comp_time?.end?.format("YYYY-MM-DD"),
            key: "comp",
            lng: current_lng
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org, id, comp_time, aggregation]);

    const comp_item = useGetAnalysisSectionDataQuery(comp_params, { skip: comp_time === null });

    const { data: comp_data, isError: comp_isError, isFetching: comp_isFetching, error: comp_error } = comp_item;

    const isError = ref_isError || comp_isError;
    const isFetching = ref_isFetching || comp_isFetching;

    if (isError) {
        let error = t`error loading analysis section data`;
        if (ref_error?.originalStatus !== 500 && comp_error?.originalStatus !== 500) {
            if (ref_error?.data?.[0]) {
                error = ref_error?.data?.[0]; //error define in i18nConfig
            }
        }

        return <MessageDisplay message={i18n._(error)} level="error" iconName="warning circle" isLoading={false} attached={true} />;
    }
    if (isFetching) {
        return (
            <MessageDisplay
                message={i18n._(t`loading analysis section data`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
                attached={true}
            />
        );
    }
    const process_data = processAnalysisSectionData(ref_data, comp_time ? comp_data : null, analysis_section, {
        sites,
        zones,
        usages,
        tags,
        categories
    });
    return (
        <>
            {_.includes([1, 3], graph_type) && (
                <>
                    <GraphicExtensive
                        data={process_data.graphic}
                        time_periods={props.time_periods}
                        aggregation={aggregation}
                        thresholds={thresholds}
                    />
                    {include_synthetic_table && (
                        <SynthesisPanel data={process_data.graphic} time_periods={props.time_periods} aggregation={aggregation} />
                    )}
                    {include_alerts && (
                        <AlertPanel
                            data={process_data.graphic}
                            time_periods={props.time_periods}
                            analysis_section={analysis_section}
                            aggregation={aggregation}
                        />
                    )}
                </>
            )}
            {graph_type === 2 && <RadialPanel data={process_data.graphic} time_periods={props.time_periods} aggregation={aggregation} />}
        </>
    );
};

export default React.memo(AnalysisSectionView);
