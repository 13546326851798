import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";
import { defaultLng } from "modules/i18n/i18nConfig";

export const orgApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_org",
    keepUnusedDataFor: 600,
    tagTypes: ["Organizations", "currentOrganization", "orgPasswordPolicy", "orgExternalApiKeys", "orgGenerateApiKey"],
    endpoints: (build) => ({
        getOrganizations: build.query({
            keepUnusedDataFor: 0,
            query: () => {
                const current_org = _.get(JSON.parse(localStorage.getItem("currentOrg")), "name", null);
                const extraURL = current_org ? `?org=${current_org}&${refreshTimestampUrlParam()}` : `?${refreshTimestampUrlParam()}`;
                return `/organizations${extraURL}`;
            },
            providesTags: ["Organizations"],
            transformResponse: (response) => {
                const organizations = _.map(response, (org) => {
                    return {
                        ...org,
                        key: org.id,
                        text: org.full_name,
                        value: org.id
                    };
                });
                return { organizations, first: _.head(organizations) };
            }
        }),
        getCurrentOrganization: build.query({
            keepUnusedDataFor: 0,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `/organizations/current?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "currentOrganization", id: arg.org.id }];
                return [];
            }
        }),
        updateOrganization: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/organizations/current?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Organizations" }, { type: "currentOrganization", id: arg.org.id }];
                }
                return [];
            }
        }),
        //Policy
        getOrganizationPasswdPolicy: build.query({
            keepUnusedDataFor: 0,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `/organizations/password_policy?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "orgPasswordPolicy", id: arg.org.id }];
                return [];
            }
        }),
        addOrganizationPasswdPolicy: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/organizations/password_policy?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            }
        }),
        updateOrganizationPasswdPolicy: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/organizations/password_policy?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "orgPasswordPolicy", id: arg.org.id }];
                }
                return [];
            }
        }),
        deleteOrganizationPasswdPolicy: build.mutation({
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/organizations/password_policy?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "orgPasswordPolicy", id: arg.org.id }];
                }
                return [];
            }
        }),
        // API key for external API
        getExternalApiKeys: build.query({
            keepUnusedDataFor: 0,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `/extapikeys?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "orgExternalApiKeys", id: arg.org.id }];
            }
        }),
        getNewExternalApiKey: build.query({
            keepUnusedDataFor: 0,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `/extapikeys/generate_key?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "orgGenerateApiKey", id: arg.org.id }];
            }
        }),
        addNewExternalApiKey: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/extapikeys?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "orgExternalApiKeys", id: arg.org.id },
                        { type: "orgGenerateApiKey", id: arg.org.id }
                    ];
                }
                return [];
            }
        }),
        deleteExternalApiKey: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/extapikeys/${data}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "orgExternalApiKeys", id: arg.org.id },
                        { type: "orgGenerateApiKey", id: arg.org.id }
                    ];
                }
                return [];
            }
        })
    })
});

export const {
    useGetOrganizationsQuery,
    useGetCurrentOrganizationQuery,
    useUpdateOrganizationMutation,
    useGetOrganizationPasswdPolicyQuery,
    useAddOrganizationPasswdPolicyMutation,
    useUpdateOrganizationPasswdPolicyMutation,
    useDeleteOrganizationPasswdPolicyMutation,
    useGetExternalApiKeysQuery,
    useGetNewExternalApiKeyQuery,
    useAddNewExternalApiKeyMutation,
    useDeleteExternalApiKeyMutation
} = orgApi;
