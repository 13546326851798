import React, { useState } from 'react';
import { t } from '@lingui/macro';
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { Card, Segment } from 'semantic-ui-react';
import { FlexibleXYPlot, Hint, HorizontalGridLines, MarkSeries, XAxis, YAxis } from 'react-vis';

import i18n from 'modules/i18n/i18nConfig';
import GenerateCsv from 'modules/common/components/GenerateCsv';

import { Media } from 'App';
import MessageDisplay from 'modules/common/components/MessageDisplay';



const GraphicPumpHeadFlow = (props) => {
    const { SerieType, markSize } = props;

    const serie = props.serie ? {
        ...props.serie,
        name: i18n._(_.get(props, 'serie.name', "")),
        title: `${i18n._(_.get(props, 'serie.name', ''))} ${`(${_.get(props, 'serie.unit', "")})`}`,
    } : null;

    const [hintValue, setHintValue] = useState(null);

    const tooltipHint = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip">
                    <Card.Content>
                        <Card.Header>
                            {hintValue.x === null && `- ${_.get(hintValue, 'unit_x')}`}
                            {hintValue.x !== null && `${i18n.number(hintValue.x, { maximumFractionDigits: 2 })} ${_.get(hintValue, 'unit_x')}`}
                        </Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description style={{ color: tinycolor(hintValue.color).setAlpha(1).toString() }}>
                            {hintValue.y_real === null && `- ${_.get(hintValue, 'unit')}`}
                            {hintValue.y_real !== null && `${i18n.number(hintValue.y_real, { maximumFractionDigits: 2 })} ${_.get(hintValue, 'unit')}`}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    const emptyData = _.isNull(serie);

    if (emptyData) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    return (
        <>
            <Media greaterThanOrEqual="computer">
                {(mediaClassNames, renderChildren) =>
                    renderChildren && (
                        <Segment attached textAlign='right'>
                            <GenerateCsv series={[serie,]} filename={props.csvName || "export"} formatted_time={false} />
                        </Segment>
                    )}
            </Media>
            <Segment attached>
                <FlexibleXYPlot
                    dontCheckIfEmpty
                    xDomain={[0, props.maxX]}
                    yDomain={[0, props.maxY]}
                    height={460}
                    margin={{ left: 60, right: 10, top: 10 }}
                    style={props.backgroundImage ? {
                        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),url(${props.backgroundImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: '60px 10px',
                        backgroundSize: "calc(100% - 70px) 410px",
                    } : null}
                >
                    <HorizontalGridLines style={{ stroke: 'rgba(255,255,255, 0.2)' }} />
                    <XAxis
                        title={`${i18n._(t`flow rate`)} (m³/h)`}
                        tickLabelAngle={-20}
                        style={{
                            title: { stroke: 'rgba(0,0,0, 0.5)', fontSize: '15px' },
                        }}
                        tickFormat={(value, index, scale, tickTotal) => {
                            const format = scale.tickFormat(tickTotal)(value);
                            if (typeof value === "number") {
                                return i18n.number(value, { maximumFractionDigits: 1 });
                            }
                            return format;
                        }}
                    />
                    <YAxis
                        title={`${i18n._(t`head`)} (m)`}
                        style={{
                            title: { stroke: 'rgba(0,0,0, 0.5)', fontSize: '15px' },
                        }}
                        tickFormat={(value, index, scale, tickTotal) => {
                            const format = scale.tickFormat(tickTotal)(value);
                            if (typeof value === "number") {
                                return i18n.number(value, { maximumFractionDigits: 1 });
                            }
                            return format;
                        }}
                    />
                    <SerieType
                        color={serie.color}
                        fill={serie.color}
                        size={markSize}
                        getNull={(d) => d.y !== null}
                        curve={"curveMonotoneX"}
                        data={serie.data}
                        onValueMouseOver={(hintValue) => { setHintValue(hintValue); }}
                        onValueMouseOut={() => { setHintValue(null); }}
                    />
                    {!_.isNull(hintValue) && tooltipHint()}
                </FlexibleXYPlot>
                {!_.chain(props).get('children[1]').isNull().value() && (
                    <Segment basic style={{ marginTop: "15px" }}>
                        {_.chain(props).get('children[1]').value()}
                    </Segment>
                )}
            </Segment>

        </>
    );
};

GraphicPumpHeadFlow.defaultProps = {
    SerieType: MarkSeries,      //Default lineMarkSeries
    markSize: 3,                    //Use this for changing mark on LineMarkSerie or CustomSVGSeries
};

export default GraphicPumpHeadFlow;
