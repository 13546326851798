import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Label, Dropdown, List, Divider } from "semantic-ui-react";
import i18n from "modules/i18n/i18nConfig";
import { useSelector } from "react-redux";

// limit for displaying string in label
const MAXLENGTHSTRING = 20;

export const Infos = React.memo((props) => {
    const { zone, category, tag } = useSelector(state => state);
    const { equipment, display, sites, usages } = props;
    let tag_list = [];

    const eqt_dataflowspec_ids = _.get(equipment, 'dataflowspec_set', []);
    const eqt_tag_ids = _.get(equipment, 'tag_set', []);

    const eqt_site = _.find(sites, { id: _.get(equipment, 'site', null) });
    if (eqt_site) {
        tag_list.push({ tag: true, color: "blue", content: _.get(eqt_site, 'text', '-') });
    }

    const eqt_zone = _.find(zone.zones, { id: _.get(equipment, 'zone', null) });

    if (eqt_zone) {
        tag_list.push({ tag: true, color: "teal", content: _.get(eqt_zone, 'name', '-') });
    }

    _.each(eqt_dataflowspec_ids, (item) => {
        const eqt_category = _.find(category.categories, { id: item });
        if (eqt_category) {
            tag_list.push({ tag: true, color: "purple", content: i18n._(_.get(eqt_category, 'text', '-')) });
        }

    });

    const eqt_usage = _.find(usages, { id: _.get(equipment, 'usage', null) });
    if (eqt_usage) {
        tag_list.push({ tag: true, color: "violet", content: i18n._(_.get(eqt_usage, 'text', '-')) });
    }
    _.each(eqt_tag_ids, (item) => {
        const eqt_tag = _.find(tag.tags, { id: item });
        if (eqt_tag) {
            tag_list.push({ tag: true, color: "grey", content: _.get(eqt_tag, 'word', '-') });
        }
    });

    return (
        <>
            {display === "label" && (
                <Label.Group>
                    {_.map(tag_list, (tagItem, index) => (
                        <Label key={index} tag={tagItem.tag} color={tagItem.color}>
                            {String(tagItem.content).length < MAXLENGTHSTRING
                                ? tagItem.content
                                : `${String(tagItem.content).substring(0, MAXLENGTHSTRING)}...`}
                        </Label>
                    ))}
                </Label.Group>
            )}
            {display === "button" && (
                <Dropdown button compact className="icon" icon="tags" direction="left">
                    <Dropdown.Menu>
                        <Dropdown.Header icon="tags" content="Tags" />
                        <Dropdown.Divider />
                        {_.map(tag_list, (tagItem, index) => (
                            <Dropdown.Item
                                key={index}
                                text={
                                    tagItem.content.length < MAXLENGTHSTRING
                                        ? tagItem.content
                                        : `${tagItem.content.substring(0, MAXLENGTHSTRING)}...`
                                }
                                value={
                                    tagItem.content.length < MAXLENGTHSTRING
                                        ? tagItem.content
                                        : `${tagItem.content.substring(0, MAXLENGTHSTRING)}...`
                                }
                                label={{
                                    color: tagItem.color,
                                    empty: true,
                                    circular: true
                                }}
                            />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {display === "list" && (
                <List>
                    <List.Item>
                        <List.Content>
                            <List.Header>Informations</List.Header>
                            <Divider />
                        </List.Content>
                    </List.Item>
                    {_.map(tag_list, (tagItem, index) => (
                        <List.Item
                            key={index}>
                            <List.Content><Label color={tagItem.color} empty circular /> {
                                tagItem.content.length < MAXLENGTHSTRING
                                    ? tagItem.content
                                    : `${tagItem.content.substring(0, MAXLENGTHSTRING)}...`
                            }</List.Content>
                        </List.Item>
                    ))}
                </List>
            )}
        </>
    );
});

Infos.propTypes = {
    equipment: PropTypes.object.isRequired,
    display: PropTypes.string.isRequired
};

export default Infos;
