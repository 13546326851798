import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import { Button, Card, Grid, Segment } from "semantic-ui-react";
import { Crosshair, DiscreteColorLegend, FlexibleXYPlot, Highlight, Hint, HorizontalGridLines, LineMarkSeries, XAxis, YAxis } from "react-vis";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { checkMobileAndTablet } from "modules/common/utils";

import { useGetDetailComparisonMutation } from "modules/dashboard/dashboardService";
import { Media } from "App";
import MessageDisplay from "modules/common/components/MessageDisplay";
import tinycolor from "tinycolor2";

const DetailGraphic = (props) => {
    const { dashboard, org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const [brushing, setBrushing] = useState(false);
    const [lastDrawLocation, setLastDrawLocation] = useState(null);
    const [series, setSeries] = useState([]);
    const [hintValue, setHintValue] = useState(null);
    const [crosshairValues, setCrosshairValues] = useState([]);

    const hasMultipleEquipments = _.size(dashboard.selected_equipments) > 1;

    const [getDetail, detail] = useGetDetailComparisonMutation();

    useEffect(() => {
        if (detail.isSuccess) {
            setSeries(detail.data);
        }
    }, [detail]);

    useEffect(() => {
        if (_.size(dashboard.selected_equipments) > 0) {
            const params = {
                org: org.current.name,
                selected_equipments: dashboard.selected_equipments,
                comparison: dashboard.actions.comparison
            };
            getDetail(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard.selected_equipments]);

    const emptyData = _.chain(series)
        .map((serie) =>
            _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty(serie.data)
                .value()
        )
        .every()
        .value();

    if (emptyData && detail.isSuccess) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    const xDomain = hasMultipleEquipments
        ? [moment(dashboard.actions.comparison.parameters.compareStart.start), moment(dashboard.actions.comparison.parameters.compareEnd.end)]
        : [0, _.chain(series).head().get("data", []).size().value()];

    const clickHandler = (serie_clk, idx) => {
        const size_disable = _.chain(series)
            .filter((item) => item.disabled === false)
            .size()
            .value();
        const update_series = _.map(series, (item, idx_ser) => {
            if (idx_ser === idx) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
            return item;
        });
        setSeries(update_series);
    };

    const nearestXHandler = (value, { index }) => {
        const crossval = _.chain(series)
            .filter((item) => item.disabled === false)
            .reduce((res, serie) => {
                const data = _.chain(serie.data).find({ x: value.x }).value();
                if (data) {
                    res.push({ title: _.get(serie, "title"), ...data });
                }
                return res;
            }, [])
            .value();
        setCrosshairValues(crossval);
    };

    const maxY = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .maxBy("y")
        .get("y")
        .value();

    const minY = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .minBy("y")
        .get("y")
        .value();

    const tooltipCrosshair = () => (
        <Crosshair values={crosshairValues}>
            <Card style={{ zIndex: 10 }}>
                <Card.Content>
                    <Card.Header textAlign="center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {hasMultipleEquipments && moment(crosshairValues[0].t).locale(current_lng).format("LLL")}
                        {!hasMultipleEquipments && _.chain(series).head().get("name", "-").value()}
                    </Card.Header>
                </Card.Content>
                <Card.Content>
                    <Grid centered verticalAlign="middle">
                        {_.chain(crosshairValues)
                            .map((item, idx) => {
                                return (
                                    <Grid.Row key={idx} style={{ color: tinycolor(item.color).setAlpha(1).toString() }}>
                                        {!hasMultipleEquipments ? (
                                            <Grid.Column width={10} textAlign="left">
                                                {moment(item.t).locale(current_lng).format("LLL")}
                                            </Grid.Column>
                                        ) : (
                                            <Grid.Column
                                                width={10}
                                                textAlign="left"
                                                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                            >
                                                {item.title}
                                            </Grid.Column>
                                        )}
                                        <Grid.Column width={6} textAlign="right">
                                            {item.y !== null && `${i18n.number(item.y, { maximumFractionDigits: 2 })} ${item.unit}`}
                                            {item.y === null && `- ${item.unit}`}
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })
                            .value()}
                    </Grid>
                </Card.Content>
            </Card>
        </Crosshair>
    );

    const tooltipHint = () => (
        <Hint value={hintValue}>
            <Card className="pwaOverviewTooltip" style={{ width: moment(hintValue.t).locale(current_lng).format("LLL").length * 8 }}>
                <Card.Content>
                    <Card.Header>{moment(hintValue.t).locale(current_lng).format("LLL")}</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Card.Description>
                        <Grid centered verticalAlign="middle" style={{ color: tinycolor(hintValue.color).setAlpha(1).toString() }}>
                            <Grid.Row>
                                <Grid.Column className="pwaTooltipContentComparison" width={16} textAlign="center">
                                    {hintValue.title}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    {hintValue.y !== null && `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${hintValue.unit}`}
                                    {hintValue.y === null && `- ${hintValue.unit}`}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        </Hint>
    );

    return (
        <>
            {detail.isError && <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />}
            {detail.isSuccess ? (
                <>
                    <Segment attached textAlign="right">
                        <Media greaterThanOrEqual="computer">
                            <Button
                                onClick={(event, data) => {
                                    setLastDrawLocation(null);
                                }}
                                icon="zoom-out"
                                className="no-print"
                            />
                        </Media>
                    </Segment>
                    <Segment attached>
                        <FlexibleXYPlot
                            dontCheckIfEmpty
                            xType={hasMultipleEquipments ? "time" : "linear"}
                            xDomain={lastDrawLocation ? lastDrawLocation : xDomain}
                            yDomain={[Math.min(0, minY), Math.max(10, maxY)]}
                            height={480}
                            margin={{ left: 60, right: 10, top: 10, bottom: 130 }}
                            onMouseLeave={() => {
                                setCrosshairValues([]);
                            }}
                        >
                            <DiscreteColorLegend
                                className="pwaLegend400"
                                orientation="horizontal"
                                height={75}
                                items={series}
                                onItemClick={clickHandler}
                            />
                            <HorizontalGridLines />
                            {hasMultipleEquipments && (
                                <XAxis
                                    title={i18n._(t`time`)}
                                    tickLabelAngle={-20}
                                    tickFormat={(value, index, scale, tickTotal) => {
                                        return multiI18nFormat(value, current_lng);
                                    }}
                                />
                            )}
                            {!hasMultipleEquipments && (
                                <XAxis
                                    title={i18n._(t`number of points`)}
                                    tickLabelAngle={-20}
                                    tickFormat={(value, index, scale, tickTotal) => {
                                        try {
                                            const firstRecordOnFirstSerie = _.chain(series).head().get("data").head().get("t").value();
                                            const start = moment.min(
                                                moment(firstRecordOnFirstSerie),
                                                moment(dashboard.actions.comparison.parameters.refStart.start)
                                            );
                                            const tmstRecordOnFirstSerie = moment(
                                                _.chain(series).head().get("data").get(parseInt(value)).get("t").value()
                                            );
                                            if (lastDrawLocation !== null) {
                                                const hours = tmstRecordOnFirstSerie.diff(start, "hours");
                                                if (hours < 24) {
                                                    return `h+${hours}`;
                                                }
                                            }
                                            const days = tmstRecordOnFirstSerie.diff(start, "days");
                                            return `j+${days}`;
                                        } catch (error) {
                                            return "";
                                        }
                                    }}
                                />
                            )}
                            <YAxis
                                title={_.chain(series).head().get("unit").value() || "-"}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    const format = scale.tickFormat(tickTotal)(value);
                                    if (typeof value === "number") {
                                        return i18n.number(value, { minimumFractionDigits: 1, maximumFractionDigits: 2, notation: "compact" });
                                    }
                                    return format;
                                }}
                            />
                            <Highlight
                                enableY={false}
                                onBrushStart={(area) => {
                                    if (area) setBrushing(true);
                                }}
                                onBrushEnd={(area) => {
                                    if (area) {
                                        setBrushing(false);
                                        hasMultipleEquipments && setLastDrawLocation([moment(_.get(area, "left")), moment(_.get(area, "right"))]);
                                        !hasMultipleEquipments && setLastDrawLocation([_.get(area, "left"), _.get(area, "right")]);
                                    }
                                }}
                            />
                            {_.chain(series)
                                .filter((item) => item.disabled === false)
                                .orderBy((serie) => {
                                    return serie.data.length;
                                }, "asc")
                                .map((serie, idx) => {
                                    return (
                                        <LineMarkSeries
                                            key={idx}
                                            color={serie.color}
                                            fill={serie.color}
                                            size={3}
                                            getNull={(d) => d.y !== null}
                                            data={serie.data}
                                            markStyle={brushing ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
                                            curve={"curveMonotoneX"}
                                            onNearestX={!checkMobileAndTablet() ? nearestXHandler : null}
                                            onValueMouseOver={
                                                checkMobileAndTablet()
                                                    ? (hintValue) => {
                                                          setHintValue(hintValue);
                                                      }
                                                    : null
                                            }
                                            onValueMouseOut={
                                                checkMobileAndTablet()
                                                    ? () => {
                                                          setHintValue(null);
                                                      }
                                                    : null
                                            }
                                        />
                                    );
                                })
                                .value()}
                            {!checkMobileAndTablet() && _.size(crosshairValues) !== 0 && tooltipCrosshair()}
                            {checkMobileAndTablet() && !_.isNull(hintValue) && tooltipHint()}
                        </FlexibleXYPlot>
                    </Segment>
                </>
            ) : (
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            )}
        </>
    );
};

export default React.memo(DetailGraphic);
