import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import Datetime from "react-datetime";
import { Button, Dropdown, Grid, Input, Label, Modal, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import {
    changeNumOfDays,
    resetComparison,
    setCompareEndComparison,
    setCompareEndBeforeStart,
    setCompareStartComparison,
    setDisableComparison,
    setMtTypeComparison,
    setRefEndComparison,
    setRefStartComparison,
    setSelectedMeasurementsComparison,
    setComparisonReady
} from "modules/dashboard/dashboardSlice";
import history_app from "history_app";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { reparseNumber, validateNumber } from "modules/common/utils";

const Comparison = (props) => {
    const { isDashboard, isSelectedEquipmentsMatch } = props;
    const now = moment();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { dashboard, measurement, measurementtype, dataflow } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const { comparison } = dashboard.actions;

    const exceededEquipmentLimit = _.size(dashboard.selected_equipments) <= 20;

    // Used to enable action button on dashboard. (hide/show modal)
    const hasEquipmentsSelected = _.size(dashboard.selected_equipments) > 0;
    const hasMultipleEquipments = _.size(dashboard.selected_equipments) > 1;
    const numOfDaysReparsed = reparseNumber(comparison.parameters.numOfDays);

    useEffect(() => {
        if (isDashboard) {
            //in dashboard
            if (open === false) {
                //reset Comparison params when modal closed
                dispatch(resetComparison());
            } else {
                //update Start/End on each open Modal
                dispatch(setRefStartComparison({ start: now.clone().startOf("minute").subtract(7, "days").toISOString(), err: false }));
                dispatch(setRefEndComparison({ end: now.clone().startOf("minute").toISOString(), err: false }));
                dispatch(setCompareStartComparison({ start: now.clone().startOf("minute").subtract(7, "days").toISOString(), err: false }));
                dispatch(setCompareEndComparison({ end: now.clone().startOf("minute").toISOString(), err: false }));
                if (measurementTypeFromSelected.length === 1) {
                    dispatch(setMtTypeComparison(measurementTypeFromSelected?.[0]?.value));
                    const measuresFromMtType = _.filter(measurementsFromSelectedEqt, (measure) => {
                        return _.get(measure, "measurementtype.id") === measurementTypeFromSelected?.[0]?.value;
                    });
                    dispatch(setSelectedMeasurementsComparison(measuresFromMtType));
                    dispatch(setDisableComparison(false));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, open, isDashboard]);

    if (open === false) {
        //prevent processing below if not open modal
        return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => {
                    setOpen(true);
                    dispatch(setComparisonReady(false));
                }}
                open={open}
                centered={false}
                trigger={
                    <Button
                        labelPosition="left"
                        icon
                        fluid
                        basic={
                            !hasEquipmentsSelected ||
                            (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) ||
                            !exceededEquipmentLimit
                        }
                        negative={
                            !hasEquipmentsSelected ||
                            (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) ||
                            !exceededEquipmentLimit
                        }
                        disabled={
                            !hasEquipmentsSelected ||
                            (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) ||
                            !exceededEquipmentLimit
                        }
                    >
                        {!hasEquipmentsSelected ||
                        (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) ||
                        !exceededEquipmentLimit ? (
                            <Icon name="warning sign" />
                        ) : (
                            <Icon name="balance" />
                        )}
                        {_.size(dashboard.selected_equipments) === 1 && isDashboard && (
                            <div style={{ paddingLeft: "2rem" }}>
                                <Trans>tocomparetwoperiod</Trans>
                            </div>
                        )}
                        {_.size(dashboard.selected_equipments) === 1 && !isDashboard && (
                            <div style={{ paddingLeft: "2rem" }}>
                                <Trans>tocomparenewtwoperiod</Trans>
                            </div>
                        )}
                        {_.size(dashboard.selected_equipments) !== 1 && isDashboard && exceededEquipmentLimit && (
                            <div style={{ paddingLeft: "2rem" }}>
                                <Trans>tocompare </Trans>
                            </div>
                        )}
                        {_.size(dashboard.selected_equipments) !== 1 && isDashboard && !exceededEquipmentLimit && (
                            <div style={{ paddingLeft: "2rem" }}>
                                <div>
                                    <Trans>too much equipments, max 20</Trans>
                                </div>
                            </div>
                        )}
                        {_.size(dashboard.selected_equipments) !== 1 && !isDashboard && (
                            <div style={{ paddingLeft: "2rem" }}>
                                <Trans>tonewcompare</Trans>
                            </div>
                        )}
                    </Button>
                }
            />
        );
    }

    /* TIME PART */
    const onChangeRefStart = (date) => {
        if (_.isString(date) || !date.isValid() || date > now) {
            dispatch(setRefStartComparison({ start: date, err: true }));
            dispatch(setDisableComparison(true));
        } else {
            dispatch(setRefStartComparison({ start: date.clone().toISOString(), err: false }));
            dispatch(setRefEndComparison({ end: date.clone().add(numOfDaysReparsed, "days").toISOString(), err: false }));
            dispatch(setDisableComparison(false));
        }
    };
    const onChangeCompareStart = (date) => {
        if (_.isString(date) || !date.isValid() || date > now) {
            dispatch(setCompareStartComparison({ start: date, err: true }));
            dispatch(setDisableComparison(true));
        } else {
            dispatch(setCompareStartComparison({ start: date.clone().toISOString(), err: false }));
            if (hasMultipleEquipments) {
                if (comparison.parameters.compareEnd.err === false && moment(comparison.parameters.compareEnd.end) < date) {
                    dispatch(setCompareEndBeforeStart(true));
                    dispatch(setDisableComparison(true));
                } else {
                    dispatch(setCompareEndBeforeStart(false));
                    dispatch(setDisableComparison(false));
                }
            } else {
                dispatch(setCompareEndComparison({ end: date.clone().add(numOfDaysReparsed, "days").toISOString(), err: false }));
                dispatch(setDisableComparison(false));
            }
        }
    };

    const onChangeCompareEnd = (date) => {
        if (_.isString(date) || !date.isValid() || date > now) {
            dispatch(setCompareEndComparison({ end: date, err: true }));
            dispatch(setDisableComparison(true));
        } else {
            dispatch(setCompareEndComparison({ end: date.clone().toISOString(), err: false }));
            if (comparison.parameters.compareStart.err === false && date < moment(comparison.parameters.compareStart.start)) {
                dispatch(setCompareEndBeforeStart(true));
                dispatch(setDisableComparison(true));
            } else {
                dispatch(setCompareEndBeforeStart(false));
                dispatch(setDisableComparison(false));
            }
        }
    };

    const renderInput = ({ msg, ...rest }, openCalendar, closeCalendar) => (
        <div>
            {rest.error && (
                <Label pointing={"below"} basic color="red">
                    {msg}
                </Label>
            )}
            <Input labelPosition="left" {...rest} />
        </div>
    );

    const measurementsFromSelectedEqt = _.reduce(
        measurement.measurements,
        (res, measure) => {
            const df = _.find(dataflow.dataflows, { id: measure.dataflow });
            if (df) {
                const eqt = _.find(dashboard.selected_equipments, { id: df.equipment });
                if (eqt) {
                    res.push(measure);
                }
            }
            return res;
        },
        []
    );

    //List of measurementtypes used by available measurements on selected equipments
    const measureTypeFromSelectedEqt = _.reduce(
        measurementsFromSelectedEqt,
        (res, measure) => {
            const df = _.find(dataflow.dataflows, { id: measure.dataflow });
            const mt_type = _.find(measurementtype.measurementtypes, { id: _.get(measure, "measurementtype.id") });
            if (mt_type) {
                if (_.get(df, "dataflowspec_tech.name") === "elec_expert") {
                    //Exclude operating_hours from mt type selector in comparison
                    if (_.get(mt_type, "datapoint_type") === 3 && _.get(mt_type, "name") !== "operating_hours") {
                        res.push(mt_type);
                    }
                } else {
                    res.push(mt_type);
                }
            }
            return res;
        },
        []
    );

    let numOfDaysValidate = validateNumber(comparison.parameters.numOfDays, i18n, false, false, true);
    if (numOfDaysValidate === undefined && numOfDaysReparsed < 1) {
        numOfDaysValidate = <Trans>Minimum of day is 1</Trans>;
    }

    const measurementTypeFromSelected = _.chain(measurementtype.measurementtypes)
        .filter((item) => !_.includes([22, 19, 18, 8, 26, 13, 11, 14, 60], item.id)) // Remove MT_TYPE
        .intersectionBy(measurementtype.measurementtypes, measureTypeFromSelectedEqt, "id")
        .value();

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                setOpen(true);
                dispatch(setComparisonReady(false));
            }}
            open={open}
            centered={false}
            trigger={
                <Button
                    labelPosition="left"
                    icon
                    fluid
                    basic={
                        !hasEquipmentsSelected || (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) || !exceededEquipmentLimit
                    }
                    negative={
                        !hasEquipmentsSelected || (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) || !exceededEquipmentLimit
                    }
                    disabled={
                        !hasEquipmentsSelected || (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) || !exceededEquipmentLimit
                    }
                >
                    {!hasEquipmentsSelected || (!isSelectedEquipmentsMatch && isSelectedEquipmentsMatch !== undefined) || !exceededEquipmentLimit ? (
                        <Icon name="warning sign" />
                    ) : (
                        <Icon name="balance" />
                    )}
                    {_.size(dashboard.selected_equipments) === 1 && isDashboard && (
                        <div style={{ paddingLeft: "2rem" }}>
                            <Trans>tocomparetwoperiod</Trans>
                        </div>
                    )}
                    {_.size(dashboard.selected_equipments) === 1 && !isDashboard && (
                        <div style={{ paddingLeft: "2rem" }}>
                            <Trans>tocomparenewtwoperiod</Trans>
                        </div>
                    )}
                    {_.size(dashboard.selected_equipments) !== 1 && isDashboard && exceededEquipmentLimit && (
                        <div style={{ paddingLeft: "2rem" }}>
                            <Trans>tocompare </Trans>
                        </div>
                    )}
                    {_.size(dashboard.selected_equipments) !== 1 && isDashboard && !exceededEquipmentLimit && (
                        <div style={{ paddingLeft: "2rem" }}>
                            <div>
                                <Trans>too much equipments, max 20</Trans>
                            </div>
                        </div>
                    )}
                    {_.size(dashboard.selected_equipments) !== 1 && !isDashboard && (
                        <div style={{ paddingLeft: "2rem" }}>
                            <Trans>tonewcompare</Trans>
                        </div>
                    )}
                </Button>
            }
        >
            <Modal.Header>
                {_.size(dashboard.selected_equipments) === 1 && <Trans>comparison one feed</Trans>}
                {_.size(dashboard.selected_equipments) !== 1 && <Trans>comparison multi feed</Trans>}
            </Modal.Header>
            <Modal.Content>
                <Grid stackable centered>
                    {_.size(dashboard.selected_equipments) === 1 && (
                        <>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    {numOfDaysValidate !== undefined && (
                                        <Label basic pointing="below" color="red">
                                            {numOfDaysValidate}
                                        </Label>
                                    )}
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        error={numOfDaysValidate !== undefined}
                                        label={`${i18n._(t`number of days`)}:`}
                                        value={comparison.parameters.numOfDays}
                                        onChange={(e, data) => {
                                            dispatch(changeNumOfDays(data.value));
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    {" "}
                                    <Trans>reference period</Trans>:
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    {/* DATEPICKER start */}
                                    <Datetime
                                        locale={current_lng}
                                        timeFormat={"HH:mm"}
                                        value={
                                            comparison.parameters.refStart.err
                                                ? comparison.parameters.refStart.start
                                                : moment(comparison.parameters.refStart.start)
                                        }
                                        strictParsing={true}
                                        onChange={onChangeRefStart}
                                        renderInput={renderInput}
                                        inputProps={{
                                            label: `${i18n._(t`from`)}:`,
                                            error: comparison.parameters.refStart.err,
                                            msg: i18n._(t`invalid day`),
                                            fluid: true,
                                            icon: "calendar"
                                        }}
                                        isValidDate={(current) => current.isBefore(now)}
                                    />
                                    {/* END DATEPICKER start */}
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    {/* DATEPICKER end */}
                                    <Datetime
                                        locale={current_lng}
                                        timeFormat={"HH:mm"}
                                        value={
                                            comparison.parameters.refEnd.err
                                                ? comparison.parameters.refEnd.end
                                                : moment(comparison.parameters.refEnd.end)
                                        }
                                        strictParsing={true}
                                        renderInput={renderInput}
                                        inputProps={{
                                            label: `${i18n._(t`to`)}:`,
                                            error: comparison.parameters.refEnd.err,
                                            msg: i18n._(t`invalid day`),
                                            fluid: true,
                                            icon: "calendar",
                                            disabled: true
                                        }}
                                        isValidDate={(current) => current.isBefore(now)}
                                    />
                                    {/* END DATEPICKER end */}
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Trans>compare period</Trans>:
                        </Grid.Column>
                        {comparison.parameters.compareEndBeforeStart && (
                            <Grid.Column width={16}>
                                <MessageDisplay
                                    message={i18n._(t`end after start`)}
                                    level="error"
                                    iconName="warning circle"
                                    isLoading={false}
                                    attached={false}
                                />
                            </Grid.Column>
                        )}
                        <Grid.Column width={5}>
                            {/* DATEPICKER start */}
                            <Datetime
                                locale={current_lng}
                                timeFormat={"HH:mm"}
                                value={
                                    comparison.parameters.compareStart.err
                                        ? comparison.parameters.compareStart.start
                                        : moment(comparison.parameters.compareStart.start)
                                }
                                strictParsing={true}
                                onChange={onChangeCompareStart}
                                renderInput={renderInput}
                                inputProps={{
                                    label: `${i18n._(t`from`)}:`,
                                    error: comparison.parameters.compareStart.err,
                                    msg: i18n._(t`invalid day`),
                                    fluid: true,
                                    icon: "calendar"
                                }}
                                isValidDate={(current) => current.isBefore(now)}
                            />
                            {/* END DATEPICKER start */}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {/* DATEPICKER end */}
                            <Datetime
                                locale={current_lng}
                                timeFormat={"HH:mm"}
                                value={
                                    comparison.parameters.compareEnd.err
                                        ? comparison.parameters.compareEnd.end
                                        : moment(comparison.parameters.compareEnd.end)
                                }
                                strictParsing={true}
                                renderInput={renderInput}
                                onChange={hasMultipleEquipments ? onChangeCompareEnd : null}
                                inputProps={{
                                    label: `${i18n._(t`to`)}:`,
                                    error: comparison.parameters.compareEnd.err,
                                    msg: i18n._(t`invalid day`),
                                    fluid: true,
                                    icon: "calendar",
                                    disabled: !hasMultipleEquipments
                                }}
                                isValidDate={(current) => current.isBefore(now)}
                            />
                            {/* END DATEPICKER end */}
                        </Grid.Column>
                    </Grid.Row>
                    {isDashboard && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Trans>measurementtypes</Trans>:
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Dropdown
                                    options={_.map(measurementTypeFromSelected, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                                    placeholder={i18n._(t`select measurementtypes`)}
                                    selection
                                    fluid
                                    value={comparison.parameters.mtType}
                                    onChange={async (e, data) => {
                                        await dispatch(setMtTypeComparison(data.value));
                                        const measuresFromMtType = _.filter(measurementsFromSelectedEqt, (measure) => {
                                            return _.get(measure, "measurementtype.id") === data.value;
                                        });

                                        await dispatch(setSelectedMeasurementsComparison(measuresFromMtType));
                                        await dispatch(setDisableComparison(false));
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    positive
                    onClick={async (e) => {
                        if (isDashboard) {
                            history_app.push("/comparison");
                        } else {
                            //Here, we are in comparison page
                            setOpen(false);
                        }
                        dispatch(setComparisonReady(true));
                    }}
                    disabled={
                        comparison.parameters.disable ||
                        comparison.parameters.mtType === null ||
                        comparison.status === "loading" ||
                        numOfDaysValidate !== undefined
                    }
                >
                    <Trans>go</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default React.memo(Comparison);
