import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';
import cookie from "react-cookies";

export const alertApi = defaultApi.injectEndpoints({
    reducerPath: 'apiAlert',
    endpoints: (build) => ({
        getAlerts: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `alerts?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                return _.map(response, (alert) => {
                    //add owner copy of user for checkRights mutualization
                    return { ...alert, owner: alert.user };
                });
            },
            providesTags: ["Alerts"],
        }),
        getAlert: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return `alerts/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: ['Alert'],
            transformResponse: (response) => {
                return { ...response, duration_threshold: response.duration_threshold / 60 };
            }
        }),
        createAlert: build.mutation({
            query: ({ org, data, user }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `alerts?org=${current_org}`,
                    method: 'POST',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: {
                        ...data,
                        duration_threshold: data.duration_threshold * 60,
                        user: user.user_id,
                    }
                };
            },
            invalidatesTags: ['Alerts', "Alert"]
        }),
        updateAlert: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `alerts/${data.id}?org=${current_org}`,
                    method: 'PUT',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: { ...data, duration_threshold: data.duration_threshold * 60 }
                };
            },
            invalidatesTags: ["Alert", "Alerts"]
        }),
        deleteAlert: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `alerts/${data}?org=${current_org}`,
                    method: 'DELETE',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                };
            },
            async onQueryStarted({ data }, { queryFulfilled, dispatch }) {
                const deleteResult = dispatch(alertApi.util.updateQueryData('getAlerts', undefined, (draft) => {
                    return _.filter(draft, (alert) => alert.id !== data);
                }));
                try {
                    await queryFulfilled;
                } catch (error) {
                    deleteResult.undo();
                }
            }
        }),
    }),
    overrideExisting: false,
});

export const { useGetAlertsQuery, useGetAlertQuery, useCreateAlertMutation, useUpdateAlertMutation, useDeleteAlertMutation } = alertApi;