import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { Grid, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { dynamicValueUnit } from "modules/data/utils";

const renderCompare = (v7, v24) => {
    let data = {
        sign: "",
        icon: null,
        val: null
    };

    if (!_.isFinite(v7) || !_.isFinite(v24)) {
        return data;
    }

    const process_val = (Math.abs(v24 - v7) / v7) * 100;

    if (!_.isNaN(process_val)) {
        data.val = process_val;
    }

    if (_.lt(v7, v24)) {
        data.sign = "+";
        data.icon = <Icon flipped="horizontally" name="level up alternate" />;
    } else if (_.gt(v7, v24)) {
        data.sign = "-";
        data.icon = <Icon flipped="horizontally" name="level down alternate" />;
    } else {
        data.icon = <Icon name="long arrow alternate left" />;
    }

    return data;
};

export const Val7Day = React.memo((props) => {
    const { measure, overview24h } = props;

    const overview_data = _.chain(overview24h).get("data.measurements").find({ measure: measure.id }).value();
    const delta_24h = _.get(overview_data, 'delta_24h', null);
    const delta_week_ago_24h = _.get(overview_data, 'delta_week_ago_24h', null);

    const { isZero, icon, sign, val, factor, new_unit } = useMemo(() => {
        const unit = _.result(measure, "display_unit.symbol");
        const auto_unit = _.get(measure, 'auto_unit', true);
        const { factor, new_unit } = dynamicValueUnit(delta_week_ago_24h, unit, auto_unit);
        const isZero = _.isEqual(delta_week_ago_24h, 0);
        const { sign, icon, val } = renderCompare(delta_week_ago_24h, delta_24h);

        return { isZero, icon, sign, val, factor, new_unit };
    }, [delta_24h, delta_week_ago_24h, measure]);

    if (_.includes(['loading', 'idle'], overview24h.status)) {
        return (
            <MessageDisplay
                message={i18n._(t`loading`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }

    if (overview24h.status === "failed") {
        return <MessageDisplay
            message={i18n._(t`error loading data`)}
            level="error"
            iconName="warning circle"
            isLoading={false}
            attached={false}
        />;
    }

    if (_.isUndefined(overview_data)) {
        return (
            <MessageDisplay
                message={i18n._(t`no consumption 7d`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }


    if (!_.isFinite(delta_week_ago_24h)) {
        return (
            <MessageDisplay
                message={i18n._(t`no consumption 7d`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    return (
        <Grid centered>
            <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                <Trans>consumption 24h 7d</Trans>
            </Grid.Column>
            {!isZero && (
                <>
                    <Grid.Column width={7} textAlign="right">
                        <h3>
                            {icon}
                            {`${sign} ${i18n.number(val, {
                                maximumFractionDigits: 1
                            })} %`}
                        </h3>
                    </Grid.Column>
                    <Grid.Column width={1} />
                </>
            )}
            <Grid.Column width={isZero ? 16 : 7} textAlign={isZero ? "center" : "left"}>
                <h3>
                    {`${i18n.number(delta_week_ago_24h * factor, {
                        maximumFractionDigits: 0
                    })} ${new_unit}`}
                </h3>
            </Grid.Column>
        </Grid>
    );
});

Val7Day.propTypes = {
    measure: PropTypes.object.isRequired,
    overview24h: PropTypes.object.isRequired
};

export default Val7Day;
