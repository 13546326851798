import _ from "lodash";
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axios from "axios";

import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    event: {
        status: 'idle',
        init_events: [],
        events: [],
        error: null
    },
    triggered: {
        status: 'idle',
        init_alerts: [],
        alerts: [],
        error: null
    },
    time: null,
    filter: {
        searchName: "",
        siteFilter: [],
        zoneFilter: [],
        usageFilter: [],
        tagFilter: [],
        categoryFilter: [],
        activityTypeFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
};


/**
 * Retrieve events from server for current organization
 * @function getEvents
 */
export const getEvents = createAsyncThunk('alertevent/getEvents', async ({ org, start, end }, thunkAPI) => {
    const current_org = _.get(org, 'name', null);
    const { measurements } = thunkAPI.getState().measurement;
    try {
        const response = await PowerAdaptAPI.get(`/eventstates?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`);
        return _.map(response.data, (event) => ({
            type: "event",
            name: _.chain(measurements).find({ id: event.measurement_id }).get('representation').value(),
            date: _.get(event, 'timestamp', null),
            reason: _.get(event, 'new_state', null),
            notif_date: _.get(event, 'notified_date', null),
            display_condition: _.get(event, 'display_condition', '-'),
            measurement_ids: _.get(event, 'measurement_id', null) === null ? [] : [_.get(event, 'measurement_id')],
        }));

    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve triggered_alerts from server for current organization
 * @function getTriggeredAlerts
 */
export const getTriggeredAlerts = createAsyncThunk('alertevent/getTriggeredAlerts', async ({ org, start, end }, thunkAPI) => {
    const current_org = _.get(org, 'name', null);
    try {
        const response = await PowerAdaptAPI.get(`/triggeredalerts?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`);
        return _.map(response.data, (alert) => ({
            type: "alert",
            name: _.result(alert, 'alert_name', '-'),
            date: _.get(alert, 'triggered_date', null),
            reason: _.get(alert, 'triggered_reason', null),
            notif_date: _.get(alert, 'notified_date', null),
            display_condition: _.get(alert, 'display_condition', '-'),
            measurement_ids: _.chain(alert).get('triggered_values', []).map((item, key) => parseInt(key)).value(), //get measurement id from triggered_values
            triggered_values: _.get(alert, 'triggered_values')
        }));
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

const alertEventSlice = createSlice({
    name: "alertevent",
    initialState,
    reducers: {
        setTime: (state, action) => {
            if (action.payload) {
                const { start, end, plage } = action.payload;
                state.time = { start, end, plage };
            }
        },
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setActivityTypeFilter: (state, action) => {
            state.filter.activityTypeFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.event.events = state.event.init_events;
            state.triggered.alerts = state.triggered.init_alerts;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getEvents.pending, (state, action) => {
                state.event.status = 'loading';
                state.event.events = [];
                state.event.init_events = [];
                state.event.error = null;
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.event.status = 'succeeded';
                state.event.events = action.payload;
                state.event.init_events = action.payload;
                state.event.error = null;
            })
            .addCase(getEvents.rejected, (state, action) => {
                state.event.status = 'failed';
                state.event.error = action.payload;
                state.event.events = [];
                state.event.init_events = [];
            })
            .addCase(getTriggeredAlerts.pending, (state, action) => {
                state.triggered.status = 'loading';
                state.triggered.alerts = [];
                state.triggered.init_alerts = [];
                state.triggered.error = null;
            })
            .addCase(getTriggeredAlerts.fulfilled, (state, action) => {
                state.triggered.status = 'succeeded';
                state.triggered.alerts = action.payload;
                state.triggered.init_alerts = action.payload;
                state.triggered.error = null;
            })
            .addCase(getTriggeredAlerts.rejected, (state, action) => {
                state.triggered.status = 'failed';
                state.triggered.error = action.payload;
                state.triggered.alerts = [];
                state.triggered.init_alerts = [];
            })
            .addMatcher(isAnyOf(setSearchNameFilter, setActivityTypeFilter), (state, action) => {
                const global_alertevent = _.chain([...state.event.init_events, ...state.triggered.init_alerts])
                    .reduce((res, alertevent) => {
                        if (_.size(state.filter.activityTypeFilter) === 0) {
                            res.push(alertevent);
                        } else if (
                            _.includes(state.filter.activityTypeFilter, alertevent.type)
                        ) {
                            res.push(alertevent);
                        }
                        return res;
                    }, [])
                    .reduce((res, alertevent) => {
                        if (state.filter.searchName === "") {
                            res.push(alertevent);
                        } else if (
                            _.includes(alertevent.name.toLowerCase(), state.filter.searchName.toLowerCase())
                        ) {
                            res.push(alertevent);
                        }
                        return res;
                    }, [])
                    .reduce((res, alertevent) => {
                        switch (alertevent.type) {
                            case "alert":
                                res.alerts.push(alertevent);
                                return res;
                            case "event":
                                res.events.push(alertevent);
                                return res;
                            default:
                                return res;
                        }
                    }, { events: [], alerts: [] })
                    .value();

                state.event.events = global_alertevent.events;
                state.triggered.alerts = global_alertevent.alerts;

                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setTime, setPage, setItemsPerPage, setStickPage, resetFilterWithPage,
    setSearchNameFilter, setActivityTypeFilter
} = alertEventSlice.actions;

export default alertEventSlice.reducer;