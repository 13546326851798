import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Crosshair, DiscreteColorLegend, FlexibleXYPlot, Hint, HorizontalGridLines, LineMarkSeries, XAxis, YAxis } from "react-vis";
import { Card, Grid, Segment } from "semantic-ui-react";

import { useGetSummaryComparisonMutation } from "modules/dashboard/dashboardService";
import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import moment from "moment";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { getTimedelta } from "modules/time/utils";
import { checkMobileAndTablet } from "modules/common/utils";
import tinycolor from "tinycolor2";

const MinAvgMaxGraphic = (props) => {
    const { dashboard, org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const [series, setSeries] = useState([]);
    const [hintValue, setHintValue] = useState(null);
    const [crosshairValues, setCrosshairValues] = useState([]);
    const hasMultipleEquipments = _.size(dashboard.selected_equipments) > 1;

    const [getSummary, summary] = useGetSummaryComparisonMutation();

    useEffect(() => {
        if (summary.isSuccess) {
            setSeries(summary.data);
        }
    }, [summary]);

    useEffect(() => {
        if (_.size(dashboard.selected_equipments) > 0) {
            const params = {
                org: org.current.name,
                selected_equipments: dashboard.selected_equipments,
                comparison: dashboard.actions.comparison
            };
            getSummary(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard.selected_equipments]);

    const emptyData = _.chain(series)
        .map((serie) =>
            _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty(serie.data)
                .value()
        )
        .every()
        .value();

    if (emptyData && summary.isSuccess) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    //process getTimeDelta from comparison parameters to get correct range for xAxis domain
    const compare_new_range_time = getTimedelta(
        dashboard.actions.comparison.parameters.compareStart.start,
        dashboard.actions.comparison.parameters.compareEnd.end
    );

    const xDomain = hasMultipleEquipments
        ? [compare_new_range_time.start, compare_new_range_time.end]
        : [0, _.chain(series).head().get("data", []).size().value()];

    const maxY = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .maxBy("y")
        .get("y")
        .value();

    const minY = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .minBy("y")
        .get("y")
        .value();

    const clickHandler = (serie_clk, idx) => {
        const size_disable = _.chain(series)
            .filter((item) => item.disabled === false)
            .size()
            .value();
        const update_series = _.map(series, (item, idx_ser, all_series) => {
            if (idx * 2 === idx_ser) {
                return { ...item, disabled: size_disable <= 2 ? false : !item.disabled };
            }
            if (idx * 2 + 1 === idx_ser) {
                return { ...item, disabled: size_disable <= 2 ? false : !item.disabled };
            }
            return item;
        });
        setSeries(update_series);
    };

    const nearestXHandler = (value, { index }) => {
        const crossval = _.chain(series)
            .filter((item) => item.disabled === false)
            .reduce((res, serie) => {
                const data = _.chain(serie.data).find({ x: value.x }).value();
                if (data) {
                    res.push({ title: _.get(serie, "title"), ...data });
                }
                return res;
            }, [])
            .value();
        setCrosshairValues(crossval);
    };

    const tooltipCrosshair = () => (
        <Crosshair values={crosshairValues}>
            <Card>
                <Card.Content>
                    <Card.Header textAlign="center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {hasMultipleEquipments && moment(crosshairValues[0].t).locale(current_lng).format("LLL")}
                        {!hasMultipleEquipments && _.chain(series).head().get("name", "-").value()}
                    </Card.Header>
                </Card.Content>
                <Card.Content>
                    <Grid>
                        {_.chain(crosshairValues)
                            .reduce(
                                (res, v) => {
                                    res[v.type].push(v);
                                    return res;
                                },
                                { max: [], min: [] }
                            )
                            .map((item, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <Grid.Row>
                                            <Grid.Column width={16} textAlign="left" style={{ color: "black", fontWeight: "bold" }}>
                                                {idx}
                                            </Grid.Column>
                                        </Grid.Row>
                                        {_.map(item, (record, index) => {
                                            return (
                                                <Grid.Row key={index} style={{ color: tinycolor(record.color).setAlpha(1).toString() }}>
                                                    <Grid.Column
                                                        width={8}
                                                        textAlign="left"
                                                        style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                    >
                                                        {hasMultipleEquipments && record.title}
                                                        {!hasMultipleEquipments && moment(record.t).locale(current_lng).format("LL")}
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        {record.y !== null && `${i18n.number(record.y, { maximumFractionDigits: 2 })} ${record.unit}`}
                                                        {record.y === null && `- ${record.unit}`}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })
                            .value()}
                    </Grid>
                </Card.Content>
            </Card>
        </Crosshair>
    );

    const tooltipHint = () => (
        <Hint value={hintValue}>
            <Card className="pwaOverviewTooltip" style={{ width: moment(hintValue.t).locale(current_lng).format("LL").length * 8 }}>
                <Card.Content>
                    <Card.Header>{moment(hintValue.t).locale(current_lng).format("LL")}</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Card.Description>
                        <Grid className="pwaOverviewTooltip" style={{ color: tinycolor(hintValue.color).setAlpha(1).toString() }}>
                            <Grid.Column width={14} textAlign="left" className="pwaTooltipContentComparison">
                                {hintValue.title}{" "}
                            </Grid.Column>
                            <Grid.Column width={1} />
                            <Grid.Column width={5} textAlign="left">
                                {" "}
                                {hintValue.type} :{" "}
                            </Grid.Column>
                            <Grid.Column width={9} textAlign="right">
                                {hintValue.y !== null && `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${hintValue.unit}`}
                                {hintValue.y === null && `- ${hintValue.unit}`}
                            </Grid.Column>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        </Hint>
    );

    return (
        <>
            {summary.isError && <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />}
            {summary.isSuccess ? (
                <Segment attached>
                    <FlexibleXYPlot
                        dontCheckIfEmpty
                        xType={hasMultipleEquipments ? "time" : "linear"}
                        xDomain={xDomain}
                        yDomain={[Math.min(0, minY), Math.max(10, maxY)]}
                        height={480}
                        margin={{ left: 60, right: 10, top: 10, bottom: 130 }}
                        onMouseLeave={() => {
                            setCrosshairValues([]);
                        }}
                    >
                        <DiscreteColorLegend
                            className="pwaLegend400"
                            orientation="horizontal"
                            height={75}
                            items={_.chain(series).uniqBy("title").value()}
                            onItemClick={clickHandler}
                        />
                        {hasMultipleEquipments && (
                            <XAxis
                                title={i18n._(t`time`)}
                                tickLabelAngle={-20}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    const default_fmt = multiI18nFormat(value, current_lng);
                                    if (default_fmt.indexOf("06:00") >= 0 || default_fmt.indexOf("12:00") >= 0 || default_fmt.indexOf("18:00") >= 0)
                                        return "";
                                    return default_fmt;
                                }}
                            />
                        )}
                        {!hasMultipleEquipments && (
                            <XAxis
                                title={i18n._(t`number of points`)}
                                tickLabelAngle={-20}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    try {
                                        const firstRecordOnFirstSerie = _.chain(series).head().get("data").head().get("t").value();
                                        const start = moment.min(
                                            moment(firstRecordOnFirstSerie),
                                            moment(dashboard.actions.comparison.parameters.refStart.start)
                                        );
                                        const tmstRecordOnFirstSerie = moment(
                                            _.chain(series).head().get("data").get(parseInt(value)).get("t").value()
                                        );
                                        const days = tmstRecordOnFirstSerie.diff(start, "days");
                                        return `j+${days}`;
                                    } catch (error) {
                                        return "";
                                    }
                                }}
                            />
                        )}
                        <YAxis
                            title={_.chain(series).head().get("unit").value() || "-"}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const format = scale.tickFormat(tickTotal)(value);
                                if (typeof value === "number") {
                                    return i18n.number(value, { minimumFractionDigits: 1, maximumFractionDigits: 2, notation: "compact" });
                                }
                                return format;
                            }}
                        />
                        <HorizontalGridLines />
                        {_.chain(series)
                            .filter((item) => item.disabled === false)
                            .map((serie, idx) => {
                                return (
                                    <LineMarkSeries
                                        key={idx}
                                        color={serie.color}
                                        fill={serie.color}
                                        size={3}
                                        getNull={(d) => d.y !== null}
                                        data={serie.data}
                                        curve={"curveMonotoneX"}
                                        strokeDasharray={serie.type === "min" ? "7,4" : ""}
                                        onNearestX={!checkMobileAndTablet() ? nearestXHandler : null}
                                        onValueMouseOver={
                                            checkMobileAndTablet()
                                                ? (hintValue) => {
                                                      setHintValue(hintValue);
                                                  }
                                                : null
                                        }
                                        onValueMouseOut={
                                            checkMobileAndTablet()
                                                ? () => {
                                                      setHintValue(null);
                                                  }
                                                : null
                                        }
                                    />
                                );
                            })
                            .value()}
                        {!checkMobileAndTablet() && _.size(crosshairValues) !== 0 && tooltipCrosshair()}
                        {checkMobileAndTablet() && !_.isNull(hintValue) && tooltipHint()}
                    </FlexibleXYPlot>
                </Segment>
            ) : (
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            )}
        </>
    );
};

export default React.memo(MinAvgMaxGraphic);
