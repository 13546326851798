import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from "lodash";
import { Grid, Segment, Header } from 'semantic-ui-react';
import { t, Trans } from '@lingui/macro';


import i18n from "modules/i18n/i18nConfig";
import { useGetSitesQuery } from 'modules/site/siteService';
import { useGetMeasurementsQuery } from 'modules/measurement/measurementService';
import { useGetEquipmentsQuery } from 'modules/equipment/equipmentService';
import { useGetCategoriesQuery } from 'modules/category/categoryService';
import { useGetZonesQuery } from 'modules/area/areaService';
import { useGetUsagesQuery } from 'modules/usage/usageService';
import { useGetTagsQuery } from 'modules/tag/tagService';
import { useGetMeasurementtypesQuery } from 'modules/measurement/measurementtypeService';
import { useGetDataflowsQuery } from 'modules/dataflow/dataflowService';

import Back from "modules/common/components/back";
import MessageDisplay from 'modules/common/components/MessageDisplay';
import Alert from 'modules/alert/alert/components/Alert';
import { useGetAlertQuery, useGetAlertsQuery } from '../../alertService';
import { useGetMailingsQuery } from 'modules/mailing/mainlingService';
import { useGetUnitsQuery } from 'modules/unit/unitService';


const PreloadAlert = (props) => {
    const { id } = useParams();
    const { org, mailing } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const mailings = useGetMailingsQuery({ org: org.current }, { skip: !org.current });
    const alerts = useGetAlertsQuery({ org: org.current }, { skip: !org.current });
    const alert = useGetAlertQuery({ org: org.current, data: id }, { skip: !org.current || id === undefined });

    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        { skip: !org.current || categories.data === undefined || tags.data === undefined || sites.data === undefined || zones.data === undefined || usages.data === undefined }
    );

    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        { skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined, units: units.data }
    );

    const error_list = [
        sites.isError,
        mailings.isError,
        alerts.isError,
        measurements.isError,
        measurementtypes.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        categories.isError,
        dataflows.isError,
        equipments.isError,
        units.isError
    ];

    const status_list = [
        sites.isSuccess,
        alerts.isSuccess,
        mailings.isSuccess,
        measurements.isSuccess,
        measurementtypes.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        dataflows.isSuccess,
        equipments.isSuccess,
        units.isSuccess
    ];

    if (_.some([...error_list, alert.isError])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>alert management</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    } else if (id && _.every([...status_list, alert.isSuccess])) {
        return <Alert alert={alert.data} sites={sites.data} mailings={mailing.mailings} />;
    } else if (id === undefined && _.every(status_list)) {
        return <Alert alert={alert.data} sites={sites.data} mailings={mailing.mailings} />;
    } else {
        return (
            <MessageDisplay
                message={i18n._(t`loading data`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }
};

export default PreloadAlert;
