import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from "lodash";
import { Grid, Segment, Header } from 'semantic-ui-react';
import { t, Trans } from '@lingui/macro';

import i18n from "modules/i18n/i18nConfig";
import {
    getAlertPacks, getAlertPack, resetCurrentAlertPack, getAlertPackOverride,
} from 'modules/alert/alert_pack/alertPackSlice';
import { getAlertPackTypes } from 'modules/alert/alert_pack/alertPackTypeSlice';

import Back from "modules/common/components/back";
import MessageDisplay from 'modules/common/components/MessageDisplay';
import AlertPack from 'modules/alert/alert_pack/components/AlertPack';
import { useGetSitesQuery } from 'modules/site/siteService';
import { useGetDataflowsQuery } from 'modules/dataflow/dataflowService';
import { useGetSensorsQuery } from 'modules/sensor/sensorService';
import { useGetMailingsQuery } from 'modules/mailing/mainlingService';
import { useGetZonesQuery } from 'modules/area/areaService';
import { useGetUsagesQuery } from 'modules/usage/usageService';
import { useGetCategoriesQuery } from 'modules/category/categoryService';
import { useGetTagsQuery } from 'modules/tag/tagService';
import { useGetEquipmentsQuery } from 'modules/equipment/equipmentService';
import { useGetMachinesQuery } from "modules/machine/machineService";


const PreloadAlertPack = (props) => {
    const { id_pack } = useParams();
    const dispatch = useDispatch();
    const { org, alertpack, alertpacktype, mailing } = useSelector((state) => state);
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const sensors = useGetSensorsQuery({ org: org.current }, { skip: !org.current });
    const mailings = useGetMailingsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });

    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        { skip: !org.current || categories.data === undefined || tags.data === undefined || sites.data === undefined || zones.data === undefined || usages.data === undefined }
    );

    useEffect(() => {
        (async () => {
            await dispatch(getAlertPacks({ org: org.current }));
            await dispatch(getAlertPackTypes({ org: org.current }));
            id_pack && await dispatch(getAlertPack({ org: org.current, id: id_pack }));
            id_pack && await dispatch(getAlertPackOverride({ org: org.current, id: id_pack }));
        })();
        return () => {
            dispatch(resetCurrentAlertPack());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, org.current]);

    const error_list = [
        sites.isError,
        mailings.isError,
        dataflows.isError,
        alertpack.error,
        alertpacktype.error,
        sensors.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        categories.isError,
        equipments.isError,
        machines.isError
    ];

    const status_list = [
        sites.isSuccess,
        dataflows.isSuccess,
        alertpack.status === "succeeded",
        alertpacktype.status === "succeeded",
        mailings.isSuccess,
        sensors.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        equipments.isSuccess,
        machines.isSuccess
    ];

    if (_.some([...error_list, alertpack.alertpack.error])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}><Back /></Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1"><Trans>alertpacks management</Trans></Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
    else if (id_pack && _.every([...status_list, alertpack.alertpack.status === "succeeded"])) {
        return <AlertPack sites={sites.data} equipments={equipments.data} machines={machines.data} mailings={mailing.mailings} />;
    } else if (id_pack === undefined && _.every(status_list)) {
        return <AlertPack sites={sites.data} equipments={equipments.data} machines={machines.data} mailings={mailing.mailings} />;
    } else {
        return (
            <MessageDisplay
                message={i18n._(t`loading data`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }
};

export default PreloadAlertPack;
