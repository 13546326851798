import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';

export const measurementApi = defaultApi.injectEndpoints({
    reducerPath: 'apiMeasurement',
    endpoints: (build) => ({
        getMeasurements: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `measurements?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            transformResponse: (response, meta, arg) => {
                const { dataflows, units, measurementtypes, equipments } = arg;
                return _.reduce(response, (res, measurement) => {
                    const display_unit = _.find(units, { id: measurement.display_unit });
                    const measurementtype = _.find(measurementtypes, { id: measurement.measurementtype });
                    const measurementtype_tech = _.find(measurementtypes, { id: measurement.measurementtype_tech });
                    const unit_from_mttype = _.find(units, { id: _.get(measurementtype, 'unit') });

                    const dataflow = _.find(dataflows, { id: measurement.dataflow });
                    const equipment = _.find(equipments, { id: _.get(dataflow, 'equipment') });
                    if (dataflow && (display_unit || unit_from_mttype)) {
                        const representation = dataflow && measurementtype ? `${dataflow.name} - ${measurementtype.repr}` : "-";
                        res.push({
                            ...measurement,
                            name: _.get(dataflow, 'name'),
                            display_unit: display_unit || unit_from_mttype,
                            auto_unit: measurement.display_unit === null,
                            measurementtype,
                            measurementtype_tech,
                            representation,
                            equipment_id: _.get(equipment, 'id', null),
                            usage: _.get(dataflow, 'usage.id', null),
                            zone: _.get(dataflow, 'zone.id', null),
                            site: _.get(dataflow, 'site', null),
                            tags_set: _.chain(dataflow).get('tag_set', []).map((tag) => (tag.id)).value(),
                            key: measurement.id,
                            text: representation,
                            value: measurement.id,
                            full_df: dataflow
                        });
                    }

                    return res;
                }, []);
            },
            providesTags: ["Measurements"],
        }),
        getConsumptions: build.query({
            keepUnusedDataFor: 120,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `consumptions?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
        }),
        getStandbyThreshold: build.query({
            keepUnusedDataFor: 0,
            query: ({ org, measure, start, end }) => {

                const current_org = _.get(org, 'name', null);
                return `/measurements/${measure.id}/standby_consumption?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            }
        })
    }),
    overrideExisting: false,
});

export const { useGetMeasurementsQuery, useGetConsumptionsQuery, useGetStandbyThresholdQuery } = measurementApi;