import React, { useMemo } from "react";
import { t } from "@lingui/macro";
import { XYPlot } from "react-vis";
import _ from "lodash";
import { Header, Modal } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetWidgetDataQuery } from "../dashboardDndService";
import { WidgetGaugeType, WidgetType, processWidgetData } from "../utils";

import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicExtensive from "modules/analysisAdvanced/components/view/GraphicExtensive";
import RadialPanel from "./widgets/RadialPanel";
import GaugeRounded from "./widgets/GaugeRounded";
import GaugeText from "./widgets/GaugeText";
import GaugeLinear from "./widgets/GaugeLinear";
import { checkMobileAndTablet } from "modules/common/utils";

const WidgetRenderer = (props) => {
    const {
        widget,
        width,
        height,
        end,
        current_dashboard,
        org,
        sites,
        zones,
        usages,
        tags,
        categories,
        displayAsModal,
        setDisplayAsModal,
        setIsHovered
    } = props;

    const query_params = useMemo(() => {
        return {
            org: org.current,
            dashboard_id: current_dashboard.id,
            widget_id: widget.id,
            end: end.end.format("YYYY-MM-DD"),
            widget
        };
    }, [org, current_dashboard, widget, end]);

    const pollingInterval = props.pollingInterval; //1000 * 60; TODO based on type of widget

    const widgetData = useGetWidgetDataQuery(query_params, {
        skip: !org.current || !current_dashboard || current_dashboard.id !== widget.dashboard,
        pollingInterval
    });

    const process_data = useMemo(() => {
        if (widgetData.isSuccess) {
            return processWidgetData(widget, widgetData.data, { sites, zones, usages, tags, categories }, end);
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetData]);

    const renderWidget = (width, height) => {
        if (widgetData.isError) {
            let err = i18n._(t`error loading data`);
            if (widgetData.error?.data?.detail) {
                err = i18n._(widgetData.error?.data?.detail);
            }
            return (
                <MessageDisplay
                    message={err}
                    level="error"
                    iconName="warning circle"
                    isLoading={false}
                    attached={"bottom"}
                    customStyle={{ width, height }}
                />
            );
        }
        if (widgetData.isLoading) {
            return (
                <MessageDisplay
                    message={i18n._(t`loading data`)}
                    level="info"
                    iconName="circle notched"
                    isLoading={true}
                    attached={"bottom"}
                    customStyle={{ width, height }}
                />
            );
        }
        if (widgetData.isSuccess) {
            const { reference_level, threshold_min, threshold_max } = widget;
            const aggregation = widget?.aggregation ?? 1;
            const thresholds = { reference_level, threshold_min, threshold_max };
            switch (widget?.widget_type) {
                case WidgetType.STACK_BAR_RATIO[0]:
                case WidgetType.STACK_BAR_CONSUMPTION_BREAKDOWN[0]:
                    return (
                        <GraphicExtensive
                            data={process_data.graphic}
                            time_periods={process_data.time_periods}
                            aggregation={aggregation}
                            thresholds={thresholds}
                            PlotType={XYPlot}
                            width={width}
                            height={height}
                            displayPopupInfo={true}
                            tooltipMode={checkMobileAndTablet() ? "hint" : "crossHair"}
                            autoUnit={false}
                        />
                    );
                case WidgetType.PIE_CHART_CONSUMPTION_BREAKDOWN[0]:
                    return (
                        <RadialPanel
                            data={process_data.graphic}
                            time_periods={process_data.time_periods}
                            aggregation={aggregation}
                            width={width}
                            height={height}
                            displayAsModal={displayAsModal}
                        />
                    );
                case WidgetType.GAUGE_RATIO[0]:
                case WidgetType.GAUGE_CONSUMPTION_BREAKDOWN[0]:
                case WidgetType.GAUGE_CONSUMPTION[0]:
                    switch (widget.gauge_choices) {
                        case WidgetGaugeType.RECTILINEAR[0]:
                            return <GaugeLinear width={width} height={height} {...process_data} />;
                        case WidgetGaugeType.DIAL[0]:
                            const currentValueText = `${
                                _.isFinite(process_data.raw_value)
                                    ? i18n.number(process_data.raw_value, {
                                          maximumFractionDigits: 2
                                      })
                                    : "-"
                            } ${process_data.unit}`;
                            return <GaugeRounded width={width} height={height} {...process_data} currentValueText={currentValueText} />;
                        case WidgetGaugeType.TEXT[0]:
                            return <GaugeText {...process_data} width={width} height={height} />;
                        default:
                            return <div>unknown</div>;
                    }
                case WidgetType.SPARKLINE_CONSUMPTION[0]:
                    return (
                        <GraphicExtensive
                            data={process_data.graphic}
                            time_periods={process_data.time_periods}
                            aggregation={aggregation}
                            thresholds={thresholds}
                            PlotType={XYPlot}
                            width={width}
                            height={height}
                            displayPopupInfo={false}
                            sparklineMode={true}
                            tooltipMode={checkMobileAndTablet() ? "hint" : "crossHair"}
                            showRepartition={false}
                            autoUnit={false}
                        />
                    );
                case WidgetType.SPARKLINE_CONSUMPTIONS[0]:
                    return (
                        <GraphicExtensive
                            data={process_data.graphic}
                            time_periods={process_data.time_periods}
                            aggregation={aggregation}
                            thresholds={thresholds}
                            PlotType={XYPlot}
                            width={width}
                            height={height}
                            displayPopupInfo={false}
                            sparklineMode={true}
                            tooltipMode={checkMobileAndTablet() ? "hint" : "crossHair"}
                            showRepartition={false}
                            autoUnit={false}
                        />
                    );
                default:
                    return null;
            }
        }
    };
    const size = displayAsModal ? [window.innerWidth * 0.9, window.innerHeight * 0.7] : [width, height];
    const render = renderWidget(...size);

    if (displayAsModal) {
        return (
            <Modal
                closeIcon
                centered={false}
                size="fullscreen"
                open
                onClose={() => {
                    setDisplayAsModal(false);
                    setIsHovered(false);
                }}
            >
                <Header content={widget.name} />
                <Modal.Content style={{ padding: "0px 1px 0px 1px", position: "relative" }}>{render}</Modal.Content>
            </Modal>
        );
    } else {
        return render;
    }
};

export default React.memo(WidgetRenderer);
