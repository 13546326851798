import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { Grid } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import { AreaSeries, FlexibleWidthXYPlot, XAxis, YAxis } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Palette from "modules/common/components/graphic/Colors";
import { dynamicValueUnit } from "modules/data/utils";
import { priceConversionRate } from "modules/common/utils";


export const Val24h = React.memo((props) => {
    const { equipment, measure, overview24h } = props;
    const now = moment();
    const overview_data = _.chain(overview24h).get("data.measurements").find({ measure: measure.id }).value();
    const datapoints = _.get(overview_data, 'datapoints', []);
    const [delta_24h, avg_24h, min_24h, max_24h] = _.at(overview_data, ['delta_24h', 'avg_24h', 'min_24h', 'max_24h'], null);
    const datapoint_type = _.get(measure, "measurementtype.datapoint_type", null);

    const { site_conversions, category_name, mt_type_name, factor, new_unit } = useMemo(() => {
        const wrap_df = _.chain(equipment).get('dataflows', []).find({ id: measure.dataflow });
        const site_conversions = wrap_df.get("site.conversions", null).value();
        const category_name = wrap_df.get("dataflowspec.name").value();
        const mt_type_name = _.get(measure, 'measurementtype.name');


        const display_unit = _.get(measure, 'display_unit.symbol');
        const auto_unit = _.get(measure, 'auto_unit', true);
        const { factor, new_unit } = _.isEqual(datapoint_type, 3) ? dynamicValueUnit(delta_24h, display_unit, auto_unit) : dynamicValueUnit(avg_24h, display_unit, auto_unit);

        return { site_conversions, category_name, mt_type_name, factor, new_unit };
    }, [avg_24h, datapoint_type, delta_24h, equipment, measure]);

    if (_.includes(['loading', 'idle'], overview24h.status)) {
        return (
            <MessageDisplay
                message={i18n._(t`loading`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }

    if (overview24h.status === "failed") {
        return <MessageDisplay
            message={i18n._(t`error loading data`)}
            level="error"
            iconName="warning circle"
            isLoading={false}
            attached={false}
        />;
    }


    if (_.isUndefined(overview_data)) {
        return (
            <MessageDisplay
                message={i18n._(t`no data 24h`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    if (_.size(datapoints) === 0) {
        return (
            <MessageDisplay
                message={i18n._(t`no data 24h`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    if (_.isUndefined(datapoint_type)) {
        return (
            <MessageDisplay
                message={i18n._(t`error processing val24h`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    const renderDelta = () => {

        const rate = priceConversionRate(delta_24h, site_conversions, category_name, mt_type_name, i18n);
        const conso_fmt = i18n.number(delta_24h * factor, { maximumFractionDigits: 0 });

        return !_.isFinite(delta_24h) ? "-" : `${conso_fmt} ${new_unit || "-"}          ${rate ? rate : ""}`;
    };

    const renderAgv = () => {
        return !_.isFinite(avg_24h)
            ? "-"
            : `${i18n.number(avg_24h * factor, {
                maximumFractionDigits: 0
            })} ${new_unit || "-"}`;
    };

    return (
        <Grid stretched centered>
            <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                <Trans>last 24h</Trans>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
                <h3 style={{ zIndex: 1 }}>{_.isEqual(datapoint_type, 3) ? renderDelta() : renderAgv()}</h3>
            </Grid.Column>
            <Grid.Column width={16}>
                {/* Graphic for last 24h datapoints */}
                <FlexibleWidthXYPlot
                    dontCheckIfEmpty
                    xType="time"
                    height={80}
                    xDomain={[
                        now.clone().subtract(1, 'day'),
                        now.clone()
                    ]}
                    yDomain={[Math.min(0, min_24h), Math.max(10, max_24h)]}
                    margin={{ left: 1, right: 1, top: 1, bottom: 1 }}
                    className="pwaVal24hPlot"
                >
                    <XAxis hideTicks />
                    <YAxis hideTicks />
                    <AreaSeries
                        color={Palette.named.yellow}
                        fill={Palette.named.yellow}
                        opacity={0.4}
                        getNull={(d) => {
                            return d[1] !== null;
                        }}
                        data={_.map(datapoints, (item) => {
                            return { x: item[0] * 1000, y: item[1] !== null ? item[1] : null };
                        })
                        }
                        curve={"curveMonotoneX"}
                    />
                </FlexibleWidthXYPlot>
            </Grid.Column>
        </Grid>
    );
});

Val24h.propTypes = {
    measure: PropTypes.object.isRequired,
    overview24h: PropTypes.object.isRequired
};

export default Val24h;
