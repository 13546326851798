import _ from "lodash";
import axios from "axios";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { alertApi } from "./alertService";

const initialState = {
    init_alerts: [],
    alerts: [],
    filter: {
        searchName: "",
        dayFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
    alert: {
        current: null,
        default: {
            name: "",
            start_date: "00:00:00",
            end_date: "23:59:59",
            mailinglist: [],
            days: [],
            duration_threshold: 0,
            site: null,
            validity: 0,
            conditions: [{ id: uuidv4(), type: null, children: [] }]
        }
    }
};

export const createAlert = createAsyncThunk("alert/createAlert", async ({ formData }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, "name", null);
    const user_id = _.get(thunkAPI.getState().auth, "user.user_id", null);
    try {
        const response = await PowerAdaptAPI.post(`/alerts?org=${current_org}&${refreshTimestampUrlParam()}`, {
            ...formData,
            user: user_id,
            duration_threshold: formData.duration_threshold * 60
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

export const updateAlert = createAsyncThunk("alert/updateAlert", async ({ formData }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, "name", null);
    try {
        const response = await PowerAdaptAPI.put(`/alerts/${formData.id}?org=${current_org}&${refreshTimestampUrlParam()}`, {
            ...formData,
            duration_threshold: formData.duration_threshold * 60
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setDayFilter: (state, action) => {
            state.filter.dayFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.alerts = state.init_alerts;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(setSearchNameFilter, setDayFilter), (state, action) => {
                const filtered_alert = _.chain(state.init_alerts)
                    .reduce((res, alert) => {
                        /* name filter */
                        if (state.filter.searchName === "") {
                            res.push(alert);
                        } else if (_.includes(alert.name.toLowerCase(), state.filter.searchName.toLowerCase())) {
                            res.push(alert);
                        }
                        return res;
                    }, [])
                    .reduce((res, alert) => {
                        if (_.size(state.filter.dayFilter) === 0) {
                            res.push(alert);
                        } else if (!_.isEmpty(_.intersection(state.filter.dayFilter, alert.days))) {
                            res.push(alert);
                        }
                        return res;
                    }, [])
                    .value();

                state.alerts = filtered_alert;
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(alertApi.endpoints.getAlerts.matchFulfilled, (state, action) => {
                state.init_alerts = action.payload;
                state.alerts = action.payload;
            })
            .addMatcher(alertApi.endpoints.getAlert.matchFulfilled, (state, action) => {
                state.alert.current = action.payload;
            })
            .addMatcher(alertApi.endpoints.deleteAlert.matchFulfilled, (state, action) => {
                const id = _.get(action, "meta.arg.originalArgs.data");
                state.init_alerts = _.filter(state.init_alerts, (alert) => alert.id !== id);
                state.alerts = _.filter(state.alerts, (alert) => alert.id !== id);
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const { resetCurrentAlert, setSearchNameFilter, setDayFilter, resetFilterWithPage, setItemsPerPage, setPage, setStickPage } =
    alertSlice.actions;

export default alertSlice.reducer;
