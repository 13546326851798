import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import moment from 'moment';
import { Segment, Grid, Header, Button, Input, Icon, Dropdown, Label, Card, Popup } from 'semantic-ui-react';

import i18n from "modules/i18n/i18nConfig";
import {
    getEvents,
    getTriggeredAlerts,
    resetFilterWithPage, setActivityTypeFilter, setItemsPerPage,
    setPage, setSearchNameFilter, setStickPage, setTime
} from 'modules/alert/alert_event/alerteventSlice';
import history_app from 'history_app';
import { dateRangeOptions } from 'modules/time/utils';

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from 'modules/common/components/MessageDisplay';
import TimePanel from 'modules/common/components/TimePanel';

const activityTypeOptions = [
    { key: 'event', value: 'event', text: i18n._(t`event`) },
    { key: 'alert', value: 'alert', text: i18n._(t`alert`) },
];

const AlertEvents = (props) => {
    const dispatch = useDispatch();
    const { org } = useSelector(state => state);
    const [readyRequest, setReadyRequest] = useState(false);
    const [readyFilter, setReadyFilter] = useState(false);
    const [localSearchName, setLocalSearchName] = useState("");
    const { alertevent, measurement } = useSelector((state) => state);

    const current_lng = useSelector(state => state.i18n.current);
    const now = moment();

    const default_time = {
        start: now.clone().subtract(30, 'days').startOf("days"),
        end: now.clone().startOf('minute'),
        plage: '30d'
    };

    useEffect(() => {
        if (readyRequest) {
            (async () => {
                await setLocalSearchName(alertevent.filter.searchName);
                await dispatch(setSearchNameFilter(alertevent.filter.searchName));
                await dispatch(setActivityTypeFilter(alertevent.filter.activityTypeFilter));
                //Change stick page after filter application.
                alertevent.pagination.stickPage && await dispatch(setStickPage(false));
                setReadyFilter(true);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readyRequest, dispatch]);

    useEffect(() => {
        (async () => {
            if (alertevent.time) {
                const { start, end } = alertevent.time;
                await dispatch(getEvents({ org: org.current, start, end }));
                await dispatch(getTriggeredAlerts({ org: org.current, start, end }));
            } else {
                await dispatch(getEvents({
                    org: org.current,
                    start: default_time.start.toISOString(),
                    end: default_time.end.toISOString(),
                }));
                await dispatch(getTriggeredAlerts({
                    org: org.current,
                    start: default_time.start.toISOString(),
                    end: default_time.end.toISOString(),
                }));
            }
            await dispatch(resetFilterWithPage());
            await setReadyRequest(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertevent.time]);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "date", label: i18n._(t`triggered_or_event_date`), textAlign: "left" },
        { id: "reason", label: i18n._(t`triggered_reason`), textAlign: "left" },
        { id: "condition", label: i18n._(t`display_condition`), textAlign: "left" },
        { id: "notified_date", label: i18n._(t`notified_date`), textAlign: "right" }
    ];

    const alertevent_list = _.chain([...alertevent.event.events, ...alertevent.triggered.alerts])
        .reduce((res, item, idx) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };

            let renderReason = () => {
                return <Label color="red">-</Label>;
            };

            let renderCondition = () => {
                return '-';
            };

            switch (item.type) {
                case "event":
                    const measure = _.find(measurement.measurements, { id: _.chain(item).get('measurement_ids').head().value() });
                    const mt_type = _.get(measure, "measurementtype");

                    renderReason = () => {
                        switch (_.get(mt_type, 'name', '-')) {
                            case "di":
                                return (
                                    <Label color={item.reason === true ? "green" : "red"}>
                                        {item.reason === true ? i18n._(t`up`) : i18n._(t`down`)}
                                    </Label>
                                );
                            case "voltage_detected":
                                return (
                                    <Label color={item.reason === true ? "green" : "red"}>
                                        {item.reason === true ? i18n._(t`power on`) : i18n._(t`power off`)}
                                    </Label>
                                );
                            case "trip":
                                return (
                                    <Label color={item.reason === true ? "red" : "green"}>
                                        {item.reason === true ? i18n._(t`tripped`) : i18n._(t`trip reset`)}
                                    </Label>
                                );
                            default:
                                return <Label color="red">-</Label>;
                        }
                    };
                    break;
                case "alert":
                    renderReason = () => {
                        switch (item.reason) {
                            case 0:
                                return <Label color={"red"}>
                                    {i18n._(t`end reason`)}
                                </Label>;
                            case 1:
                                return <Label color={"green"}>
                                    {i18n._(t`start reason`)}
                                </Label>;
                            default:
                                return <Label color="red">-</Label>;
                        }
                    };

                    renderCondition = () => {
                        return <Popup trigger={
                            <div style={{ zIndex: 10 }}>
                                <span style={customStyle}>{_.get(item, 'display_condition')}</span>
                            </div>}
                        >
                            <Card>
                                <Card.Content>
                                    <Card.Header><Trans>triggered_values</Trans></Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    {_.chain(item.measurement_ids).filter((measure) => {
                                        const mes = _.find(measurement.measurements, { id: measure });
                                        return mes;
                                    }).map((measure, idx) => {
                                        const mes = _.find(measurement.measurements, { id: measure });
                                        const trig_val = _.get(item.triggered_values, measure, '-');
                                        return <Segment basic key={idx} style={{ ...customStyle, overflow: "hidden", textOverflow: "ellipsis" }}>
                                            <span>"{mes.name}"</span>{'\u00A0'}={'\u00A0'}<span>{trig_val}</span>{'\u00A0'}<span>{_.result(mes, 'display_unit.intensive', null) || _.result(mes, 'display_unit.symbol', '-')}</span>
                                        </Segment>;
                                    }).value()}
                                </Card.Content>
                            </Card>
                        </Popup>;
                    };
                    break;
                default:
                    return res;
            }

            res.push({
                id: { render: null, value: item.idx, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", '-')}</span>,
                    value: _.get(item, "name", '-'),
                    textAlign: "left",
                    datatype: "string"
                },
                date: {
                    render:
                        item.date !== null
                            ? <span style={customStyle}>{`${moment(item.date).locale(current_lng).format("L")} ${moment(item.date).locale(current_lng).format("LTS")}`}</span>
                            : "-",
                    value: item.date !== null ? moment(item.date) : null,
                    textAlign: "left",
                    datatype: "date"
                },
                reason: {
                    render: renderReason(),
                    value: +_.get(item, "reason"),
                    textAlign: "left",
                    datatype: "number"
                },
                condition: {
                    render: renderCondition(), //Add tooltip for measurements detail
                    value: _.get(item, 'display_condition', '-'),
                    textAlign: "left",
                    datatype: "string"
                },
                notified_date: {
                    render:
                        _.get(item, "notif_date") && item.notif_date !== null
                            ? <span style={customStyle}>{`${moment(item.notif_date)
                                .locale(current_lng)
                                .format("L")} ${moment(item.notif_date)
                                    .locale(current_lng)
                                    .format("LTS")}`}</span>
                            : "-",
                    value:
                        _.get(item, "notif_date") && item.notif_date !== null
                            ? moment(item.notif_date)
                            : null,
                    textAlign: "right",
                    datatype: "date"
                }
            });
            return res;
        }, [])
        .value();

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(['24h', '48h', '72h'], item.value));

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>alertevents management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <TimePanel
                            time={
                                alertevent.time ? {
                                    start: moment(alertevent.time.start), end: moment(alertevent.time.end), plage: alertevent.time.plage
                                } : default_time
                            }
                            rangeOptions={rangeOptions}
                            accordion={true}
                            action={({ start, end, plage }) => {
                                dispatch(resetFilterWithPage());
                                dispatch(setTime({ start, end, plage }));
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                {!readyRequest && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {readyRequest && !readyFilter && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading filter`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {readyRequest && readyFilter && (
                    <>
                        {_.some([alertevent.event.error, alertevent.triggered.error]) && (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <Grid celled>
                                    <Grid.Column mobile={16} tablet={3} computer={3}>
                                        <Input
                                            fluid
                                            icon='search'
                                            placeholder={i18n._(t`search identifier`)}
                                            onChange={(e, { value }) => {
                                                setLocalSearchName(value);
                                                dispatch(setSearchNameFilter(value));
                                            }}
                                            value={localSearchName}
                                        />
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={3} computer={3}>
                                        <Dropdown
                                            fluid
                                            options={_.map(activityTypeOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                            placeholder={i18n._(t`select activity`)}
                                            multiple
                                            selection
                                            onChange={(e, { value }) => { dispatch(setActivityTypeFilter(value)); }}
                                            value={alertevent.filter.activityTypeFilter}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Button
                                            icon
                                            labelPosition="left"
                                            onClick={async (e) => {
                                                await dispatch(resetFilterWithPage());
                                                history_app.push(`/alerts`);
                                            }}
                                        >
                                            <Icon name="bullhorn" />
                                            <Trans>alert setup</Trans>
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <TableEnhanced
                                            headCells={headCells}
                                            rows={alertevent_list}
                                            textItemPerPages={i18n._(t`items per page`)}
                                            order="desc"
                                            orderBy="date"
                                            page={alertevent.pagination.page}
                                            rowsPerPage={alertevent.pagination.itemsPerPage}
                                            setPage={(page) => { dispatch(setPage(page)); }}
                                            setItemsPerPage={(items) => { dispatch(setItemsPerPage(items)); }}
                                        />
                                    </Grid.Column>

                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )}
            </Grid>
        </Segment>
    );
};

export default AlertEvents;