import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Header } from "semantic-ui-react";
import { addSelectedEquipment, removeSelectedEquipment } from "modules/dashboard/dashboardSlice";

export const Name = React.memo((props) => {
    const { equipment } = props;
    const dispatch = useDispatch();
    const { selected_equipments } = useSelector((state) => state.dashboard);

    const isSelected = !_.chain(selected_equipments).find({ id: equipment.id }).isUndefined().value();

    const onHandleChange = (event, data) => {
        if (event.target.checked) {
            dispatch(addSelectedEquipment(equipment));
        } else {
            dispatch(removeSelectedEquipment(equipment.id));
        }
    };

    //'16' is DigitalInput dataflowspec
    return _.chain([16]).intersection(_.get(equipment, "dataflowspec_set", [])).size() > 0 ? (
        <Header as="h4" className="pwaNameWidgetDI">
            {_.get(equipment, "name", "-")}
        </Header>
    ) : (
        <div className="ui checkbox pwaNameWidget">
            <input type="checkbox" onChange={onHandleChange} checked={isSelected} />
            <label style={{ cursor: "default" }}>{_.get(equipment, "name", "-")}</label>
        </div>
    );
});

Name.propTypes = {
    equipment: PropTypes.object.isRequired
};

export default Name;
