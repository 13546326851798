import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form, Field } from "react-final-form";
import { Grid, Segment, Icon, Button, Header, Popup, Divider } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { identity, identityNull } from "modules/common/utils/form";
import { advancedUnitsOptions, graphicTypeOptions, denominatorUnitsOptions } from "../../../analysisAdvanced/utils";
import {
    WidgetTypeOptions,
    historic_options_widget,
    keyToReparseInDnD,
    operator_options,
    updateWidthHeigthFromMin
} from "modules/dashboardDragNDrop/utils";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useCreateWidgetMutation, useUpdateWidgetMutation } from "../../dashboardDndService";

import { InputAdapter, DropDownAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DropdownAdapterMeasurement from "modules/export/components/DropdownAdapterMeasurement";
import GaugeViewChoicesPanel from "./GaugeViewChoicesPanel";

const GaugeRatio = (props) => {
    const { current_dashboard, onClose, widgetChoice, widget, disabled } = props;

    const { org } = useSelector((state) => state);

    const [createWidget, create] = useCreateWidgetMutation();
    const [updateWidget, update] = useUpdateWidgetMutation();

    // Create Widget
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful create widget`), toast_options);
            onClose();
        }
        if (create.isError) {
            toast.error(i18n._(t`Can't create widget`), toast_options_err);
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError, create.isLoading]);

    // Update Widget
    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update widget`), toast_options);
            onClose();
        }
        if (update.isError) {
            toast.error(i18n._(t`Can't update widget`), toast_options_err);
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isSuccess, update.isError]);

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        {
            skip:
                !org.current ||
                categories.data === undefined ||
                tags.data === undefined ||
                sites.data === undefined ||
                zones.data === undefined ||
                usages.data === undefined
        }
    );
    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        {
            skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined,
            units: units.data
        }
    );

    const err_list = [
        sites.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        categories.isError,
        equipments.isError,
        units.isError,
        measurements.isError,
        dataflows.isError,
        measurementtypes.isError
    ];

    const status_list = [
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        equipments.isSuccess,
        units.isSuccess,
        measurements.isSuccess,
        dataflows.isSuccess,
        measurementtypes.isSuccess
    ];

    const initialValues = useMemo(() => {
        const defaultValues = {
            name: null,
            widget_type: widgetChoice,
            dashboard: current_dashboard.id,
            equipment_name: null,
            sites: null,
            zones: [],
            usages: [],
            tags: [],
            categories: [],
            numerator_unit: null,
            numerator_factor: null,
            numerator_factor_unit: null,
            operator: operator_options[0].value,
            denominator: null,
            denominator_measurement: null,
            denominator_factor: null,
            denominator_factor_unit: null,
            measurements: [],
            x: 0,
            y: 0,
            w: 2,
            h: 4,
            historic: null,
            aggregation: 1
        };

        return {
            ...defaultValues,
            denominator: defaultValues.denominator === null ? "no denominator type" : defaultValues.denominator,
            ...widget,
            denominator_factor_unit: (widget?.denominator_factor_unit ?? null) === null ? -1 : widget.denominator_factor_unit,
            numerator_factor_unit: (widget?.numerator_factor_unit ?? null) === null ? -1 : widget.numerator_factor_unit,
            sites: _.chain(widget)
                .get("sites", [])
                .head()
                .defaultTo(_.size(sites.data) === 1 && sites.data[0].id ? sites.data?.[0].id : null)
                .value()
        };
    }, [widget, widgetChoice, current_dashboard.id, sites.data]);

    const remapUnits = useMemo(() => {
        return [{ key: -1, text: i18n._(t`no unit`), value: -1 }, ..._.filter(units.data, (item) => item.key !== -1)];
    }, [units]);

    //limit category for dropdown measurement
    const { restricted_cats_id, filtered_cats } = useMemo(() => {
        if (categories.isSuccess) {
            const cats = _.chain(categories.data)
                .reduce((res, category) => {
                    if (_.includes(["elec", "gas", "water", "calories", "frigories"], category.name)) {
                        res.push(category);
                    }
                    return res;
                }, [])
                .value();
            return {
                filtered_cats: cats,
                restricted_cats_id: _.map(cats, "id")
            };
        }
        return { restricted_cats_id: [], filtered_cats: [] };
    }, [categories]);

    //limit measurementtype form dropdown measurement
    const restricted_mttypes_id = useMemo(() => {
        if (measurementtypes.isSuccess) {
            return _.reduce(
                measurementtypes.data,
                (res, mttype) => {
                    if (_.includes(["p_act_import", "index_nm3", "water_import"], mttype.name)) {
                        res.push(mttype.id);
                    }
                    return res;
                },
                []
            );
        }
        return [];
    }, [measurementtypes]);

    const restricted_measurements = useMemo(() => {
        if (measurements.isSuccess) {
            return _.chain(measurements)
                .get("data", [])
                .reduce((res, measure) => {
                    if (_.includes(restricted_mttypes_id, measure?.measurementtype?.id)) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .value();
        }
        return [];
    }, [measurements, restricted_mttypes_id]);

    //limit historics options (exculde "since midnight" option)
    const filter_historic_options = historic_options_widget.filter((item) => item.value !== 8);

    const submitForm = async (formdata) => {
        //reparsed text to number
        let data = _.mapValues(formdata, (item, key) => {
            if (_.includes(keyToReparseInDnD, key)) {
                return reparseNumber(item);
            }
            return item;
        });

        data = {
            ...data,
            denominator: data.denominator === "no denominator type" ? null : data.denominator,
            historic: data.operator === 0 ? null : data.historic,
            //rewrite factor because final_form return string instead of number
            denominator_factor: !_.isFinite(data.denominator_factor) ? null : data.denominator_factor,
            numerator_factor: !_.isFinite(data.numerator_factor) ? null : data.numerator_factor,
            denominator_factor_unit:
                data.denominator_factor_unit === -1 || !_.isFinite(data.denominator_factor) ? null : data.denominator_factor_unit,
            numerator_factor_unit: data.numerator_factor_unit === -1 || !_.isFinite(data.numerator_factor) ? null : data.numerator_factor_unit,
            sites: [data?.sites ?? null]
        };

        if (widget) {
            await updateWidget({ formdata: updateWidthHeigthFromMin(data), org: org.current, dashboard_id: current_dashboard.id });
        } else {
            await createWidget({ formdata: data, org: org.current, dashboard_id: current_dashboard.id });
        }
    };

    const title = i18n._(_.chain(WidgetTypeOptions).find({ value: widgetChoice }).get("text", "-").value());

    const MyHeader = () => (
        <Header attached="top" block style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {title} 
            <Popup position="bottom center" trigger={<Icon name="question circle" />}>
                <Segment basic style={{ width: "50vh" }}>
                    <p>
                        <Trans>
                            Statistical (min, average, max or last value) of a ratio over a period, displayed in a linear gauge, dial or simple text
                        </Trans>
                    </p>
                    <p>
                        <Trans>Average ratio is the overall ratio over the period.</Trans>
                    </p>
                </Segment>
            </Popup>
        </Header>
    );

    const sites_id = useMemo(() => {
        return _.map(sites.data, (item) => item.id);
    }, [sites.data]);

    const validate = (values) => {
        const err = {};
        if (!disabled) {
            const site = _.find(sites.data, { id: values.sites });
            if (site && !site.timezone) {
                err.sites = <Trans>Site has no timezone definition</Trans>;
            }

            if (_.includes(["cost", "kcost"], values.numerator_unit)) {
                const missingCurrency = site && !site?.conversions?.currency;
                if (missingCurrency) {
                    err.sites = <Trans>Site has no currency definition</Trans>;
                }
            }
            if (values.operator > 0 && values.historic === null) {
                err.historic = <Trans>Required field</Trans>;
            }
        }
        return err;
    };

    return (
        <>
            {(() => {
                if (_.some(err_list)) {
                    return (
                        <>
                            <MyHeader />
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={true}
                            />
                            <Segment attached textAlign="right" basic>
                                <Button type="button" negative onClick={() => onClose()}>
                                    <Trans>cancel</Trans>
                                </Button>
                            </Segment>
                        </>
                    );
                } else if (_.every(status_list)) {
                    return (
                        <Form
                            onSubmit={submitForm}
                            validate={validate}
                            initialValues={initialValues}
                            render={({ handleSubmit, submitting, pristine, invalid, values, form }) => {
                                const threshold = [
                                    reparseNumber(values.low_limit),
                                    reparseNumber(values.low_intermediate_limit),
                                    reparseNumber(values.high_intermediate_limit),
                                    reparseNumber(values.high_limit)
                                ];

                                const isIncrease = _.every(threshold, (value, index, array) => {
                                    return index === 0 || value >= array[index - 1];
                                });
                                const areDiff = _.uniq(threshold).length === threshold.length;
                                const strict_increase = isIncrease && areDiff;

                                const not_visible_sites = values.id && !_.includes(sites_id, values.sites);

                                return (
                                    <form onSubmit={handleSubmit} className="ui form" style={{ marginTop: "1em" }}>
                                        <MyHeader />
                                        {!disabled && !values.sites && (
                                            <MessageDisplay
                                                message={i18n._(t`select 1 site`)}
                                                level="info"
                                                iconName="info circle"
                                                isLoading={false}
                                            />
                                        )}
                                        <Segment attached>
                                            <Field
                                                name="name"
                                                disabled={disabled}
                                                label={i18n._(t`widget name`)}
                                                placeholder={i18n._(t`enter name of widget`)}
                                                isRequired={true}
                                                component={InputAdapter}
                                                validate={(value) => {
                                                    if (!disabled) {
                                                        if (!value) {
                                                            return <Trans>Required field</Trans>;
                                                        }
                                                    }
                                                    return undefined;
                                                }}
                                                onKeyDown={(e) => {
                                                    //Hack to prevent parent dropdown tabspace interaction
                                                    if (e.keyCode === 32) {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </Segment>
                                        <Header attached block textAlign="center">
                                            <Trans>Numerator section</Trans>
                                        </Header>
                                        <Segment attached>
                                            <Grid stackable centered verticalAlign="top">
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="numerator_unit"
                                                            className="icon"
                                                            icon="fire"
                                                            button
                                                            disabled={disabled}
                                                            labeled
                                                            search={false}
                                                            label={i18n._(t`select unit`)}
                                                            isRequired={true}
                                                            placeholder={i18n._(t`select unit`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={advancedUnitsOptions}
                                                            component={DropDownAdapter}
                                                            defaultValue={graphicTypeOptions[0].value}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!value) {
                                                                        return <Trans>Required field</Trans>;
                                                                    }
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="numerator_factor"
                                                            placeholder={i18n._(t`enter numerator factor`)}
                                                            label={i18n._(t`numerator_factor`)}
                                                            component={InputAdapter}
                                                            parse={identityNull}
                                                            inputMode="decimal"
                                                            disabled={disabled}
                                                            defaultValue={null}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    return validateNumber(value, i18n, false, true);
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="numerator_factor_unit"
                                                            label={i18n._(t`numerator_factor_unit`)}
                                                            placeholder={i18n._(t`select numerator factor unit`)}
                                                            options={remapUnits} //exclude 'dynamic_unit'
                                                            component={DropDownAdapter}
                                                            disabled={disabled || !_.isFinite(reparseNumber(values.numerator_factor))}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            label={i18n._(t`select statistic`)}
                                                            name="operator"
                                                            isRequired={true}
                                                            search={false}
                                                            placeholder={i18n._(t`value displayed`)}
                                                            options={operator_options}
                                                            component={DropDownAdapter}
                                                            disabled={disabled}
                                                            customAction={(data) => {
                                                                if (data === 0) {
                                                                    form.change("historic", null);
                                                                }
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            disabled={disabled || values?.operator === 0}
                                                            label={i18n._(t`historic`)}
                                                            name="historic"
                                                            className="icon"
                                                            icon="calendar"
                                                            isRequired={values?.operator !== 0}
                                                            button
                                                            labeled
                                                            search={false}
                                                            placeholder={i18n._(t`historic`)}
                                                            options={values?.operator === 0 ? null : filter_historic_options}
                                                            component={DropDownAdapter}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Field
                                                            name="equipment_name"
                                                            disabled={disabled}
                                                            label={i18n._(t`equipment name filter`)}
                                                            placeholder={i18n._(t`Enter a filter on the name of equipments`)}
                                                            component={InputAdapter}
                                                            parse={identity}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Field
                                                            name="sites"
                                                            label={i18n._(t`select site`)}
                                                            placeholder={i18n._(t`select site`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={sites.data}
                                                            isRequired={true}
                                                            disabled={disabled}
                                                            component={DropDownAdapter}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!_.isFinite(value)) return <Trans>Required field</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                            customAction={(data) => {
                                                                form.change("zones", []);
                                                                if (values.denominator === "measurement") {
                                                                    form.change("denominator_measurement", null);
                                                                }
                                                            }}
                                                        />
                                                        {not_visible_sites && (
                                                            <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                                <Trans>You do not have permission to view site</Trans>
                                                            </div>
                                                        )}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Divider horizontal>
                                                            <Trans>Additional filters</Trans>
                                                        </Divider>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="zones"
                                                            placeholder={i18n._(t`select zones`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={_.filter(zones.data, (item) => {
                                                                return item?.site_id === values.sites;
                                                            })}
                                                            multipleselect={1}
                                                            disabled={disabled || !values.sites}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "teal",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="usages"
                                                            placeholder={i18n._(t`select usages`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={usages.data}
                                                            multipleselect={1}
                                                            disabled={disabled}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "violet",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="tags"
                                                            placeholder={i18n._(t`select tags`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={tags.data}
                                                            multipleselect={1}
                                                            disabled={disabled}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "grey",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="categories"
                                                            placeholder={i18n._(t`select categories`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={filtered_cats}
                                                            disabled={disabled}
                                                            multipleselect={1}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "purple",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>

                                        <Header attached block textAlign="center">
                                            <Trans>Denominator section</Trans>
                                        </Header>
                                        <Segment attached>
                                            <Grid stackable centered verticalAlign="top">
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="denominator"
                                                            disabled={disabled}
                                                            placeholder={i18n._(t`select denominator type`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={denominatorUnitsOptions}
                                                            component={DropDownAdapter}
                                                            defaultValue={graphicTypeOptions[0].value}
                                                        />
                                                    </Grid.Column>
                                                    {values?.denominator === "measurement" && (
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                name="denominator_measurement"
                                                                placeholder={i18n._(t`choose your measurement here`)}
                                                                options={restricted_measurements}
                                                                component={DropdownAdapterMeasurement}
                                                                sites={sites.data}
                                                                usages={usages.data}
                                                                disabled={disabled || !values.sites}
                                                                siteDisabled={true}
                                                                sites_filter={[values.sites]}
                                                                restricted_cats_id={restricted_cats_id}
                                                                displayAddBtn={false}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                open={false}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (!value) {
                                                                            return <Trans>Required field</Trans>;
                                                                        }
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                </Grid.Row>

                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="denominator_factor"
                                                            placeholder={i18n._(t`enter denominator factor`)}
                                                            disabled={disabled}
                                                            label={i18n._(t`denominator_factor`)}
                                                            component={InputAdapter}
                                                            parse={identityNull}
                                                            inputMode="decimal"
                                                            defaultValue={null}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    return validateNumber(value, i18n, false, true);
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="denominator_factor_unit"
                                                            label={i18n._(t`denominator_factor_unit`)}
                                                            placeholder={i18n._(t`select denominator factor unit`)}
                                                            options={remapUnits} //exclude 'dynamic_unit'
                                                            component={DropDownAdapter}
                                                            disabled={disabled || !_.isFinite(reparseNumber(values.denominator_factor))}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                        <GaugeViewChoicesPanel values={values} strict_increase={strict_increase} disabled={disabled} />
                                        <Segment attached basic textAlign="right">
                                            <Button type="button" negative onClick={() => onClose()}>
                                                <Trans>cancel</Trans>
                                            </Button>
                                            {!disabled && (
                                                <Button
                                                    type="submit"
                                                    positive
                                                    icon
                                                    labelPosition="right"
                                                    disabled={submitting || pristine || invalid || strict_increase === false}
                                                >
                                                    <Icon name="check" />
                                                    <Trans>validate</Trans>
                                                </Button>
                                            )}
                                        </Segment>
                                    </form>
                                );
                            }}
                        />
                    );
                }
                return (
                    <>
                        <MyHeader />
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        <Segment attached textAlign="right" basic>
                            <Button type="button" negative onClick={() => onClose()}>
                                <Trans>cancel</Trans>
                            </Button>
                        </Segment>
                    </>
                );
            })()}
        </>
    );
};

export default GaugeRatio;
