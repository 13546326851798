import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Button, Icon, Loader, Modal, Segment, Statistic } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';
import { useGetLastValuesQuery } from 'modules/dataflow/dataflowService';

import HeaderWHelper from 'modules/common/components/HeaderWHelper';

export const Accumulation = (props) => {
    const {
        measure_operating_cycles,
        measure_operating_hours
    } = props;

    const dataflow_id = _.get(measure_operating_cycles, "dataflow");
    const [open, setOpen] = useState(false);
    const current_lng = useSelector(state => state.i18n.current);
    const { org } = useSelector(state => state);

    const last_values = useGetLastValuesQuery({ org: org.current, pk_list: dataflow_id });

    const offset_at = _.get(measure_operating_hours, 'offset_update_at');

    const operating_hours_counter = _.chain(last_values)
        .get('data.[0].measurements')
        .find((item) => (item.id === _.get(measure_operating_hours, 'id')))
        .get("last_extensive")
        .value();

    const cycles_counter = _.chain(last_values)
        .get('data.[0].measurements')
        .find((item) => (item.id === _.get(measure_operating_cycles, 'id')))
        .get("last_extensive")
        .value();

    return (
        <>
            <HeaderWHelper
                text={<Trans>accumulation operation</Trans>}
                helper={
                    <Trans>
                        <p>
                            Nombre d'heures pendant lesquelles la puissance est au dessus du
                            seuil de no load (Seuil défini sur le PE6)
                        </p>
                        <p>Le nombre de cycle correspond au nombre de démarrages.</p>
                        <p>
                            Un démarrage est comptabilisé lorsque la consommation dépasse un
                            seuil bas de différence (par exemple passage du mode de veille au
                            mode de fonctionnement)
                        </p>
                    </Trans>
                }
            />
            <Segment.Group compact>
                <Segment tertiary>
                    {`${i18n._(t`last reset`)} : `}
                    {last_values.isSuccess && (<b><em>{`${offset_at ? moment(offset_at).locale(current_lng).format('LLL') : i18n._(t`never`)}`}</em></b>)}
                    {last_values.isLoading && (<Loader active inline size='small' />)}
                    {last_values.isError && (<Icon name="warning" color="red" />)}
                </Segment>
                <Segment.Group compact horizontal>
                    <Segment padded>
                        <Statistic size="mini">
                            <Statistic.Value>
                                {last_values.isSuccess && (<><Icon name="clock outline" /> {`${Math.floor(operating_hours_counter)}`}</>)}
                                {last_values.isLoading && (<Loader active inline size='small' />)}
                                {last_values.isError && (<Icon name="warning" color="red" />)}
                            </Statistic.Value>
                            <Statistic.Label>
                                <Trans>accumulation hours</Trans>
                            </Statistic.Label>
                        </Statistic>
                    </Segment>
                    <Segment padded>
                        <Statistic size="mini">
                            <Statistic.Value>
                                {last_values.isSuccess && (<><Icon name="sync" /> {`${cycles_counter}`}</>)}
                                {last_values.isLoading && (<Loader active inline size='small' />)}
                                {last_values.isError && (<Icon name="warning" color="red" />)}
                            </Statistic.Value>
                            <Statistic.Label>
                                <Trans>number of cycles</Trans>
                            </Statistic.Label>
                        </Statistic>
                    </Segment>
                    {false && (
                        <Segment padded>
                            <Button onClick={() => setOpen(true)} fluid>
                                RAZ
                            </Button>
                            <Modal open={open}>
                                <Modal.Header>
                                    <Trans>Reset cumul</Trans>
                                </Modal.Header>
                                <Modal.Content>
                                    <p>
                                        <Trans>want reset cumul ?</Trans>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        onClick={() => setOpen(false)}
                                        labelPosition="right"
                                        icon="delete"
                                        content="No"
                                    />
                                    <Button
                                        onClick={() => setOpen(false)}
                                        labelPosition="right"
                                        icon="checkmark"
                                        content="Yes"
                                    />
                                </Modal.Actions>
                            </Modal>
                        </Segment>
                    )}
                </Segment.Group>
            </Segment.Group>
        </>
    );

};

export default Accumulation;
