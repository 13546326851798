import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import axios from "axios";
import { Grid } from "semantic-ui-react";

import PowerAdapt, { refreshTimestampUrlParam } from "apis/PowerAdapt";

import Val24h from "./widgets/Val24h";
import LastVal from "./widgets/LastVal";
import Val7Day from "./widgets/Val7Day";
import MinVal from "./widgets/MinVal";
import MaxVal from "./widgets/MaxVal";
import UsagePercent from "./widgets/UsagePercent";

export const PowerPanel = (props) => {
    const isMounted = useRef(false);
    const { equipment, lastValues, eqt_machine } = props;
    const { org } = useSelector((state) => state);
    const [overview24h, setOverview24h] = useState({
        status: "idle",
        error: null,
        data: { measurements: [], machine: null }
    });
    const current_org = _.get(org, "current.name", null);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const getOverview24h = async (equipment, current_org) => {
        try {
            isMounted.current && (await setOverview24h({ status: "loading", error: null, data: [] }));
            const count_measurements = _.size(equipment.measurements);
            //if multiple p_act_import. Check mtType_tech if it's e_act_counter. Prevent Error with PE6 non expert => PE6 expert
            const has_measure_counter =
                count_measurements >= 2 &&
                _.chain(equipment.measurements)
                    .filter((measure) => _.get(measure, "measurementtype_tech.name") === "e_act_counter")
                    .size()
                    .value() > 0;

            const response_measurements =
                isMounted.current &&
                (await Promise.all(
                    _.map(equipment.measurements, async (measure) => {
                        if (has_measure_counter && _.get(measure, "measurementtype_tech.name") === "p_act_import") return null;
                        try {
                            const response = await PowerAdapt.get(
                                `/measurements/${measure.id}/overview24h?org=${current_org}&${refreshTimestampUrlParam()}`
                            );
                            return { ...response.data, measure: measure.id };
                        } catch (error) {
                            return null;
                        }
                    })
                ));
            if (eqt_machine && eqt_machine?.is_predict) {
                const response_machine = await PowerAdapt.get(
                    `/machines/${equipment.machine}/overview24h?org=${current_org}&pk_list=${equipment.machine}&${refreshTimestampUrlParam()}`
                );
                isMounted.current &&
                    (await setOverview24h({
                        status: "succeeded",
                        error: null,
                        data: { measurements: _.filter(response_measurements, _.isObject), machine: response_machine.data }
                    }));
            } else {
                isMounted.current &&
                    (await setOverview24h({
                        status: "succeeded",
                        error: null,
                        data: { measurements: _.filter(response_measurements, _.isObject), machine: null }
                    }));
            }
        } catch (error) {
            isMounted.current &&
                (await setOverview24h({
                    status: "failed",
                    error: axios.isAxiosError(error) ? error.message : "An unexpected error occurred",
                    data: []
                }));
        }
    };

    useEffect(() => {
        getOverview24h(equipment, current_org);
        const timer = setInterval(() => {
            getOverview24h(equipment, current_org);
        }, 60 * 10 * 1000);

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const count_measurements = _.size(equipment.measurements);

    //Define criteria to exclude p_act_import if e_act_counter exists (PE6 case)
    const has_measure_counter =
        count_measurements >= 2 &&
        _.chain(equipment.measurements)
            .filter((measure) => _.get(measure, "measurementtype_tech.name") === "e_act_counter")
            .size()
            .value() > 0;

    return _.map(equipment.measurements, (measure, idx) => {
        // Prevent display more than 4 widgets in dashboard
        if (idx >= 3) return null;
        //Remove p_act_import measurement if equipment has e_act_counter
        if (has_measure_counter && _.get(measure, "measurementtype_tech.name") === "p_act_import") return null;
        const datapoint_type = _.get(measure, "measurementtype.datapoint_type");
        return (
            <React.Fragment key={idx}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    {<LastVal equipment={equipment} measure={measure} lastValues={lastValues} overview24h={overview24h} />}
                </Grid.Column>
                {/* Criteria to display 3 instant gauge + 1 graphic */}
                {(_.size(equipment.measurements) < 3 || idx === 0) && (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        {<Val24h equipment={equipment} measure={measure} overview24h={overview24h} />}
                    </Grid.Column>
                )}
                {_.isEqual(datapoint_type, 3) && (
                    <>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            {<Val7Day measure={measure} overview24h={overview24h} />}
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <UsagePercent measure={measure} overview24h={overview24h} />
                        </Grid.Column>
                    </>
                )}
                {/* If only one measurment display Min and Max panel */}
                {count_measurements === 1 && !_.isEqual(datapoint_type, 3) && (
                    <>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            {<MinVal measure={measure} overview24h={overview24h} />}
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            {<MaxVal measure={measure} overview24h={overview24h} />}
                        </Grid.Column>
                    </>
                )}
            </React.Fragment>
        );
    });
};

export default PowerPanel;
