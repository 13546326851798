import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    filter: {
        searchEmail: "",
        searchRole: 1
    },
    pagination: {
        page: 1,
        itemsPerPage: 10
    },
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setSearchEmailFilter: (state, action) => {
            state.filter.searchEmail = action.payload;
        },
        setSearchRoleFilter: (state, action) => {
            state.filter.searchRole = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setSearchEmailFilter, setPage, setItemsPerPage, resetFilterWithPage,
    setSearchRoleFilter
} = userSlice.actions;

export default userSlice.reducer;