import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { setDashboardStickPage } from "modules/dashboard/dashboardSlice";



export const Details = React.memo((props) => {
    const dispatch = useDispatch();
    const { display, equipment, text, disabled } = props;
    return (
        <Button
            compact icon
            labelPosition={display === "label" ? "right" : null}
            onClick={async (e) => {
                if (!disabled) {
                    await dispatch(setDashboardStickPage(true));
                    if (_.chain([16]).intersection(_.get(equipment, 'dataflowspec_set')).size().value() > 0) {
                        history_app.push(`/activity/${equipment.id}`);
                    } else {
                        history_app.push(`/overview/${equipment.id}`);
                    }

                }

            }}
        >
            {display === "label" && i18n._(text)}
            <Icon name="chart area" />
        </Button>
    );
});

Details.defaultProps = {
    text: "detail",
    disabled: false
};

Details.propTypes = {
    equipment: PropTypes.object.isRequired,
    display: PropTypes.string.isRequired
};

export default Details;
