import _ from "lodash";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import moment from "moment";

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { exportFormatOptions, exportTypeOptions } from "modules/export/utils";
import { equipmentApi } from "modules/equipment/equipmentService";
import { dashboardApi } from "./dashboardService";

const now = moment();

const initialState = {
    init_equipments: [],
    sorted_equipments: [],
    equipments: [],
    filter: {
        ready: false,
        searchName: "",
        siteFilter: [],
        zoneFilter: [],
        usageFilter: [],
        tagFilter: [],
        categoryFilter: []
    },
    sort: {
        ready: false,
        category: 8,
        unit: null,
        order: true // true: asc, false:desc
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
    lastActivity_equipments: [],
    selected_equipments: [],
    actions: {
        instant_export: {
            data: null,
            status: "idle",
            error: null,
            parameters: {
                start: { start: now.clone().subtract(7, "days").startOf("days").toISOString(), err: false },
                end: { end: now.clone().startOf("minute").toISOString(), err: false },
                endBeforeStart: false,
                exportType: _.head(exportTypeOptions).value,
                exportFormat: _.head(exportFormatOptions).value,
                exportInstant: false,
                mtTypes: [],
                disable: true
            }
        },
        comparison: {
            parameters: {
                numOfDays: 7,
                disable: true,
                refStart: { start: now.clone().startOf("minute").subtract(7, "days").toISOString(), err: false },
                refEnd: { end: now.clone().startOf("minute").toISOString(), err: false },
                compareStart: { start: now.clone().startOf("minute").subtract(7, "days").toISOString(), err: false },
                compareEnd: { end: now.clone().startOf("minute").toISOString(), err: false },
                compareEndBeforeStart: false,
                mtType: null,
                measurements: []
            },
            detail: { data: [], error: null },
            summary: { data: [], error: null },
            consumption: { data: [], error: null },
            isReady: false
        }
    }
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        //Filter part for dashboard
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setSiteFilter: (state, action) => {
            state.filter.siteFilter = action.payload;
        },
        setZoneFilter: (state, action) => {
            state.filter.zoneFilter = action.payload;
        },
        setUsageFilter: (state, action) => {
            state.filter.usageFilter = action.payload;
        },
        setTagFilter: (state, action) => {
            state.filter.tagFilter = action.payload;
        },
        setCategoryFilter: (state, action) => {
            state.filter.categoryFilter = action.payload;
        },
        setReadyFilter: (state, action) => {
            state.filter.ready = action.payload;
        },
        //Sort part for dashboard
        setSortByCategory: (state, action) => {
            state.sort.category = action.payload;
        },
        setSortByUnit: (state, action) => {
            state.sort.unit = action.payload;
        },
        setSortOrder: (state, action) => {
            state.sort.order = action.payload;
        },
        setSortedEquipments: (state, action) => {
            state.sorted_equipments = action.payload;
        },
        setReadySort: (state, action) => {
            state.sort.ready = action.payload;
        },
        /* End dashboard Filter && Sort */
        //Custom pagination options
        setDashboardPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setDashboardItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setDashboardStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.equipments = state.init_equipments;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        },
        /* End custom pagination */
        // Selected equipments with Checkbox selector (See Name component)
        addSelectedEquipment: (state, action) => {
            state.selected_equipments = [...state.selected_equipments, action.payload];
        },
        addAllEquipments: (state, action) => {
            state.selected_equipments = action.payload;
        },
        removeSelectedEquipment: (state, action) => {
            state.selected_equipments = _.filter(state.selected_equipments, (item) => item.id !== action.payload);
        },
        resetSelectedEquipments: (state, action) => {
            state.selected_equipments = [];
        },
        /* End SelectedEquipment */
        //Instant export part (See Modal)
        setDisableExport: (state, action) => {
            state.actions.instant_export.parameters.disable = action.payload;
        },
        setEndBeforeStartExport: (state, action) => {
            state.actions.instant_export.parameters.endBeforeStart = action.payload;
        },
        setStartExport: (state, action) => {
            state.actions.instant_export.parameters.start = action.payload;
        },
        setEndExport: (state, action) => {
            state.actions.instant_export.parameters.end = action.payload;
        },
        setExportType: (state, action) => {
            state.actions.instant_export.parameters.exportType = action.payload;
        },
        setExportInstant: (state, action) => {
            state.actions.instant_export.parameters.exportInstant = action.payload;
        },
        setExportFormat: (state, action) => {
            state.actions.instant_export.parameters.exportFormat = action.payload;
        },
        setMtTypes: (state, action) => {
            state.actions.instant_export.parameters.mtTypes = action.payload;
        },
        resetInstantExport: (state, action) => {
            state.actions.instant_export = initialState.actions.instant_export;
        },
        /*end Instant export part */
        /* Comparison part */
        changeNumOfDays: (state, action) => {
            state.actions.comparison.parameters.numOfDays = action.payload;
            state.actions.comparison.parameters.refStart.start = moment(state.actions.comparison.parameters.refEnd.end)
                .subtract(action.payload, "days")
                .toISOString();
            state.actions.comparison.parameters.compareStart.start = moment(state.actions.comparison.parameters.compareEnd.end)
                .subtract(action.payload, "days")
                .toISOString();
        },
        setRefStartComparison: (state, action) => {
            state.actions.comparison.parameters.refStart = action.payload;
        },
        setRefEndComparison: (state, action) => {
            state.actions.comparison.parameters.refEnd = action.payload;
        },
        setCompareStartComparison: (state, action) => {
            state.actions.comparison.parameters.compareStart = action.payload;
        },
        setCompareEndComparison: (state, action) => {
            state.actions.comparison.parameters.compareEnd = action.payload;
        },
        setCompareEndBeforeStart: (state, action) => {
            state.actions.comparison.parameters.compareEndBeforeStart = action.payload;
        },
        setDisableComparison: (state, action) => {
            state.actions.comparison.parameters.disable = action.payload;
        },
        resetComparison: (state, action) => {
            state.actions.comparison = initialState.actions.comparison;
        },
        setMtTypeComparison: (state, action) => {
            state.actions.comparison.parameters.mtType = action.payload;
        },
        setSelectedMeasurementsComparison: (state, action) => {
            state.actions.comparison.parameters.measurements = action.payload;
        },
        setComparisonReady: (state, action) => {
            state.actions.comparison.isReady = action.payload;
        },
        // Last Activity part
        setLastActivity: (state, action) => {
            state.lastActivity_equipments = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(equipmentApi.endpoints.getEquipments.matchFulfilled, (state, action) => {
                state.equipments = action.payload;
                state.init_equipments = action.payload;
            })
            .addMatcher(dashboardApi.endpoints.createInstantExport.matchFulfilled, (state, action) => {
                state.actions.instant_export.data = action.payload;
                state.actions.instant_export.error = null;
            })
            .addMatcher(dashboardApi.endpoints.getDetailComparison.matchFulfilled, (state, action) => {
                state.actions.comparison.detail.data = action.payload;
                state.actions.comparison.detail.error = null;
            })
            .addMatcher(dashboardApi.endpoints.getSummaryComparison.matchFulfilled, (state, action) => {
                state.actions.comparison.summary.data = action.payload;
                state.actions.comparison.summary.error = null;
            })
            .addMatcher(dashboardApi.endpoints.getConsoComparison.matchFulfilled, (state, action) => {
                state.actions.comparison.consumption.data = action.payload;
                state.actions.comparison.consumption.error = null;
            })
            .addMatcher(
                isAnyOf(setSearchNameFilter, setSiteFilter, setZoneFilter, setUsageFilter, setTagFilter, setCategoryFilter),
                (state, action) => {
                    const filtered_eqt = _.chain(state.init_equipments)
                        .reduce((res, eqt) => {
                            /* name filter */
                            if (state.filter.searchName === "") {
                                res.push(eqt);
                            } else if (_.includes(eqt.name.toLowerCase(), state.filter.searchName.toLowerCase())) {
                                res.push(eqt);
                            }
                            return res;
                        }, [])
                        .reduce((res, eqt) => {
                            /* site filter */
                            if (_.size(state.filter.siteFilter) === 0) {
                                res.push(eqt);
                            } else if (_.includes(state.filter.siteFilter, eqt.site)) {
                                res.push(eqt);
                            }
                            return res;
                        }, [])
                        .reduce((res, eqt) => {
                            /* zone filter */
                            if (_.size(state.filter.zoneFilter) === 0) {
                                res.push(eqt);
                            } else if (_.includes(state.filter.zoneFilter, eqt.zone)) {
                                res.push(eqt);
                            }
                            return res;
                        }, [])
                        .reduce((res, eqt) => {
                            /* usage filter */
                            if (_.size(state.filter.usageFilter) === 0) {
                                res.push(eqt);
                            } else if (_.includes(state.filter.usageFilter, eqt.usage)) {
                                res.push(eqt);
                            }
                            return res;
                        }, [])
                        .reduce((res, eqt) => {
                            //HARDCODED PULSE EXCLUSION
                            if (_.chain(eqt).get("dataflowspec_set", []).includes(8).value()) {
                                return res;
                            }
                            /* category filter */
                            if (_.size(state.filter.categoryFilter) === 0) {
                                res.push(eqt);
                                return res;
                            }
                            const count_categories = _.chain(eqt)
                                .get("dataflowspec_set")
                                .map((category) => {
                                    if (_.includes(state.filter.categoryFilter, category)) return 1;
                                    return 0;
                                })
                                .sum()
                                .value();
                            if (count_categories) {
                                res.push(eqt);
                            }
                            return res;
                        }, [])
                        .reduce((res, eqt) => {
                            /* Tags filter */
                            if (_.size(state.filter.tagFilter) === 0) {
                                res.push(eqt);
                                return res;
                            }
                            const count_tags = _.chain(eqt)
                                .get("tag_set")
                                .map((tag) => {
                                    if (_.includes(state.filter.tagFilter, tag)) return 1;
                                    return 0;
                                })
                                .sum()
                                .value();
                            if (count_tags) {
                                res.push(eqt);
                            }
                            return res;
                        }, [])
                        .value();

                    state.equipments = filtered_eqt;
                    if (!state.pagination.stickPage) {
                        state.pagination.page = 1;
                    }
                }
            )
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setSearchNameFilter,
    setSiteFilter,
    setZoneFilter,
    setReadyFilter,
    setUsageFilter,
    setTagFilter,
    setSortByCategory,
    setSortByUnit,
    setSortOrder,
    setSortedEquipments,
    setReadySort,
    setCategoryFilter,
    resetFilterWithPage,
    setDashboardPage,
    setDashboardItemsPerPage,
    setDashboardStickPage,
    addSelectedEquipment,
    addAllEquipments,
    removeSelectedEquipment,
    resetSelectedEquipments,
    setStartExport,
    setEndExport,
    setDisableExport,
    setEndBeforeStartExport,
    setExportType,
    setExportInstant,
    setExportFormat,
    setMtTypes,
    resetInstantExport,
    changeNumOfDays,
    setRefStartComparison,
    setRefEndComparison,
    setCompareStartComparison,
    setCompareEndComparison,
    setSelectedMeasurementsComparison,
    setDisableComparison,
    resetComparison,
    setMtTypeComparison,
    setCompareEndBeforeStart,
    setLastActivity,
    setComparisonReady
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
