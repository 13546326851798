import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Segment, Grid, Header, Button, Input, Icon, Dropdown } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { frequencyOptions } from "modules/export/utils";
import { resetFilterWithPage, setFrequencyFilter, setItemsPerPage, setPage, setSearchNameFilter } from "modules/report/reportSlice";
import { djangoAdmin } from "apis/PowerAdapt";

import { useGetReportsQuery } from "../reportService";
import history_app from "history_app";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import ReportDelete from "./ReportDelete";
import Generate from "./Generate";
import MessageDisplay from "modules/common/components/MessageDisplay";

const Reports = () => {
    const dispatch = useDispatch();
    const { report, org, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const reports = useGetReportsQuery({ org: org.current }, { skip: !org.current });

    const renderIsActive = (item) => {
        return item.is_active === true ? <Icon name="checkmark" color="green" /> : <Icon name="delete" color="red" />;
    };

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "is_active", label: i18n._(t`is_active`), textAlign: "left", collapsing: true },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "frequency", label: i18n._(t`frequency`), textAlign: "left" },
        { id: "owner_email", label: i18n._(t`owner`), textAlign: "left" },
        { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const reports_list = _.chain(reports.data)
        .reduce((res, item) => {
            if (report.filter.searchName === "") {
                res.push(item);
            } else if (_.includes(item.name.toLowerCase(), report.filter.searchName.toLowerCase())) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            if (_.size(report.filter.frequencyFilter) === 0) {
                res.push(item);
            } else if (_.includes(report.filter.frequencyFilter, item.frequency)) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const report_freq = _.chain(frequencyOptions).find(["value", item.frequency]).get("text").value();
            const is_owner = auth.user.email === item?.owner_email;

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                is_active: {
                    render: <span style={customStyle}>{renderIsActive(item)}</span>,
                    value: +_.get(item, "is_active", null),
                    textAlign: "left",
                    datatype: "number"
                },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "right",
                    datatype: "string"
                },
                owner_email: {
                    render: <span style={customStyle}>{_.get(item, "owner_email", "-")}</span>,
                    value: _.get(item, "owner_email", "-"),
                    textAlign: "right",
                    datatype: "string"
                },
                frequency: {
                    render: <span style={customStyle}>{report_freq ? i18n._(report_freq) : "-"}</span>,
                    value: report_freq ? i18n._(report_freq) : null,
                    textAlign: "right",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Button.Group size="small">
                                    <Generate report={item} org={org.current} />
                                    <Button
                                        icon={is_owner && notification.srv_status.db_status === "rw" ? "edit" : "eye"}
                                        onClick={async (e) => {
                                            history_app.push(`/reports/${item.id}`);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    />
                                    {notification.srv_status.db_status === "rw" && is_owner && (
                                        <ReportDelete org={org.current} report={item} setPage={setPage} />
                                    )}
                                </Button.Group>
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>reports management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {reports.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {reports.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {reports.isSuccess && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <Grid celled>
                                <Grid.Column mobile={16} tablet={3} computer={3}>
                                    <Input
                                        fluid
                                        icon="search"
                                        placeholder={i18n._(t`search identifier`)}
                                        onChange={(e, { value }) => {
                                            dispatch(setSearchNameFilter(value));
                                        }}
                                        value={report.filter.searchName}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={3} computer={3}>
                                    <Dropdown
                                        fluid
                                        options={_.map(frequencyOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                        placeholder={i18n._(t`select frequency`)}
                                        multiple
                                        selection
                                        onChange={(e, { value }) => {
                                            dispatch(setFrequencyFilter(value));
                                        }}
                                        value={report.filter.frequencyFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    {notification.srv_status.db_status === "rw" && (
                                        <Button
                                            icon
                                            labelPosition="left"
                                            onClick={async (e) => {
                                                await dispatch(resetFilterWithPage());
                                                history_app.push(`reports/add`);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Icon name="add" />
                                            <Trans>add report</Trans>
                                        </Button>
                                    )}
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            window.location = `${djangoAdmin(
                                                current_lng
                                            )}pwa_clients/jobactivity/?content_type=advancedreport`;
                                        }}
                                    >
                                        <Icon name="bullhorn" />
                                        <Trans>Tasks activity</Trans>
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <TableEnhanced
                                        headCells={headCells}
                                        rows={reports_list}
                                        textItemPerPages={i18n._(t`items per page`)}
                                        order="asc"
                                        orderBy="name"
                                        page={report.pagination.page}
                                        rowsPerPage={report.pagination.itemsPerPage}
                                        setPage={(page) => {
                                            dispatch(setPage(page));
                                        }}
                                        setItemsPerPage={(items) => {
                                            dispatch(setItemsPerPage(items));
                                        }}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(Reports);
