import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import { Icon, Label, Button, Grid } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";

export const DigitalInput = (props) => {
    const { measure, lastValues } = props;
    const current_lng = useSelector((state) => state.i18n.current);

    if (lastValues.status === "loading") {
        return (
            <Grid.Column width={16}>
                <MessageDisplay
                    message={i18n._(t`loading`)}
                    level="info"
                    iconName="circle notched"
                    isLoading={true}
                />
            </Grid.Column>
        );
    }

    const last_measurement = _.chain(lastValues).get("data.dataflows")
        .find({ id: measure.dataflow })
        .get('measurements')
        .find({ id: measure.id })
        .value();

    if (lastValues.status === "failed" || _.isUndefined(last_measurement)) {
        return (
            <Grid.Column width={16}>
                <MessageDisplay
                    message={i18n._(t`unable to get last value`)}
                    level="warning"
                    iconName="warning circle"
                    isLoading={false}
                />
            </Grid.Column>
        );
    }

    const intensive = _.get(last_measurement, "last_intensive");
    const date = _.get(last_measurement, "last_state_change");

    return (
        <>
            <Grid.Column mobile={16} tablet={8} computer={4}>
                <Grid centered verticalAlign='middle'>
                    <Grid.Column width={16} className="unpadded" textAlign='center'>
                        <Button as="div" labelPosition="right">
                            <Button color={intensive === 1 ? "green" : "red"}>
                                <Icon name="bolt" />
                                <Trans>state</Trans>
                            </Button>
                            <Label basic pointing="left" color={intensive === 1 ? "green" : "red"}>
                                {intensive === 1 ? i18n._(t`up`) : i18n._(t`down`)}
                            </Label>
                        </Button>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            {date !== undefined && moment(date).isValid() && (
                <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Grid centered stretched verticalAlign='middle'>
                        <Grid.Column width={16} textAlign='center'>
                            <span>
                                <Trans>
                                    your device has been{" "}
                                    <strong>{intensive === 1 ? i18n._(t`up`) : i18n._(t`down`)}</strong> since
                                </Trans>:<br />
                                <strong>{` ${moment(date).locale(current_lng).format("LLL")}`}</strong>
                            </span>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            )}
        </>
    );
};

DigitalInput.propTypes = {
    measure: PropTypes.object.isRequired
};

export default DigitalInput;
