import _ from "lodash";
import { t } from "@lingui/macro";
import { processAnalysisSectionData } from "modules/analysisAdvanced/processing";
import { comparison_options, historic_options, process_analysis_period } from "modules/analysisAdvanced/utils";
import tinycolor from "tinycolor2";
import Palette from "modules/common/components/graphic/Colors";

export const WidgetType = {
    STACK_BAR_RATIO: [1001, t`Ratio - Stack bar chart`],
    GAUGE_RATIO: [3001, t`Ratio - Gauge`],
    STACK_BAR_CONSUMPTION_BREAKDOWN: [1002, t`Consumptions - Stack bar chart`],
    PIE_CHART_CONSUMPTION_BREAKDOWN: [2001, t`Consumptions - Pie Chart`],
    GAUGE_CONSUMPTION_BREAKDOWN: [3002, t`Consumptions - Gauge`],
    GAUGE_CONSUMPTION: [3003, t`Single measurement - Gauge`],
    SPARKLINE_CONSUMPTION: [4001, t`Comparison - Sparkline chart`],
    SPARKLINE_CONSUMPTIONS: [4002, t`Multi measurement - Sparkline chart`]
};

export const WidgetGaugeType = {
    RECTILINEAR: [0, t`Linear gauge`],
    DIAL: [1, t`Dial gauge`],
    TEXT: [2, t`Text gauge`]
};

export const operator_options = [
    { key: 0, value: 0, text: t`Last value` },
    { key: 1, value: 1, text: t`Average` },
    { key: 2, value: 2, text: t`Maximum` },
    { key: 3, value: 3, text: t`Minimum` }
];

export const calendarTypeOptions = [
    { key: 0, text: t`Opening`, value: 0 },
    { key: 1, text: t`Closing`, value: 1 },
    { key: 2, text: t`Opening & Closing`, value: 2 }
];

//Used for semantic
export const WidgetTypeOptions = _.map(WidgetType, (value, key) => {
    return { key: value[0], value: value[0], text: value[1] };
});

export const WidgetGaugeOptions = _.map(WidgetGaugeType, (value, key) => {
    return { key: value[0], value: value[0], text: value[1] };
});

export const processWidgetData = (widget, response, meta, end) => {
    switch (widget?.widget_type) {
        case WidgetType.STACK_BAR_RATIO[0]:
        case WidgetType.PIE_CHART_CONSUMPTION_BREAKDOWN[0]:
        case WidgetType.STACK_BAR_CONSUMPTION_BREAKDOWN[0]:
        case WidgetType.SPARKLINE_CONSUMPTION[0]:
        case WidgetType.SPARKLINE_CONSUMPTIONS[0]:
            const historic = widget?.historic ?? historic_options[1].value;
            const comparison = widget?.comparison ?? comparison_options[0].value;
            const time_periods = process_analysis_period(end.end, comparison, historic);
            const { sites, zones, usages, tags, categories } = meta;
            let process_data = processAnalysisSectionData(
                _.pick(response, ["data", "unit"]),
                response.comparison_data ? { data: response.comparison_data, unit: response.unit } : null,
                widget,
                { sites, zones, usages, tags, categories }
            );
            if (widget.widget_type === WidgetType.SPARKLINE_CONSUMPTION[0]) {
                if (time_periods.comp_time) {
                    //Rename series for single measurement case with comparison
                    const ref_color = tinycolor(Palette.named.red).setAlpha(0.6).toString();
                    const comp_color = tinycolor(Palette.named.blue).setAlpha(0.6).toString();
                    process_data = {
                        ...process_data,
                        graphic: {
                            ...process_data.graphic,
                            series: {
                                ...process_data.graphic.series,
                                ref_series: _.map(process_data.graphic.series.ref_series, (item) => {
                                    return {
                                        ...item,
                                        title: t`Comparison period`,
                                        color: comp_color,
                                        data: _.map(item.data, (record) => {
                                            return {
                                                ...record,
                                                color: comp_color
                                            };
                                        })
                                    };
                                }),
                                comp_series: _.map(process_data.graphic.series.comp_series, (item) => {
                                    return {
                                        ...item,
                                        title: t`Reference period`,
                                        color: ref_color,
                                        data: _.map(item.data, (record) => {
                                            return {
                                                ...record,
                                                color: ref_color
                                            };
                                        })
                                    };
                                })
                            }
                        }
                    };
                }
            }
            return { ...process_data, time_periods };
        case WidgetType.GAUGE_RATIO[0]:
        case WidgetType.GAUGE_CONSUMPTION_BREAKDOWN[0]:
        case WidgetType.GAUGE_CONSUMPTION[0]:
            switch (widget.gauge_choices) {
                case WidgetGaugeType.RECTILINEAR[0]:
                    //data for rectilinear

                    const thresholds = [
                        {
                            value: widget?.low_limit ?? 0,
                            color: "green"
                        },
                        {
                            value: widget?.low_intermediate_limit ?? 30,
                            color: "orange"
                        },
                        {
                            value: widget?.high_intermediate_limit ?? 60,
                            color: "red"
                        }
                    ];

                    return {
                        thresholds,
                        value: _.isFinite(response.data) ? Math.round((response.data + Number.EPSILON) * 100) / 100 : response.data,
                        minValue: widget?.low_limit ?? 0,
                        maxValue: widget?.high_limit ?? 100,
                        unit: response.unit
                    };
                case WidgetGaugeType.DIAL[0]:
                    let className = "";
                    if (!_.isFinite(response.data)) {
                        className = "pwaWidgetGaugeDialRed";
                    } else if (response.data >= widget?.high_intermediate_limit) {
                        className = "pwaWidgetGaugeDialRed";
                    } else if (response.data >= widget?.low_intermediate_limit) {
                        className = "pwaWidgetGaugeDialOrange";
                    } else if (response.data >= widget?.low_limit) {
                        className = "pwaWidgetGaugeDialGreen";
                    }

                    const raw_value = _.isFinite(response.data) ? Math.round((response.data + Number.EPSILON) * 100) / 100 : null;

                    return {
                        raw_value,
                        value: _.clamp(raw_value, widget?.low_limit, widget?.high_limit),
                        unit: response.unit,
                        minValue: widget.low_limit,
                        maxValue: widget.high_limit,
                        customSegmentStops: [
                            widget?.low_limit ?? 0,
                            widget?.low_intermediate_limit ?? 30,
                            widget?.high_intermediate_limit ?? 60,
                            widget?.high_limit ?? 100
                        ],
                        segmentColors: [
                            //Only 3 level based on widget level
                            "green",
                            "orange",
                            "red"
                        ],
                        className
                    };
                case WidgetGaugeType.TEXT[0]:
                    let color = "green";
                    if (!_.isFinite(response.data)) {
                        color = "red";
                    } else if (response.data >= widget?.high_limit) {
                        color = "red";
                    } else if (response.data >= widget?.high_intermediate_limit) {
                        color = "orange";
                    }

                    return {
                        unit: response.unit,
                        value: _.isFinite(response.data) ? Math.round((response.data + Number.EPSILON) * 100) / 100 : null,
                        color
                    };
                default:
                    return null;
            }
        default:
            break;
    }

    return response;
};

//for widgets
export const historic_options_widget = [
    { key: 0, value: 0, text: t`Previous day` },
    { key: 8, value: 8, text: t`Since midnight` },
    { key: 1, value: 1, text: t`Previous 7 days` },
    { key: 2, value: 2, text: t`Previous 30 days` },
    { key: 3, value: 3, text: t`Previous week` },
    { key: 4, value: 4, text: t`Previous 4 weeks` },
    { key: 5, value: 5, text: t`Previous month` },
    { key: 6, value: 6, text: t`Previous 3 months` },
    { key: 7, value: 7, text: t`Previous 12 months` }
];

//Only for Gauge form
//Change w && h based on minW && minH
export const updateWidthHeigthFromMin = (formData) => {
    switch (formData.gauge_choices) {
        case WidgetGaugeType.RECTILINEAR[0]:
            return {
                ...formData,
                w: Math.max(formData.w, 2),
                h: Math.max(formData.h, 3)
            };
        case WidgetGaugeType.DIAL[0]:
            return {
                ...formData,
                w: Math.max(formData.w, 2),
                h: Math.max(formData.h, 4)
            };
        case WidgetGaugeType.TEXT[0]:
            return {
                ...formData,
                w: Math.max(formData.w, 1),
                h: Math.max(formData.h, 3)
            };
        default:
            return formData;
    }
};

export const MtTypesExclusions = [
    "battery",
    "battery_status",
    "cycles",
    "di",
    "debit_nm3",
    "debit_tv",
    "analog",
    "operating_hours",
    "p_act",
    "p_react",
    "p_react+",
    "p_react-",
    "trip",
    "voltage_detected"
];

export const keyToReparseInDnD = ["denominator_factor", "numerator_factor", "reference_level", "threshold_min", "threshold_max"];
