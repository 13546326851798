import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';
import cookie from "react-cookies";
import moment from "moment";

export const exportApi = defaultApi.injectEndpoints({
    reducerPath: 'apiExport',
    endpoints: (build) => ({
        getExports: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `exports?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Exports"],
        }),
        getExport: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return `exports/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: ['Export'],
            transformResponse: (response) => {
                return {
                    ...response,
                    hours: `${response.hour}:${response.minute}`,
                    mailinglist: _.map(response.mailinglist, (item) => item.id),
                    measurements: _.map(response.measurements, (item) => item.id)
                };
            }
        }),
        createExport: build.mutation({
            query: ({ org, data, user }) => {
                const current_org = _.get(org, 'name', null);
                const timer = moment(data.hours, ["h:m a", "H:m"]);
                const version = 2; //Need to add version number for database usage
                return {
                    url: `exports?org=${current_org}`,
                    method: 'POST',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: {
                        ...data,
                        user_id: user.user_id,
                        hour: timer.hour(),
                        minute: timer.minute(),
                        version
                    }
                };
            },
            transformResponse: (response) => {
                return {
                    ...response,
                    hours: `${response.hour}:${response.minute}`,
                    mailinglist: _.map(response.mailinglist, (item) => item.id),
                    measurements: _.map(response.measurements, (item) => item.id)
                };
            },
            invalidatesTags: ['Exports', "Export"]
        }),
        updateExport: build.mutation({
            query: ({ org, data, user }) => {
                const current_org = _.get(org, 'name', null);
                const timer = moment(data.hours, ["h:m a", "H:m"]);
                return {
                    url: `exports/${data.id}?org=${current_org}`,
                    method: 'PUT',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: {
                        ...data,
                        user_id: user.user_id,
                        hour: timer.hour(),
                        minute: timer.minute()
                    }
                };
            },
            transformResponse: (response) => {
                return {
                    ...response,
                    hours: `${response.hour}:${response.minute}`,
                    mailinglist: _.map(response.mailinglist, (item) => item.id),
                    measurements: _.map(response.measurements, (item) => item.id)
                };
            },
            invalidatesTags: ["Exports", "Export"]
        }),
        deleteExport: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `exports/${data}?org=${current_org}`,
                    method: 'DELETE',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                };
            },
        }),
        generateExport: build.mutation({
            query: ({ org, export_id, end }) => {
                const current_org = _.get(org, 'name', "");
                return {
                    url: `exports/${export_id}/generate`,
                    method: 'GET',
                    params: {
                        org: current_org,
                        end,
                    }
                };
            },

        })
    }),
    overrideExisting: false,
});

export const {
    useGetExportsQuery, useGetExportQuery,
    useCreateExportMutation, useUpdateExportMutation, useDeleteExportMutation,
    useGenerateExportMutation
} = exportApi;