import PowerAdaptAPI, { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";
import moment from "moment";
import { getTimedelta } from "modules/time/utils";

export const dashboardApi = defaultApi.injectEndpoints({
    reducerPath: "apiDashboard",
    endpoints: (build) => ({
        getLastActivity: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/equipments/last_activity_sensors?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    body: {
                        equipments: _.isEmpty(data) ? "" : data
                    }
                };
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "LastActivity", id: arg.org.id }];
                return [];
            }
        }),
        createInstantExport: build.mutation({
            queryFn: async ({ org, current_lng, instant_export, measurements, dataflows, equipments }) => {
                const m_list = _.chain(measurements).reduce((res, measure) => {
                    const mtTypeId = _.get(measure, "measurementtype.id");
                    const dataflow = _.find(dataflows, { id: measure.dataflow });

                    if (dataflow) {
                        const eqt = _.find(equipments, { id: dataflow.equipment });
                        if (eqt) {
                            if (_.includes(instant_export.parameters.mtTypes, mtTypeId)) {
                                res.push(_.get(measure, "id", null));
                            }
                        }
                    }
                    return res;
                }, []);

                const params = {
                    start: moment(_.get(instant_export, "parameters.start.start", "")).format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(_.get(instant_export, "parameters.end.end", "")).format("YYYY-MM-DD HH:mm:ss"),
                    export_type: _.get(instant_export, "parameters.exportType", 1),
                    format: _.get(instant_export, "parameters.exportFormat", 1),
                    m_list,
                    instant: _.get(instant_export, "parameters.exportInstant", false)
                };

                const response = await PowerAdaptAPI.post(`/instantexport?org=${org}&${refreshTimestampUrlParam()}`, params, {
                    responseType: "blob",
                    timeout: 1000 * 60 * 5,
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                });

                if (response.data !== undefined) {
                    // Passing url reference of blob instead of blob directly because blob object is not serializable for redux-toolkit
                    const url = window.URL.createObjectURL(response.data);
                    return { data: { data: url, params: _.pick(params, ["format"]) } }; //only use format in 'params'
                } else {
                    return { error: response.error };
                }
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "InstantExport", id: arg.org.id }];
                }
                return [];
            },
            keepUnusedDataFor: 600
        }),
        getDetailComparison: build.mutation({
            queryFn: async ({ org, selected_equipments, comparison }) => {
                if (_.isEmpty(selected_equipments)) return { error: "No equipment selected" }; // Needed to avoid error in the API call
                if (_.size(selected_equipments) === 1) {
                    try {
                        const measure = _.chain(comparison).get("parameters.measurements[0]").value();
                        const refReq = `/measurements/${measure.id}/detail?org=${org}&start=${comparison.parameters.refStart.start}&end=${
                            comparison.parameters.refEnd.end
                        }&${refreshTimestampUrlParam()}`;
                        const refResponse = await PowerAdaptAPI.get(refReq);

                        const compareReq = `/measurements/${measure.id}/detail?org=${org}&start=${comparison.parameters.compareStart.start}&end=${
                            comparison.parameters.compareEnd.end
                        }&${refreshTimestampUrlParam()}`;
                        const compareResponse = await PowerAdaptAPI.get(compareReq);

                        return {
                            data: [
                                { measure, data: refResponse.data },
                                { measure, data: compareResponse.data }
                            ]
                        };
                    } catch (error) {
                        return { error };
                    }
                } else if (_.size(selected_equipments) > 1) {
                    try {
                        const measures = _.chain(comparison).get("parameters.measurements", []).value();
                        const response_measurements = await Promise.all(
                            _.map(measures, async (measure) => {
                                try {
                                    const response = await PowerAdaptAPI.get(
                                        `/measurements/${measure.id}/detail?org=${org}&start=${comparison.parameters.compareStart.start}&end=${
                                            comparison.parameters.compareEnd.end
                                        }&${refreshTimestampUrlParam()}`
                                    );
                                    return { data: response.data, measure };
                                } catch (error) {
                                    return { error };
                                }
                            })
                        );
                        return { data: response_measurements };
                    } catch (error) {
                        return { error };
                    }
                }
                return null;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ComparisonWidgets", id: arg.org.id }];
                return [];
            }
        }),
        getSummaryComparison: build.mutation({
            queryFn: async ({ org, selected_equipments, comparison }) => {
                if (_.isEmpty(selected_equipments)) return { error: "No equipment selected" }; // Needed to avoid error in the API call
                if (_.size(selected_equipments) === 1) {
                    try {
                        const measure = _.chain(comparison).get("parameters.measurements[0]").value();
                        const ref_new_range_time = getTimedelta(comparison.parameters.refStart.start, comparison.parameters.refEnd.end);
                        const refReq = `/measurements/${measure.id}/summary?org=${org}&start=${ref_new_range_time.start.format(
                            "YYYY-MM-DD"
                        )}&end=${ref_new_range_time.end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const refResponse = await PowerAdaptAPI.get(refReq);

                        const compare_new_range_time = getTimedelta(comparison.parameters.compareStart.start, comparison.parameters.compareEnd.end);
                        const compareReq = `/measurements/${measure.id}/summary?org=${org}&start=${compare_new_range_time.start.format(
                            "YYYY-MM-DD"
                        )}&end=${compare_new_range_time.end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const compareResponse = await PowerAdaptAPI.get(compareReq);

                        return {
                            data: [
                                { measure, data: refResponse.data },
                                { measure, data: compareResponse.data }
                            ]
                        };
                    } catch (error) {
                        return { error };
                    }
                } else if (_.size(selected_equipments) > 1) {
                    try {
                        const measures = _.chain(comparison).get("parameters.measurements", []).value();
                        const response_measurements = await Promise.all(
                            _.map(measures, async (measure) => {
                                try {
                                    const new_range_time = getTimedelta(
                                        comparison.parameters.compareStart.start,
                                        comparison.parameters.compareEnd.end
                                    );
                                    const response = await PowerAdaptAPI.get(
                                        `/measurements/${measure.id}/summary?org=${org}&start=${new_range_time.start.format(
                                            "YYYY-MM-DD"
                                        )}&end=${new_range_time.end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`
                                    );
                                    return { measure, data: response.data };
                                } catch (error) {
                                    return { error };
                                }
                            })
                        );
                        return { data: response_measurements };
                    } catch (error) {
                        return { error };
                    }
                }
                return null;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ComparisonWidgets", id: arg.org.id }];
                return [];
            }
        }),
        getConsoComparison: build.mutation({
            queryFn: async ({ org, selected_equipments, comparison }) => {
                if (_.isEmpty(selected_equipments)) return { error: "No equipment selected" }; // Needed to avoid error in the API call
                if (_.size(selected_equipments) === 1) {
                    try {
                        const measure = _.chain(comparison).get("parameters.measurements[0]").value();
                        const ref_new_range_time = getTimedelta(comparison.parameters.refStart.start, comparison.parameters.refEnd.end);
                        const refReq = `/measurements/${measure.id}/consumptions?org=${org}&start=${ref_new_range_time.start.format(
                            "YYYY-MM-DD"
                        )}&end=${ref_new_range_time.end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const refResponse = await PowerAdaptAPI.get(refReq);

                        const compare_new_range_time = getTimedelta(comparison.parameters.compareStart.start, comparison.parameters.compareEnd.end);
                        const compareReq = `/measurements/${measure.id}/consumptions?org=${org}&start=${compare_new_range_time.start.format(
                            "YYYY-MM-DD"
                        )}&end=${compare_new_range_time.end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const compareResponse = await PowerAdaptAPI.get(compareReq);

                        return {
                            data: [
                                { measure, data: refResponse.data },
                                { measure, data: compareResponse.data }
                            ]
                        };
                    } catch (error) {
                        return { error };
                    }
                } else if (_.size(selected_equipments) > 1) {
                    try {
                        const measures = _.chain(comparison).get("parameters.measurements", []).value();
                        const response_measurements = await Promise.all(
                            _.map(measures, async (measure) => {
                                try {
                                    const new_range_time = getTimedelta(
                                        comparison.parameters.compareStart.start,
                                        comparison.parameters.compareEnd.end
                                    );
                                    const response = await PowerAdaptAPI.get(
                                        `/measurements/${measure.id}/consumptions?org=${org}&start=${new_range_time.start.format(
                                            "YYYY-MM-DD"
                                        )}&end=${new_range_time.end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`
                                    );
                                    return { measure, data: response.data };
                                } catch (error) {
                                    return { error };
                                }
                            })
                        );
                        return { data: response_measurements };
                    } catch (error) {
                        return { error };
                    }
                }
                return null;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ComparisonWidgets", id: arg.org.id }];
                return [];
            }
        }),
        getSortedEquipmentsIds: build.query({
            query: ({ org, category, unit, isAsc, sites }) => {
                const current_org = _.get(org, "name", null);
                let query = `category=${category}&unit=${unit}&asc=${isAsc}&org=${current_org}&${refreshTimestampUrlParam()}`;
                if (!_.isEmpty(sites)) {
                    query = `sites=${sites}&${query}`;
                }

                return `equipmentsorting?${query}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "SortedDashboards", id: arg.org.id }];
                return [];
            }
        })
    })
});

export const {
    useGetLastActivityQuery,
    useCreateInstantExportMutation,
    useGetDetailComparisonMutation,
    useGetSummaryComparisonMutation,
    useGetConsoComparisonMutation,
    useGetSortedEquipmentsIdsQuery
} = dashboardApi;
