import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import axios from "axios";
import { Grid } from "semantic-ui-react";
import Alerts from "./widgets/predict/Alerts";
import Load from "./widgets/predict/Load";
import Trend from "./widgets/predict/Trend";
import Val24h from "./widgets/Val24h";

import PowerAdapt, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import LastVal from "./widgets/LastVal";
import Val7Day from "./widgets/Val7Day";
import UsagePercent from "./widgets/UsagePercent";

export const PredictPanel = (props) => {
    const isMounted = useRef(false);
    const { equipment, lastValues } = props;
    const { org, machine } = useSelector((state) => state);
    const [overview24h, setOverview24h] = useState({
        status: "idle",
        error: null,
        data: { measurements: [], machine: null }
    });
    const current_org = _.get(org, "current.name", null);
    const eqt_machine = _.find(machine.machines, { id: equipment.machine });

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const getOverview24h = async (equipment, current_org) => {
        try {
            isMounted.current && (await setOverview24h({ status: "loading", error: null, data: [] }));
            const response_measurements =
                isMounted.current &&
                (await Promise.all(
                    _.map(equipment.measurements, async (measure) => {
                        try {
                            const response = await PowerAdapt.get(
                                `/measurements/${measure.id}/overview24h?org=${current_org}&${refreshTimestampUrlParam()}`
                            );
                            return { ...response.data, measure: measure.id };
                        } catch (error) {
                            return null;
                        }
                    })
                ));
            if (equipment.machine && _.get(eqt_machine, "is_predict") === true) {
                const response_machine = await PowerAdapt.get(
                    `/machines/${equipment.machine}/overview24h?org=${current_org}&${refreshTimestampUrlParam()}`
                );
                isMounted.current &&
                    (await setOverview24h({
                        status: "succeeded",
                        error: null,
                        data: { measurements: _.filter(response_measurements, _.isObject), machine: response_machine.data }
                    }));
            } else {
                isMounted.current &&
                    (await setOverview24h({
                        status: "succeeded",
                        error: null,
                        data: { measurements: _.filter(response_measurements, _.isObject), machine: null }
                    }));
            }
        } catch (error) {
            isMounted.current &&
                (await setOverview24h({
                    status: "failed",
                    error: axios.isAxiosError(error) ? error.message : "An unexpected error occurred",
                    data: []
                }));
        }
    };

    useEffect(() => {
        getOverview24h(equipment, current_org);
        const timer = setInterval(() => {
            getOverview24h(equipment, current_org);
        }, 60 * 10 * 1000);
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Try to get p_act_import measurement (Elec+Predict ?)
    const power_measurement = _.chain(equipment)
        .get("measurements")
        .find((measure) => {
            if (_.get(measure, "measurementtype.name") === "p_act_import") {
                return true;
            }
            return false;
        })
        .value();

    //Default predict case : Try to get 'current' measurement (here from predict ?)
    const current_measurement = _.chain(equipment)
        .get("measurements")
        .find((measure) => {
            if (_.get(measure, "measurementtype.name") === "current") {
                return true;
            }
            return false;
        })
        .value();

    if (_.isUndefined(power_measurement) && !_.isUndefined(current_measurement)) {
        /* No power data => display full predict */
        return (
            <>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Load equipment={equipment} lastValues={lastValues} machine={eqt_machine} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Val24h measure={current_measurement} overview24h={overview24h} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Trend overview24h={overview24h} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Alerts lastValues={lastValues} machine={_.get(equipment, "machine")} />
                </Grid.Column>
            </>
        );
    }
    if (_.isUndefined(current_measurement) && !_.isUndefined(power_measurement)) {
        /* No current data => display full power data */
        return (
            <>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <LastVal equipment={equipment} measure={power_measurement} lastValues={lastValues} overview24h={overview24h} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Val24h measure={power_measurement} overview24h={overview24h} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Val7Day measure={power_measurement} overview24h={overview24h} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <UsagePercent measure={power_measurement} overview24h={overview24h} />
                </Grid.Column>
            </>
        );
    }
    /* Mixing predict + power */
    return (
        <>
            <Grid.Column mobile={16} tablet={8} computer={4}>
                {/* Display p_act_import if exist else 'current' */}
                <LastVal equipment={equipment} measure={power_measurement} lastValues={lastValues} overview24h={overview24h} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
                <Val24h measure={power_measurement} overview24h={overview24h} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
                <Trend overview24h={overview24h} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
                <Alerts lastValues={lastValues} machine={_.get(equipment, "machine")} />
            </Grid.Column>
        </>
    );
};

export default PredictPanel;
