import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { t } from "@lingui/macro";
import moment from "moment";
import { Crosshair, DiscreteColorLegend, FlexibleXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis } from "react-vis";
import { Card, Grid, Segment } from "semantic-ui-react";

import { useGetConsoComparisonMutation } from "modules/dashboard/dashboardService";
import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { getTimedelta } from "modules/time/utils";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { checkMobileAndTablet } from "modules/common/utils";
import tinycolor from "tinycolor2";

const ConsumptionGraphic = (props) => {
    const { dashboard, org, measurementtype } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const [checkDataPointType, setCheckDataPointType] = useState(false);
    const [series, setSeries] = useState([]);
    const [hintValue, setHintValue] = useState(null);
    const [crosshairValues, setCrosshairValues] = useState([]);

    const mtType = _.find(measurementtype.measurementtypes, { id: dashboard.actions.comparison.parameters.mtType });

    useEffect(() => {
        if (_.get(mtType, "datapoint_type") === 3) {
            setCheckDataPointType(true);
        }
    }, [checkDataPointType, mtType]);

    const hasMultipleEquipments = _.size(dashboard.selected_equipments) > 1;

    const [getConso, conso] = useGetConsoComparisonMutation();

    useEffect(() => {
        if (conso.isSuccess) {
            setSeries(conso.data);
        }
    }, [conso]);

    useEffect(() => {
        if (_.size(dashboard.selected_equipments) > 0) {
            const params = {
                org: org.current.name,
                selected_equipments: dashboard.selected_equipments,
                comparison: dashboard.actions.comparison
            };
            getConso(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard.selected_equipments]);

    const emptyData = _.chain(series)
        .map((serie) =>
            _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty(serie.data)
                .value()
        )
        .every()
        .value();

    if (emptyData && conso.isSuccess) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    //process getTimeDelta from comparison parameters to get correct range for xAxis domain
    const compare_new_range_time = getTimedelta(
        dashboard.actions.comparison.parameters.compareStart.start,
        dashboard.actions.comparison.parameters.compareEnd.end
    );

    const xDomain = hasMultipleEquipments
        ? [compare_new_range_time.start, compare_new_range_time.end]
        : [0, _.chain(series).head().get("data", []).size().value()];

    /*
    const maxY = _.chain(series).filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => { res.push(item); });
            return res;
        }, []).maxBy('y').get('y').value();

    const minY = _.chain(series).filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => { res.push(item); });
            return res;
        }, []).minBy('y').get('y').value();
    */

    const clickHandler = (serie_clk, idx) => {
        const size_disable = _.chain(series)
            .filter((item) => item.disabled === false)
            .size()
            .value();
        const update_series = _.map(series, (item, idx_ser) => {
            if (idx === idx_ser) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
            return item;
        });
        setSeries(update_series);
    };

    const nearestXHandler = (value, { index }) => {
        const crossval = _.chain(series)
            .filter((item) => item.disabled === false)
            .reduce((res, serie) => {
                const data = _.chain(serie.data).find({ x: value.x }).value();
                if (data) {
                    res.push({ title: _.get(serie, "title"), ...data });
                }
                return res;
            }, [])
            .value();
        setCrosshairValues(crossval);
    };

    const tooltipCrosshair = () => (
        <Crosshair values={crosshairValues}>
            <Card>
                <Card.Content>
                    <Card.Header textAlign="center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {hasMultipleEquipments && moment(crosshairValues[0].t).locale(current_lng).format("LL")}
                        {!hasMultipleEquipments && _.chain(series).head().get("name", "-").value()}
                    </Card.Header>
                </Card.Content>
                <Card.Content>
                    <Grid centered verticalAlign="middle">
                        {_.chain(crosshairValues)
                            .map((item, idx) => {
                                return (
                                    <Grid.Row key={idx} style={{ color: tinycolor(item.color).setAlpha(1).toString() }}>
                                        {!hasMultipleEquipments ? (
                                            <Grid.Column width={10} textAlign="left">
                                                {moment(item.t).locale(current_lng).format("LL")}
                                            </Grid.Column>
                                        ) : (
                                            <Grid.Column
                                                width={10}
                                                textAlign="left"
                                                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                            >
                                                {item.title}
                                            </Grid.Column>
                                        )}
                                        <Grid.Column width={6} textAlign="right">
                                            {item.y !== null && `${i18n.number(item.y, { maximumFractionDigits: 2 })} ${item.unit}`}
                                            {item.y === null && `- ${item.unit}`}
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })
                            .value()}
                    </Grid>
                </Card.Content>
            </Card>
        </Crosshair>
    );

    const tooltipHint = () => (
        <Hint value={hintValue}>
            <Card className="pwaCrosshairCard">
                <Card.Content>
                    <Card.Header>{moment(hintValue.t).locale(current_lng).format("LL")}</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Card.Description>
                        <Grid centered verticalAlign="middle" style={{ color: tinycolor(hintValue.color).setAlpha(1).toString() }}>
                            <Grid.Row>
                                <Grid.Column className="pwaTooltipContentComparison" width={16} textAlign="center">
                                    {hintValue.title}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    {hintValue.y !== null && `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${hintValue.unit}`}
                                    {hintValue.y === null && `- ${hintValue.unit}`}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        </Hint>
    );

    return (
        <>
            {conso.isError && <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />}

            {conso.isSuccess ? (
                <Segment attached>
                    <FlexibleXYPlot
                        dontCheckIfEmpty
                        xType={hasMultipleEquipments ? "time" : "linear"}
                        xDomain={xDomain}
                        //yDomain={[Math.min(0, minY), Math.max(10, maxY)]}  Disable yDomain because bad restitution on VerticalBarSeries
                        //stackBy={!hasMultipleEquipments || series.length === 2 ? null : "y"} Disable stack usage because Negative bar overlap other bars
                        onMouseLeave={() => {
                            setCrosshairValues([]);
                        }}
                        height={480}
                        margin={{ left: 60, right: 10, top: 10, bottom: 130 }}
                    >
                        <DiscreteColorLegend
                            className="pwaLegend400"
                            orientation="horizontal"
                            height={75}
                            items={_.chain(series).uniqBy("title").value()}
                            onItemClick={clickHandler}
                        />
                        {hasMultipleEquipments && (
                            <XAxis
                                title={i18n._(t`time`)}
                                tickLabelAngle={-20}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    const default_fmt = multiI18nFormat(value, current_lng);
                                    if (default_fmt.indexOf("06:00") >= 0 || default_fmt.indexOf("12:00") >= 0 || default_fmt.indexOf("18:00") >= 0)
                                        return "";
                                    return default_fmt;
                                }}
                            />
                        )}
                        {!hasMultipleEquipments && (
                            <XAxis
                                title={i18n._(t`number of points`)}
                                tickLabelAngle={-20}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    try {
                                        const firstRecordOnFirstSerie = _.chain(series).head().get("data").head().get("t").value();
                                        const start = moment.min(
                                            moment(firstRecordOnFirstSerie),
                                            moment(dashboard.actions.comparison.parameters.refStart.start)
                                        );
                                        const tmstRecordOnFirstSerie = moment(
                                            _.chain(series).head().get("data").get(parseInt(value)).get("t").value()
                                        );
                                        const days = tmstRecordOnFirstSerie.diff(start, "days");
                                        return `j+${days}`;
                                    } catch (error) {
                                        return "";
                                    }
                                }}
                            />
                        )}
                        <YAxis
                            title={_.chain(series).head().get("unit").value() || "-"}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const format = scale.tickFormat(tickTotal)(value);
                                if (typeof value === "number") {
                                    return i18n.number(value, { minimumFractionDigits: 1, maximumFractionDigits: 2, notation: "compact" });
                                }
                                return format;
                            }}
                        />
                        <HorizontalGridLines />
                        {_.chain(series)
                            .filter((item) => item.disabled === false)
                            .map((serie, idx) => {
                                return (
                                    <VerticalBarSeries
                                        key={idx}
                                        color={serie.color}
                                        fill={serie.color}
                                        size={3}
                                        getNull={(d) => d.y !== null}
                                        data={serie.data}
                                        onNearestX={!checkMobileAndTablet() ? nearestXHandler : null}
                                        onValueMouseOver={
                                            checkMobileAndTablet()
                                                ? (hintValue) => {
                                                      setHintValue(hintValue);
                                                  }
                                                : null
                                        }
                                        onValueMouseOut={
                                            checkMobileAndTablet()
                                                ? () => {
                                                      setHintValue(null);
                                                  }
                                                : null
                                        }
                                    />
                                );
                            })
                            .value()}
                        {!checkMobileAndTablet() && _.size(crosshairValues) !== 0 && tooltipCrosshair()}
                        {checkMobileAndTablet() && !_.isNull(hintValue) && tooltipHint()}
                    </FlexibleXYPlot>
                </Segment>
            ) : (
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            )}
        </>
    );
};

export default React.memo(ConsumptionGraphic);
