import _ from "lodash";
import tinycolor from "tinycolor2";
import moment from "moment";
import { groupByOptions } from "./utils";

import Palette from "modules/common/components/graphic/Colors";

const processGraphic = (ref_data, comp_data, yUnit, no_group = false) => {
    /**
     * Process serie for react-vis graphic
     * @function processSerie
     * @param {object} record
     * @returns
     */
    const processSerie = (index, record, stack_repartition) => {
        const { id, avg, min, max, name, total, values } = record;
        const color = tinycolor(Palette.circles[id % Palette.circles.length || 0])
            .setAlpha(0.6)
            .toString();
        const data = _.map(values, (item, idx) => {
            const total_stack = stack_repartition?.[idx]?.[1] ?? null;
            const repartition = _.defaultTo((item[1] / total_stack) * 100, null);
            return { x: idx, t: moment(item[0]).unix() * 1000, y: item[1], repartition, color };
        });
        const title = name;
        return { id, avg, min, max, title, name, total, data, color, disabled: false, strokeWidth: 20 };
    };

    //Process xTickValues, to process timestamp restitution in graphic
    //Get serie with maximum length of values
    const config_wrap_ref = _.chain(ref_data).maxBy(_.property("values.length")).get("values", []);
    const xTickValues_ref = config_wrap_ref.size().value();
    const xFormatTmst_ref = config_wrap_ref.flatMap(0).value();

    const config_wrap_comp = _.chain(comp_data).maxBy(_.property("values.length")).get("values", []);
    const xTickValues_comp = config_wrap_comp.size().value();

    //Check maximum tickValues length between reference series && comparison serie to display tickValues in Graphic
    const xTickValues = _.isFinite(xTickValues_comp) && xTickValues_comp > xTickValues_ref ? xTickValues_comp : xTickValues_ref;
    const xFormatTmst = xFormatTmst_ref;

    if (no_group) {
        const ref_repartition = ref_data?.[0]?.values ?? [];
        const com_repartition = comp_data?.[0]?.values ?? [];
        //retrieve data directly from measurements
        const ref_series = _.chain(ref_data)
            .get("[0].measurements", [])
            .map((measure, idx) => processSerie(idx, measure, ref_repartition))
            .value();
        const comp_series = comp_data
            ? _.chain(comp_data)
                  .get("[0].measurements", [])
                  .map((measure, idx) => processSerie(idx, measure, com_repartition))
                  .value()
            : null;
        return { series: { ref_series, comp_series }, config: { xTickValues, xFormatTmst, yUnit } };
    } else {
        //process BarStack repartition
        const ref_repartition = _.map(_.groupBy(_.flatMap(ref_data, "values"), 0), (values, key) => {
            const all_data = _.flatMap(values, 1);
            const all_data_null = _.every(all_data, (data) => data === null);
            if (all_data_null) return [key, null];
            return [key, _.sumBy(values, 1)];
        });
        const comp_repartition = comp_data
            ? _.map(_.groupBy(_.flatMap(comp_data, "values"), 0), (values, key) => {
                  const all_data = _.flatMap(values, 1);
                  const all_data_null = _.every(all_data, (data) => data === null);
                  if (all_data_null) return [key, null];
                  return [key, _.sumBy(values, 1)];
              })
            : null;
        //retrieve data from grouping serie (site/zone/usage/...)
        const ref_series = _.chain(ref_data)
            .map((item, idx) => processSerie(idx, item, ref_repartition))
            .value();
        const comp_series = comp_data
            ? _.chain(comp_data)
                  .map((item, idx) => processSerie(idx, item, comp_repartition))
                  .value()
            : null;
        return { series: { ref_series, comp_series }, config: { xTickValues, xFormatTmst, yUnit } };
    }
};

/**
 * Processing to add min/max/avg/total on each series
 * @function processDataGlobal
 * @param {array} measurements - List of measurements
 * @returns
 */
const processDataGlobal = (measurements) => {
    const all_total_null = _.every(measurements, (measure) => measure.total === null);
    //process min/max/avg/total
    const total = all_total_null ? null : _.sumBy(measurements, "total");
    //AVG here is the mean of all measurements.
    const avg = all_total_null ? null : _.chain(measurements).filter("avg").meanBy("avg").value();
    //Processing merge of measurements 'values'
    const values = _.map(_.groupBy(_.flatMap(measurements, "values"), 0), (values, key) => {
        const all_data = _.flatMap(values, 1);
        const all_data_null = _.every(all_data, (data) => data === null);
        if (all_data_null) return [key, null];
        return [key, _.sumBy(values, 1)];
    });
    const max = _.chain(values)
        .maxBy((item) => (_.isFinite(item[1]) ? item[1] : -Infinity))
        .defaultTo(null)
        .value();
    const min = _.chain(values)
        .minBy((item) => (_.isFinite(item[1]) ? item[1] : Infinity))
        .defaultTo(null)
        .value();

    return { total, min, max, avg, values };
};

/**
 * Remap measurements data into list of meta_group
 * @function processGroup
 * @param {array} ref_data_group    - List of measurement from reference data request
 * @param {array} comp_data_group   - List of measurement from comparison data request
 * @param {array} meta_group        - List of data from specific group_by (categories, usages, tags, etc...)
 * @param {string} key              - Key used to group data (cat, usage, site, etc...)
 * @param {boolean} multiple        - Used to change measurement's data inclusion (for example: tags)
 * @returns {object}                - Reference && Comparison transformation
 */
const processGroup = (ref_data_group, comp_data_group, meta_group, key, multiple = false) => {
    const ref = _.map(meta_group, (item) => {
        const measurements = _.reduce(
            ref_data_group,
            (res, measure) => {
                if (!multiple && measure[key] === item.value) {
                    res.push(measure);
                }
                if (multiple && _.includes(measure[key], item.value)) {
                    res.push(measure);
                }
                return res;
            },
            []
        );
        const { total, min, max, values } = processDataGlobal(measurements);
        //override avg process to get avg of group instead of measurements avg
        const avg = _.chain(values)
            .flatMap(1)
            .filter((item) => _.isFinite(item))
            .mean()
            .defaultTo(null)
            .value();

        return { ...item, measurements, total, min, max, avg, values };
    });

    const comp = comp_data_group
        ? _.map(meta_group, (item) => {
              const measurements = _.reduce(
                  comp_data_group,
                  (res, measure) => {
                      if (!multiple && measure[key] === item.value) {
                          res.push(measure);
                      }
                      if (multiple && _.includes(measure[key], item.value)) {
                          res.push(measure);
                      }
                      return res;
                  },
                  []
              );
              const { total, min, max, values } = processDataGlobal(measurements);
              const avg = _.chain(values)
                  .flatMap(1)
                  .filter((item) => _.isFinite(item))
                  .mean()
                  .defaultTo(null)
                  .value();
              return { ...item, measurements, total, min, max, avg, values };
          })
        : null;
    return { ref, comp };
};

/**
 * Processing used to create element for each component used in section (graph, radial chart, ect...)
 * @function processAnalysisSectionData
 * @param {object} data                 - Analysis section data retrieve from server
 * @param {object} analysis_section     - Analysis section used to process data
 * @param {object} meta                 - { sites, zones, usages, tags, categories }
 * @returns
 */
export const processAnalysisSectionData = (ref_data, comp_data, analysis_section, meta) => {
    const { group_by } = analysis_section;
    // default is list of measurements
    let ref_data_group = ref_data?.data ?? [];
    let comp_data_group = comp_data?.data ?? null;
    let process_group = { ref: [], comp: null };
    switch (group_by) {
        case groupByOptions[1].value:
            // by category
            const categories = meta?.categories ?? [];
            process_group = processGroup(ref_data_group, comp_data_group, categories, "cat");
            ref_data_group = process_group.ref;
            comp_data_group = process_group.comp;
            break;
        case groupByOptions[2].value:
            // site
            const sites = meta?.sites ?? [];
            process_group = processGroup(ref_data_group, comp_data_group, sites, "site");
            ref_data_group = process_group.ref;
            comp_data_group = process_group.comp;
            break;
        case groupByOptions[3].value:
            //zone
            const zones = _.map(meta?.zones ?? [], (zone) => {
                //include site's name in zone
                return { ...zone, name: `${zone.name} (${zone.site_name})` };
            });
            process_group = processGroup(ref_data_group, comp_data_group, zones, "zone");
            ref_data_group = process_group.ref;
            comp_data_group = process_group.comp;
            break;
        case groupByOptions[4].value:
            //usages
            const usages = meta?.usages ?? [];
            process_group = processGroup(ref_data_group, comp_data_group, usages, "usage");
            ref_data_group = process_group.ref;
            comp_data_group = process_group.comp;
            break;
        case groupByOptions[5].value:
            //tags
            const tags = meta?.tags ?? [];
            process_group = processGroup(ref_data_group, comp_data_group, tags, "tags", true);
            ref_data_group = process_group.ref;
            comp_data_group = process_group.comp;
            break;
        default:
            //no group
            const { total: ref_global, min: ref_min, max: ref_max, avg: ref_avg, values: ref_values } = processDataGlobal(ref_data_group);
            ref_data_group = [
                {
                    id: -1,
                    key: -1,
                    text: "no_group",
                    value: -1,
                    measurements: ref_data_group,
                    total: ref_global,
                    min: ref_min,
                    max: ref_max,
                    avg: ref_avg,
                    values: ref_values
                }
            ];
            const { total: comp_global, min: comp_min, max: comp_max, avg: comp_avg, values: comp_values } = processDataGlobal(comp_data_group);
            comp_data_group = comp_data_group
                ? [
                      {
                          id: -1,
                          key: -1,
                          text: "no_group",
                          value: -1,
                          measurements: comp_data_group,
                          total: comp_global,
                          min: comp_min,
                          max: comp_max,
                          avg: comp_avg,
                          values: comp_values
                      }
                  ]
                : null;
            break;
    }

    const isNoGroup = group_by === groupByOptions[0].value;
    const graphic = processGraphic(ref_data_group, comp_data_group, ref_data.unit, isNoGroup);
    return { graphic };
};
