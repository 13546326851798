import React, { useEffect, useMemo, useState } from 'react';

import _ from "lodash";
import { useSelector } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import { Button, Divider, Dropdown, Icon, Modal, Segment } from 'semantic-ui-react';
import { Field, Form } from 'react-final-form';

import i18n from 'modules/i18n/i18nConfig';
import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import { useCreateDiagramMutation, useUpdateDiagramMutation } from '../hierarchyService';

import { DropDownAdapter, InputAdapter, TextAreaAdapter, ToggleAdapter } from 'modules/common/components/form';
import { diagramTypeOptions } from '../utils';


const default_diagram = {
    name: "",
    type: diagramTypeOptions[0].value,
    site: null,
    is_public: false,
    description: ""
};


const DiagramForm = (props) => {
    const { current_diagram, sites, disabled, setSelectedDiagram } = props;
    const [openModal, setOpenModal] = useState(false);
    const { notification, auth, org } = useSelector((state) => state);

    const org_id = _.get(org, 'current.id', null);
    const user_id = _.get(auth, 'user.user_id', null);


    const [createDiagram, create] = useCreateDiagramMutation();
    const [updateDiagram, update] = useUpdateDiagramMutation();

    useEffect(() => {
        if (create.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create diagram`), toast_options);
            if (setSelectedDiagram) setSelectedDiagram(create.data.id);
            setOpenModal(false);
        }
        if (create.isError) {
            toast.error(i18n._(t`cannot create diagram`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError, create.isLoading]);


    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update diagram`), toast_options);
            setOpenModal(false);
        }
        if (update.isError) {
            toast.error(i18n._(t`cannot update diagram`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isSuccess, update.isError, update.isLoading]);


    const onSubmitForm = (data) => {
        if (current_diagram === null) {
            createDiagram({ org: org.current, data });
        } else {
            updateDiagram({ org: org.current, data });
        }
    };

    const resetForm = (form) => {
        form.reset();
        form.getRegisteredFields()
            .forEach(field => {
                form.resetFieldState(field);
            });
    };

    const initialValues = useMemo(() => {
        return {
            ...default_diagram,
            organization: org_id,
            owner: user_id,
            ...current_diagram
        };
    }, [org_id, user_id, current_diagram]);

    return <Form
        onSubmit={onSubmitForm}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
            return (
                <Modal
                    open={openModal}
                    centered={false}
                    closeOnDimmerClick
                    closeOnEscape
                    onClose={(e) => {
                        resetForm(form);
                        setOpenModal(false);
                    }}
                    trigger={
                        <Dropdown.Item
                            onClick={(e) => { setOpenModal(true); }}>
                            {current_diagram === null && (
                                <>
                                    <Icon name="add circle" />
                                    <Trans>Create new diagram</Trans>
                                </>)}
                            {current_diagram !== null && disabled && (
                                <>
                                    <Icon name="eye" />
                                    <Trans>View diagram</Trans>
                                </>
                            )}
                            {current_diagram !== null && !disabled && (
                                <>
                                    <Icon name="edit" />
                                    <Trans>Edit diagram</Trans>
                                </>
                            )}
                        </Dropdown.Item>
                    }>
                    <Modal.Header>
                        {current_diagram === null && (<Trans>Create new diagram</Trans>)}
                        {current_diagram !== null && disabled && (<Trans>View diagram</Trans>)}
                        {current_diagram !== null && !disabled && (<Trans>Edit diagram</Trans>)}
                    </Modal.Header>
                    <Modal.Content>
                        <form onSubmit={handleSubmit} className="ui form">
                            <Field
                                name="name"
                                placeholder={i18n._(t`enter name of diagram`)}
                                label={i18n._(t`name`)}
                                isRequired={true}
                                component={InputAdapter}
                                disabled={disabled}
                                validate={(value) => {
                                    if (!value) {
                                        return <Trans>diagram name is required</Trans>;
                                    }
                                    return undefined;
                                }}
                                onKeyDown={(e) => {
                                    //Hack to prevent parent dropdown tabspace interaction
                                    if (e.keyCode === 32) {
                                        e.stopPropagation();
                                    }
                                }}
                            />
                            <Field
                                name="site"
                                label={i18n._(t`site`)}
                                placeholder={i18n._(t`select site`)}
                                options={_.map(sites, ({ key, text, value }) => ({ key, text, value }))}
                                component={DropDownAdapter}
                                isRequired={true}
                                validate={value => {
                                    if (!_.isFinite(value)) {
                                        return <Trans>site is required</Trans>;
                                    } else if (!_.includes(auth.rights?.sites_rw, value)) {
                                        return <Trans>insufficient site rights</Trans>;
                                    } else {
                                        return undefined;
                                    }
                                }}
                                disabled={current_diagram !== null || disabled}
                                onKeyDown={(e) => {
                                    //Hack to prevent parent dropdown tabspace interaction
                                    if (e.keyCode === 32) {
                                        e.stopPropagation();
                                    }
                                }}
                            />
                            <Field
                                name="type"
                                label={i18n._(t`Diagram type`)}
                                options={diagramTypeOptions}
                                component={DropDownAdapter}
                                search={false}
                                validate={value => {
                                    if (!value) return <Trans>diagram type is required</Trans>;
                                    return undefined;
                                }}
                                //can't change nature of diagram
                                disabled={current_diagram !== null || disabled}
                            />
                            <Field
                                name="description"
                                placeholder={i18n._(
                                    t`enter description of your diagram`
                                )}
                                label={i18n._(t`description`)}
                                component={TextAreaAdapter}
                                onKeyDown={(e) => {
                                    //Hack to prevent parent dropdown tabspace interaction
                                    if (e.keyCode === 32) {
                                        e.stopPropagation();
                                    }
                                }}
                            />
                            <Field
                                name="is_public"
                                label={i18n._(t`Is diagram public?`)}
                                labelposition="right"
                                component={ToggleAdapter}
                                disabled={disabled}
                            />
                            <Divider />
                            <Segment attached basic textAlign='right'>
                                <Button
                                    type="button"
                                    negative
                                    icon
                                    labelPosition="left"
                                    onClick={(e) => {
                                        resetForm(form);
                                        setOpenModal(false);
                                    }}
                                >
                                    <Icon name="arrow left" />
                                    <Trans>cancel</Trans>
                                </Button>
                                {notification.srv_status.db_status === "rw" && (
                                    <Button
                                        positive
                                        icon
                                        labelPosition="right"
                                        type="submit"
                                        disabled={submitting || pristine || invalid}
                                    >
                                        <Icon name="send" />
                                        <Trans>Confirm</Trans>
                                    </Button>
                                )}
                            </Segment>
                        </form>
                    </Modal.Content>
                </Modal>
            );
        }}
    />;
};

export default DiagramForm;