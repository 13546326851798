import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import Datetime from "react-datetime";
import { Button, Divider, Dropdown, Grid, Input, Label, Modal, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { exportTypeOptions, exportFormatOptions, exportInstantOptions } from "modules/export/utils";
import {
    setDisableExport,
    setExportType,
    setExportInstant,
    setExportFormat,
    setMtTypes,
    setStartExport,
    setEndExport,
    setEndBeforeStartExport,
    resetInstantExport
} from "modules/dashboard/dashboardSlice";

import { useCreateInstantExportMutation } from "modules/dashboard/dashboardService";

const InstantExport = (props) => {
    const now = moment();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { dashboard, measurementtype, measurement, dataflow, org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const { instant_export } = dashboard.actions;

    // Used to enable action button on dashboard. (hide/show modal)
    const hasEquipmentsSelected = _.size(dashboard.selected_equipments) > 0;

    const [createInstantExport, create] = useCreateInstantExportMutation();

    useEffect(() => {
        if (open === false) {
            //reset Export params when modal closed
            dispatch(resetInstantExport());
            create.reset(); // reset mutation when Modal close
        } else {
            //update Start/End on each open Modal
            dispatch(setStartExport({ start: now.clone().subtract(7, "days").startOf("days").toISOString(), err: false }));
            dispatch(setEndExport({ end: now.clone().startOf("minute").toISOString(), err: false }));
            if (measurementTypeFromSelected.length === 1) {
                dispatch(setMtTypes([measurementTypeFromSelected?.[0]?.value]));
                dispatch(setDisableExport(false));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, open]);

    if (open === false) {
        //prevent processing below if not open modal
        return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                centered={false}
                trigger={
                    <Button labelPosition="left" icon fluid disabled={!hasEquipmentsSelected}>
                        <Icon name="send" />
                        <Trans>toexport</Trans>
                    </Button>
                }
            />
        );
    }

    const hasTimeIntegral = _.chain(instant_export.parameters.mtTypes)
        .reduce((res, mttypeID) => {
            const mt_type = _.find(measurementtype.measurementtypes, { id: mttypeID });
            if (_.get(mt_type, "datapoint_type") === 3) {
                res.push(mt_type);
            }
            return res;
        }, [])
        .size()
        .gt(0)
        .value();

    //List of measurementtypes used by available measurements on selected equipments
    const measureTypeFromSelectedEqt = _.reduce(
        measurement.measurements,
        (res, measure) => {
            const df = _.find(dataflow.dataflows, { id: measure.dataflow });
            if (df) {
                const eqt = _.find(dashboard.selected_equipments, { id: df.equipment });
                if (eqt) {
                    const mt_type = _.find(measurementtype.measurementtypes, { id: _.get(measure, "measurementtype.id") });
                    if (mt_type) {
                        res.push(mt_type);
                    }
                }
            }
            return res;
        },
        []
    );

    const measurementTypeFromSelected = _.intersectionBy(measurementtype.measurementtypes, measureTypeFromSelectedEqt, "id");

    /* TIME PART */
    const onChangeStart = (date) => {
        if (_.isString(date) || !date.isValid() || date > now) {
            dispatch(setStartExport({ start: date, err: true }));
            dispatch(setDisableExport(true));
        } else {
            dispatch(setStartExport({ start: date.clone().toISOString(), err: false }));
            if (instant_export.parameters.end.err === false && moment(instant_export.parameters.end.end) < date) {
                dispatch(setEndBeforeStartExport(true));
                dispatch(setDisableExport(true));
            } else {
                dispatch(setEndBeforeStartExport(false));
                dispatch(setDisableExport(false));
            }
        }
    };
    const onChangeEnd = (date) => {
        if (_.isString(date) || !date.isValid() || date > now) {
            dispatch(setEndExport({ end: date, err: true }));
            dispatch(setDisableExport(true));
        } else {
            dispatch(setEndExport({ end: date.clone().toISOString(), err: false }));
            if (instant_export.parameters.start.err === false && date < moment(instant_export.parameters.start.start)) {
                dispatch(setEndBeforeStartExport(true));
                dispatch(setDisableExport(true));
            } else {
                dispatch(setEndBeforeStartExport(false));
                dispatch(setDisableExport(false));
            }
        }
    };
    const renderInput = ({ msg, ...rest }, openCalendar, closeCalendar) => (
        <div>
            {rest.error && (
                <Label pointing={"below"} basic color="red">
                    {msg}
                </Label>
            )}
            <Input labelPosition="left" {...rest} />
        </div>
    );
    /* END TIME PART */

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            centered={false}
            trigger={
                <Button labelPosition="left" icon fluid disabled={!hasEquipmentsSelected} secondary={!hasEquipmentsSelected}>
                    <Icon name="send" />
                    <Trans>toexport</Trans>
                </Button>
            }
        >
            <Modal.Header>
                <Trans>instant export</Trans>
            </Modal.Header>
            <Modal.Content>
                <Grid stackable centered>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Trans>date range</Trans>:
                        </Grid.Column>
                        {instant_export.parameters.endBeforeStart && (
                            <Grid.Column width={16}>
                                <MessageDisplay
                                    message={i18n._(t`end after start`)}
                                    level="error"
                                    iconName="warning circle"
                                    isLoading={false}
                                    attached={false}
                                />
                            </Grid.Column>
                        )}
                        <Grid.Column width={5}>
                            {/* DATEPICKER start */}
                            <Datetime
                                locale={current_lng}
                                timeFormat={"HH:mm"}
                                value={
                                    instant_export.parameters.start.err
                                        ? instant_export.parameters.start.start
                                        : moment(instant_export.parameters.start.start)
                                }
                                strictParsing={true}
                                onChange={onChangeStart}
                                renderInput={renderInput}
                                inputProps={{
                                    label: `${i18n._(t`from`)}:`,
                                    error: instant_export.parameters.start.err,
                                    msg: i18n._(t`invalid day`),
                                    fluid: true,
                                    icon: "calendar"
                                }}
                                isValidDate={(current) => current.isBefore(now)}
                            />
                            {/* END DATEPICKER start */}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {/* DATEPICKER end */}
                            <Datetime
                                locale={current_lng}
                                timeFormat={"HH:mm"}
                                value={
                                    instant_export.parameters.end.err ? instant_export.parameters.end.end : moment(instant_export.parameters.end.end)
                                }
                                strictParsing={true}
                                onChange={onChangeEnd}
                                renderInput={renderInput}
                                inputProps={{
                                    label: `${i18n._(t`to`)}:`,
                                    error: instant_export.parameters.end.err,
                                    msg: i18n._(t`invalid day`),
                                    fluid: true,
                                    icon: "calendar"
                                }}
                                isValidDate={(current) => current.isBefore(now)}
                            />
                            {/* END DATEPICKER end */}
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <em>
                                <Trans>instantexport time helper</Trans>
                            </em>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Trans>export_type</Trans>:
                        </Grid.Column>
                        <Grid.Column width={10} textAlign="center">
                            <Dropdown
                                options={_.map(exportTypeOptions, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                                selection
                                fluid
                                value={instant_export.parameters.exportType}
                                onChange={(event, data) => {
                                    dispatch(setExportType(data.value));
                                    dispatch(setExportInstant(data.value === 2 ? false : instant_export.parameters.exportInstant));
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Trans>export_format</Trans>:
                        </Grid.Column>
                        <Grid.Column width={10} textAlign="center">
                            <Dropdown
                                options={_.map(exportFormatOptions, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                                selection
                                fluid
                                value={instant_export.parameters.exportFormat}
                                onChange={(event, data) => {
                                    dispatch(setExportFormat(data.value));
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Trans>measurementtypes</Trans>:
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Dropdown
                                options={_.map(measurementTypeFromSelected, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                                placeholder={i18n._(t`select measurementtypes`)}
                                selection
                                multiple
                                fluid
                                value={instant_export.parameters.mtTypes}
                                onChange={(e, data) => {
                                    dispatch(setMtTypes(data.value));
                                    dispatch(setDisableExport(_.isEmpty(data.value)));
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {instant_export.parameters.exportType === 1 && hasTimeIntegral && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Divider />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Trans>data type</Trans>:
                            </Grid.Column>
                            <Grid.Column width={10} textAlign="center">
                                <Dropdown
                                    options={_.map(exportInstantOptions, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                                    selection
                                    fluid
                                    value={instant_export.parameters.exportInstant}
                                    onChange={(e, data) => {
                                        dispatch(setExportInstant(data.value));
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {create.isError && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MessageDisplay message={i18n._(t`error csv generation`)} level="error" iconName="warning circle" isLoading={false} />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {create.isLoading && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MessageDisplay
                                    message={i18n._(t`csv generation in progress`)}
                                    level="info"
                                    iconName="circle notched"
                                    isLoading={true}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {create.isSuccess && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MessageDisplay message={i18n._(t`csv generation success`)} level="info" iconName="checkmark" isLoading={false} />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    positive
                    onClick={async (e) => {
                        await createInstantExport({
                            org: org.current.name,
                            current_lng,
                            instant_export,
                            measurements: measurement.measurements,
                            dataflows: dataflow.dataflows,
                            equipments: dashboard.selected_equipments
                        });
                        setOpen(false);
                    }}
                    disabled={instant_export.parameters.disable || create.isLoading || create.isError}
                >
                    <Trans>toexport</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default InstantExport;
