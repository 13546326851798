import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid, Header } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";

export const MaxVal = React.memo((props) => {
    const { measure, overview24h } = props;

    if (_.includes(['loading', 'idle'], overview24h.status)) {
        return (
            <MessageDisplay
                message={i18n._(t`loading`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }

    if (overview24h.status === "failed") {
        return <MessageDisplay
            message={i18n._(t`error loading data`)}
            level="error"
            iconName="warning circle"
            isLoading={false}
            attached={false}
        />;
    }

    const overview_data = _.chain(overview24h).get("data.measurements").find({ measure: measure.id }).value();

    if (_.isUndefined(overview_data)) {
        return (
            <MessageDisplay
                message={i18n._(t`no maximum 24h`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    const { max_24h } = overview_data;

    if (_.isNull(max_24h)) {
        return (
            <MessageDisplay
                message={i18n._(t`no maximum 24h`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    const unit = _.get(measure, "display_unit.symbol", "N/A");

    return (
        <Grid stretched centered>
            <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                <Trans>maximum</Trans>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
                <Header as={"h3"}>
                    {`${i18n.number(max_24h, {
                        maximumFractionDigits: 1
                    })} ${unit}`}
                </Header>
            </Grid.Column>
        </Grid>
    );
});

MaxVal.propTypes = {
    overview24h: PropTypes.object.isRequired,
    measure: PropTypes.object.isRequired
};

export default MaxVal;

