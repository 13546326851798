import React from "react";
import _ from "lodash";
import { Segment, Grid, Dropdown, Button, Message, Icon } from "semantic-ui-react";

import { v4 as uuidv4 } from "uuid";
import { Trans } from "@lingui/macro";
import ThresholdItem from "./ThresholdItem";

const alertList = [
    { key: "and", value: "and", text: <Trans>and</Trans>, icone: <Trans>and</Trans> },
    { key: "or", value: "or", text: <Trans>or</Trans>, icone: <Trans>or</Trans> },
    { key: "threshold", value: "threshold", text: <Trans>threshold</Trans>, icone: null }
];

export const upsert = (array, element) => {
    // (1)
    const i = array.findIndex((_element) => _element.id === element.id);
    if (i > -1) array[i] = element; // (2)
    else array.push(element);
};

const removeFromTree = (new_tree, nodeId, except = true) => {
    for (let i = 0; i < new_tree.length; i++) {
        const cur_node = new_tree[i];
        if (cur_node.id === nodeId) {
            _.remove(new_tree, (item) => item.id === cur_node.id);
            for (let j = 0; j < cur_node.children.length; j++) {
                removeFromTree(new_tree, cur_node.children[j]);
            }
        }
        if (except) {
            const new_children = [...cur_node.children];
            _.remove(new_children, (item) => item === nodeId);
            if (new_tree[i]) {
                new_tree[i] = { ...new_tree[i], children: new_children };
            }
        }
    }
};

const TreeItem = (props) => {
    const { node, tree, updateTree, meta, form } = props;

    const hasChildren = _.size(node.children) > 0;
    const father = props.root
        ? null
        : _.find(tree, (item) => {
              return _.includes(item.children, node.id);
          });

    const myErr = _.get(meta.error, node.id);

    const onChangeOperator = (e, { value }) => {
        let current_node = { ...node, type: value };
        const new_tree = [...tree]; //copy of tree for update
        switch (value) {
            case "and":
            case "or":
                if (!hasChildren) {
                    const c1 = { id: uuidv4(), type: null, children: [] };
                    const c2 = { id: uuidv4(), type: null, children: [] };
                    current_node = {
                        ...current_node,
                        children: [c1.id, c2.id]
                    };
                    upsert(new_tree, c1);
                    upsert(new_tree, c2);
                }
                upsert(new_tree, _.omit(current_node, ["measure", "threshold", "operator"]));
                updateTree(new_tree);
                break;
            case "threshold":
                removeFromTree(new_tree, current_node.id, false);
                upsert(new_tree, { ...current_node, children: [], measure: null, operator: ">", threshold: 0 });
                updateTree(new_tree);
                break;
            default:
                break;
        }
    };

    const addChild = () => {
        const new_tree = [...tree];
        const c1 = { id: uuidv4(), type: null, children: [] };
        let current_node = { ...node, children: [...node.children, c1.id] };
        upsert(new_tree, c1);
        upsert(new_tree, current_node);
        updateTree(new_tree);
    };

    const deleteChild = async () => {
        const new_tree = [...tree];
        await removeFromTree(new_tree, String(node.id));
        await updateTree(new_tree);
    };

    return (
        <>
            {myErr && (
                <Segment basic style={{ marginLeft: "20px" }}>
                    <Message warning style={{ display: "block" }}>
                        <Icon name="warning circle" />
                        {myErr}
                    </Message>
                </Segment>
            )}
            <Segment style={{ background: "rgba(0, 151, 19, 0.1)", marginLeft: "20px" }}>
                <Grid>
                    <Grid.Column mobile={9} tablet={2} computer={2}>
                        <Dropdown fluid options={alertList} selection onChange={onChangeOperator} value={node.type} upward />
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={1} computer={1}>
                        <Button.Group>
                            {_.includes(["and", "or"], node.type) && <Button icon="add" onClick={addChild} type="button" />}
                            {props.root !== true && (
                                <Button type="button" icon="trash" color="red" onClick={deleteChild} disabled={_.size(father.children) < 3} />
                            )}
                        </Button.Group>
                    </Grid.Column>
                    {_.includes(["and", "or"], node.type) &&
                        _.map(node.children, (item, idx) => {
                            const child = _.find(tree, { id: item });
                            if (idx === 0) {
                                return (
                                    <Grid.Column width={15} key={child.id}>
                                        <TreeItem node={child} tree={tree} updateTree={updateTree} meta={meta} form={form} />
                                    </Grid.Column>
                                );
                            }
                            return (
                                <React.Fragment key={child.id}>
                                    <Grid.Column width={2}>{_.chain(alertList).find({ value: node.type }).get("icone").value()}</Grid.Column>
                                    <Grid.Column width={15}>
                                        <TreeItem node={child} tree={tree} updateTree={updateTree} meta={meta} form={form} />
                                    </Grid.Column>
                                </React.Fragment>
                            );
                        })}
                    {node.type === "threshold" && (
                        <Grid.Column mobile={16} tablet={12} computer={12}>
                            <ThresholdItem node={node} tree={tree} updateTree={updateTree} meta={meta} form={form} />
                        </Grid.Column>
                    )}
                </Grid>
            </Segment>
        </>
    );
};

export default TreeItem;
