import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Button, Dropdown, Grid, Input } from "semantic-ui-react";
import DropdownMeasurement from "./DropdownMeasurement";
import { upsert } from "./TreeItem";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUsagesQuery } from "modules/usage/usageService";

const operatorList = [
    { key: ">", value: ">", text: ">" },
    { key: "<", value: "<", text: "<" }
];

const ThresholdItem = (props) => {
    const { node, tree, updateTree, form } = props;
    const { org } = useSelector((state) => state);
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const { measurements } = useSelector((state) => state.measurement);
    const measure = _.find(measurements, { id: node.measure });

    const onChangeOperator = (e, data) => {
        const new_tree = [...tree]; //copy of tree for update
        const current_node = { ...node, operator: data.value };
        upsert(new_tree, current_node);
        updateTree(new_tree);
    };

    const onChangeThreshold = (e, data) => {
        const new_tree = [...tree]; //copy of tree for update
        const current_node = { ...node, threshold: data.value };
        upsert(new_tree, current_node);
        updateTree(new_tree);
    };

    return (
        <Grid columns={3} verticalAlign="middle">
            <Grid.Column mobile={16} tablet={9} computer={9}>
                <DropdownMeasurement node={node} tree={tree} updateTree={updateTree} form={form} sites={sites.data} usages={usages.data} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={2} computer={2}>
                <Dropdown fluid selection options={operatorList} onChange={onChangeOperator} value={node.operator} upward />
            </Grid.Column>
            <Grid.Column mobile={9} tablet={3} computer={3}>
                <Input fluid inputMode="decimal" onChange={onChangeThreshold} value={node.threshold} />
            </Grid.Column>
            {_.result(measure, "display_unit", null) !== null && (
                <Grid.Column mobile={4} tablet={2} computer={2}>
                    <Button color="blue" type="button" onClick={null} style={{ cursor: "default" }}>
                        {measure.display_unit.intensive || measure.display_unit.symbol}
                    </Button>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default ThresholdItem;
