import React from 'react';
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Segment, Grid, Header } from 'semantic-ui-react';

import i18n from "modules/i18n/i18nConfig";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import AlertEvents from 'modules/alert/alert_event/components/AlertEvents';
import { useGetMeasurementsQuery } from 'modules/measurement/measurementService';
import { useGetMeasurementtypesQuery } from 'modules/measurement/measurementtypeService';
import { useGetDataflowsQuery } from 'modules/dataflow/dataflowService';
import { useGetSitesQuery } from 'modules/site/siteService';
import { useGetUnitsQuery } from 'modules/unit/unitService';
import { useGetZonesQuery } from 'modules/area/areaService';
import { useGetUsagesQuery } from 'modules/usage/usageService';
import { useGetCategoriesQuery } from 'modules/category/categoryService';
import { useGetTagsQuery } from 'modules/tag/tagService';
import { useGetEquipmentsQuery } from 'modules/equipment/equipmentService';


const PreloadAlertEvents = (props) => {
    const { org } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        { skip: !org.current || categories.data === undefined || tags.data === undefined || sites.data === undefined || zones.data === undefined || usages.data === undefined }
    );

    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        { skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined, units: units.data }
    );

    const error_list = [
        sites.isError,
        units.isError,
        zones.isError,
        usages.isError,
        equipments.isError,
        categories.isError,
        tags.isError,
        measurementtypes.isError,
        dataflows.isError,
        measurements.isError,
    ];

    const status_list = [
        sites.isSuccess,
        units.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        equipments.isSuccess,
        categories.isSuccess,
        tags.isSuccess,
        measurementtypes.isSuccess,
        dataflows.isSuccess,
        measurements.isSuccess,
    ];



    if (_.some(error_list)) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>alertevents management</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
    else if (_.every(status_list)) {
        return <AlertEvents />;
    } else {
        return <MessageDisplay
            message={i18n._(t`loading data`)}
            level="info"
            iconName="circle notched"
            isLoading={true}
        />;
    }

};

export default PreloadAlertEvents;