import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Segment, Header } from "semantic-ui-react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { resetComparison, resetSelectedEquipments } from "modules/dashboard/dashboardSlice";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Back from "modules/common/components/back";
import DetailGraphic from "./DetailGraphic";
import MinAvgMaxGraphic from "./MinAvgMaxGraphic";
import ConsumptionGraphic from "./ConsumptionGraphic";
import ComparisonAction from "../actions/Comparison";

const Comparison = (props) => {
    const dispatch = useDispatch();
    const { dashboard, measurementtype } = useSelector((state) => state);
    const { comparison } = dashboard.actions;

    const mtType = _.find(measurementtype.measurementtypes, { id: dashboard.actions.comparison.parameters.mtType });

    useEffect(() => {
        return () => {
            dispatch(resetSelectedEquipments());
            dispatch(resetComparison());
        };
    }, [dispatch]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {_.size(dashboard.selected_equipments) === 1 && <Trans>comparison one feed</Trans>}
                            {_.size(dashboard.selected_equipments) !== 1 && <Trans>comparison multi feed</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                {dashboard.selected_equipments.length === 0 && (
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={14} computer={14}>
                            <MessageDisplay
                                message={i18n._(t`You need to select equipments in dashboard first`)}
                                level="warning"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {dashboard.selected_equipments.length > 0 && (
                    <>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <ComparisonAction isDashboard={false} />
                            </Grid.Column>
                        </Grid.Row>
                        {comparison.isReady && (
                            <>
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={14} computer={14} textAlign="center" className="pwaGraph">
                                        <Header as="h3" block textAlign="center" attached="top">
                                            <Trans>detail</Trans>
                                        </Header>
                                        <DetailGraphic />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    {_.get(mtType, "datapoint_type") === 3 && (
                                        <Grid.Column mobile={16} tablet={14} computer={7} textAlign="center" className="pwaGraph">
                                            <Header as="h3" block textAlign="center" attached="top">
                                                <Trans>consumptions historic</Trans>
                                            </Header>
                                            <ConsumptionGraphic />
                                        </Grid.Column>
                                    )}
                                    <Grid.Column
                                        mobile={16}
                                        tablet={14}
                                        computer={_.get(mtType, "datapoint_type") === 3 ? 7 : 14}
                                        textAlign="center"
                                        className="pwaGraph"
                                    >
                                        <Header as="h3" block textAlign="center" attached="top">
                                            <Trans>long term trend</Trans>
                                        </Header>
                                        <MinAvgMaxGraphic />
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(Comparison);
