import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";

import { Grid, Segment, Header, Dimmer } from "semantic-ui-react";

import {
    resetFilterWithPage,
    setCategoryFilter,
    setDashboardItemsPerPage,
    setDashboardPage,
    setDashboardStickPage,
    // Filter part
    setReadyFilter,
    setSearchNameFilter,
    setSiteFilter,
    setTagFilter,
    setUsageFilter,
    setZoneFilter,
    // sort Part
    setSortByCategory,
    setSortByUnit,
    setSortOrder,
    setReadySort,
    setSortedEquipments
} from "modules/dashboard/dashboardSlice";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetMachinesQuery } from "modules/machine/machineService";
import { useGetSortedEquipmentsIdsQuery } from "../dashboardService";

import i18n from "modules/i18n/i18nConfig";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { CustomPagination } from "modules/common/components/TableEnhanced";
import DashboardFilter from "modules/dashboard/components/DashboardFilter";
import EquipmentRow from "./EquipmentRow";
import DashboardActions from "./DashboardActions";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const { dashboard, org } = useSelector((state) => state);
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const sortedEquipments = useGetSortedEquipmentsIdsQuery(
        {
            org: org.current,
            category: dashboard.sort.category,
            unit: dashboard.sort.unit,
            isAsc: dashboard.sort.order,
            sites: dashboard.filter.siteFilter
        },
        {
            skip:
                !org.current ||
                dashboard.sort.category === undefined ||
                dashboard.sort.unit === undefined ||
                dashboard.sort.order === undefined ||
                dashboard.filter.siteFilter === undefined
        }
    );

    useEffect(() => {
        (async () => {
            await dispatch(setReadyFilter(false));
            await dispatch(setReadySort(false));
            await dispatch(setSearchNameFilter(dashboard.filter.searchName));
            await dispatch(setSiteFilter(dashboard.filter.siteFilter));
            await dispatch(setZoneFilter(dashboard.filter.zoneFilter));
            await dispatch(setUsageFilter(dashboard.filter.usageFilter));
            await dispatch(setCategoryFilter(dashboard.filter.categoryFilter));
            await dispatch(setTagFilter(dashboard.filter.tagFilter));
            // Sort part
            await dispatch(setSortByCategory(dashboard.sort.category));
            await dispatch(setSortByUnit(dashboard.sort.unit));
            await dispatch(setSortOrder(dashboard.sort.order));
            //Change stick page after filter application.
            dashboard.pagination.stickPage && (await dispatch(setDashboardStickPage(false)));
            await dispatch(setReadyFilter(true));
            await dispatch(setReadySort(true));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const newEqtSorted = _.chain(sortedEquipments?.data)
                .map((id) => {
                    return _.find(dashboard.equipments, { id });
                })
                .compact()
                .value();
            await dispatch(setSortedEquipments(newEqtSorted));
        })();
    }, [sortedEquipments.data, dashboard.equipments, dispatch, machines.data]);

    const totalEquiments = _.size(dashboard?.sorted_equipments);

    const paginateEquiments = dashboard?.sorted_equipments?.slice(
        (dashboard.pagination.page - 1) * dashboard.pagination.itemsPerPage,
        (dashboard.pagination.page - 1) * dashboard.pagination.itemsPerPage + dashboard.pagination.itemsPerPage
    );

    //list of id for last_sensors_values activity request
    const equipment_list =
        dashboard.pagination.itemsPerPage === totalEquiments
            ? ""
            : _.chain(paginateEquiments)
                  .map((eqt) => eqt.id)
                  .toString()
                  .value();

    return (
        <Segment attached>
            <Grid verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Dashboard</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <DashboardFilter sites={sites.data} usages={usages.data} units={units.data} />
                <DashboardActions />
                {!dashboard.filter.ready && !dashboard.sort.ready && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay message={i18n._(t`loading filter`)} level="info" iconName="circle notched" isLoading={true} />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {sortedEquipments.isError && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay message={i18n._(t`no equipments`)} level="warning" iconName="warning circle" isLoading={false} />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {sortedEquipments.isSuccess ? (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Grid padded centered id="pwaDashboardList">
                                {dashboard.filter.ready &&
                                    dashboard.sort.ready &&
                                    _.chain(paginateEquiments)
                                        .map((equipment, idx) => {
                                            let isActive = false;
                                            const first_selected = _.head(dashboard.selected_equipments);
                                            if (first_selected) {
                                                isActive =
                                                    !_.chain(first_selected)
                                                        .get("dataflowspec_set")
                                                        .intersection(_.get(equipment, "dataflowspec_set", []))
                                                        .size()
                                                        .value() > 0 && dashboard.selected_equipments.length !== totalEquiments;
                                            }
                                            return (
                                                <Dimmer.Dimmable key={idx} as={Grid.Row} dimmed={true} stretched>
                                                    <Dimmer inverted active={isActive}>
                                                        <MessageDisplay
                                                            message={i18n._(
                                                                t`The equipment must be of the same category as the other equipment selected`
                                                            )}
                                                            level="warning"
                                                            iconName="warning circle"
                                                            isLoading={false}
                                                        />
                                                    </Dimmer>
                                                    <EquipmentRow
                                                        equipment={equipment}
                                                        sites={sites.data}
                                                        usages={usages.data}
                                                        machines={machines.data}
                                                        equipment_list={equipment_list}
                                                    />
                                                </Dimmer.Dimmable>
                                            );
                                        })
                                        .value()}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                ) : (
                    <Grid.Column width={16}>
                        <MessageDisplay message={i18n._(t`loading equipments`)} level="info" iconName="circle notched" isLoading={true} />
                    </Grid.Column>
                )}

                <Grid.Row>
                    <Grid.Column width={14} textAlign="right">
                        <CustomPagination
                            rowsPerPageOptions={[10, 20, 50, 100, totalEquiments]}
                            rowsPerPage={dashboard.pagination.itemsPerPage}
                            onChangeRowsPerPage={async (e, { value }) => {
                                await dispatch(setReadyFilter(false));
                                await dispatch(setReadySort(false));
                                await dispatch(setDashboardItemsPerPage(parseInt(value, 10)));
                                await dispatch(setReadyFilter(true));
                                await dispatch(setReadySort(true));
                            }}
                            count={_.size(dashboard?.sorted_equipments)}
                            page={dashboard.pagination.page}
                            onChangePage={async (e, data) => {
                                await dispatch(setReadyFilter(false));
                                await dispatch(setReadySort(false));
                                await dispatch(setDashboardPage(parseInt(data.activePage, 10)));
                                await dispatch(setReadyFilter(true));
                                await dispatch(setReadySort(true));
                            }}
                            textItemPerPages={i18n._(t`item per page`)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Dashboard;
