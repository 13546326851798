import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Button, Icon, Grid, Input, Popup } from "semantic-ui-react";

import { djangoAdmin } from "apis/PowerAdapt";
import i18n from "modules/i18n/i18nConfig";
import { setItemsPerPage, setPage, setSearchNameFilter, setStickPage, resetFilterWithPage } from "../apiImportSlice";
import { useGetApiImportsQuery } from "../ApiImportService";
import history_app from "history_app";

import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteApiImport from "./DeleteApiImport";

const ApiImports = () => {
    const [localSearchName, setLocalSearchName] = useState("");

    const current_lng = useSelector((state) => state.i18n.current);
    const { org, auth, notification, apiImports } = useSelector((state) => state);

    const user_rights = auth.rights;
    const getApiImports = useGetApiImportsQuery({ org: org.current }, { skip: !org.current });
    const can_add = notification.srv_status.db_status === "rw" && (user_rights.is_admin || _.size(auth.rights?.sites_rw) > 0);

    const dispatch = useDispatch();

    let err_list = [getApiImports.isError];
    let status_list = [getApiImports.isSuccess];

    useEffect(() => {
        (async () => {
            await setLocalSearchName(apiImports.filter.searchName);
            apiImports.pagination.stickPage && (await dispatch(setStickPage(false)));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "is_active", label: i18n._(t`is_active`), textAlign: "left" },
        { id: "name", label: i18n._(t`Name`), textAlign: "left" },
        { id: "owner_email", label: i18n._(t`owner_email`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const renderIsActive = (item) => {
        return item.is_active === true ? <Icon name="checkmark" color="green" /> : <Icon name="delete" color="red" />;
    };

    const apiImports_list = _.chain(getApiImports.data)
        .reduce((res, item) => {
            if (apiImports.filter.searchName === "") {
                res.push(item);
            } else if (_.includes(item.name.toLowerCase(), apiImports.filter.searchName.toLowerCase())) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const can_actions = user_rights.is_admin || item?.owner === auth.user.user_id;
            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                is_active: {
                    render: <span style={customStyle}>{renderIsActive(item)}</span>,
                    value: +_.get(item, "is_active", null),
                    textAlign: "left",
                    datatype: "number"
                },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                owner_email: {
                    render: <span style={customStyle}>{_.get(item, "owner_email", "-")}</span>,
                    value: _.get(item, "owner_email", "-"),
                    textAlign: "left",
                    datatype: "string"
                },

                actions: {
                    render: (() => (
                        <>
                            {can_actions && (
                                <Button.Group>
                                    <Popup
                                        trigger={
                                            <Button
                                                icon={can_add ? "edit" : "eye"}
                                                onClick={async (e) => {
                                                    await dispatch(setStickPage(true));
                                                    if (notification.srv_status.db_status === "rw") {
                                                        history_app.push(`/imports/api/${item.id}/change`);
                                                    } else {
                                                        history_app.push(`/imports/api/${item.id}/view`);
                                                    }
                                                }}
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            {notification.srv_status.db_status === "rw" && <Trans>Edit API source</Trans>}
                                            {notification.srv_status.db_status === "r" && <Trans>View API source</Trans>}
                                        </Popup.Content>
                                    </Popup>
                                    {notification.srv_status.db_status === "rw" && <DeleteApiImport item={item} canDelete={can_add} />}
                                </Button.Group>
                            )}
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <>
            {(() => {
                if (_.some(err_list)) {
                    return (
                        <Grid>
                            <Grid.Column width={16}>
                                <MessageDisplay
                                    message={i18n._(t`error loading data`)}
                                    level="error"
                                    iconName="warning circle"
                                    isLoading={false}
                                    attached={false}
                                />
                            </Grid.Column>
                        </Grid>
                    );
                } else if (_.every(status_list)) {
                    return (
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search import`)}
                                    onChange={(e, { value }) => {
                                        setLocalSearchName(value);
                                        dispatch(setSearchNameFilter(value));
                                    }}
                                    value={localSearchName}
                                />
                            </Grid.Column>
                            {can_add && (
                                <Grid.Column width={16}>
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            await dispatch(resetFilterWithPage());
                                            history_app.push(`imports/api/add`);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>Add api source</Trans>
                                    </Button>
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            window.location = `${djangoAdmin(current_lng)}pwa_clients/jobactivity/?content_type=apiimport`;
                                        }}
                                    >
                                        <Icon name="bullhorn" />
                                        <Trans>Tasks activity</Trans>
                                    </Button>
                                </Grid.Column>
                            )}
                            {/* Table  */}
                            <Grid.Column width={16}>
                                <TableEnhanced
                                    headCells={headCells}
                                    rows={apiImports_list}
                                    order="asc"
                                    orderBy="name"
                                    page={apiImports.pagination.page}
                                    rowsPerPage={apiImports.pagination.itemsPerPage}
                                    setPage={(page) => {
                                        dispatch(setPage(page));
                                    }}
                                    setItemsPerPage={(items) => {
                                        dispatch(setItemsPerPage(items));
                                    }}
                                    textItemPerPages={i18n._(t`items per page`)}
                                />
                            </Grid.Column>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid>
                            <Grid.Column width={16}>
                                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="spinner" isLoading={true} />
                            </Grid.Column>
                        </Grid>
                    );
                }
            })()}
        </>
    );
};

export default ApiImports;
