import React from "react";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Grid, Button, Popup, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";

export const Alerts = (props) => {
    const { lastValues, machine } = props;

    if (_.includes(["loading", "idle"], lastValues.status)) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }

    if (lastValues.status === "failed") {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    const healthscores = _.chain(lastValues).get(`data.machine[${machine}]`, []).value();

    if (_.size(healthscores) === 0) {
        return <MessageDisplay level="warning" message={i18n._(t`unable to get score`)} iconName="warning circle" isLoading={false} />;
    }

    const data = _.reduce(
        healthscores,
        (res, item) => {
            if (item.value >= 9) {
                res = { ...res, red: (res.red += 1) };
            } else if (item.value >= 7) {
                res = { ...res, orange: (res.orange += 1) };
            }
            return res;
        },
        { orange: 0, red: 0 }
    );

    return (
        <Grid centered>
            <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                <Trans>alerts</Trans>&nbsp;
                <Popup position="top center" trigger={<Icon color="grey" name="question circle" />}>
                    <Popup.Content>
                        <Trans>number of scores currently on alert</Trans>
                    </Popup.Content>
                </Popup>
            </Grid.Column>
            <Grid.Column width={7}>
                <div className="right pwaUsagePercent">
                    <Button color={data.orange ? "orange" : "grey"} circular style={{ cursor: "default" }}>
                        {_.isFinite(data.orange) ? `${i18n.number(data.orange, { maximumFractionDigits: 0 })}` : "-"}
                    </Button>
                    <div className="pwaUsagePercentText">
                        <Trans>To monitor</Trans>
                    </div>
                </div>
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={7}>
                <div className="left pwaUsagePercent">
                    <Button color={data.red ? "red" : "grey"} circular style={{ cursor: "default" }}>
                        {_.isFinite(data.red) ? `${i18n.number(data.red, { maximumFractionDigits: 0 })}` : "-"}
                    </Button>
                    <div className="pwaUsagePercentText">
                        <Trans>Urgently inspect</Trans>
                    </div>
                </div>
            </Grid.Column>
        </Grid>
    );
};

export default Alerts;
