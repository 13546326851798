import React from 'react';
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Segment, Grid, Header } from 'semantic-ui-react';

import i18n from "modules/i18n/i18nConfig";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Exports from 'modules/export/components/Exports';
import { useGetExportsQuery } from 'modules/export/exportService';

const PreloadExports = (props) => {
    const { org } = useSelector((state) => state);

    const expts = useGetExportsQuery({ org: org.current }, { skip: !org.current });

    if (_.some([expts.isError])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>exports management</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

    else if (_.every([expts.isSuccess])) {
        return <Exports />;
    } else {
        return (
            <MessageDisplay
                message={i18n._(t`loading data`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }

};

export default PreloadExports;