import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';

export const dataflowApi = defaultApi.injectEndpoints({
    reducerPath: 'apiDataflow',
    endpoints: (build) => ({
        getDataflows: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {

                const current_org = _.get(org, 'name', null);
                return `dataflows?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ['Dataflows'],
            transformResponse: (response, meta, arg) => {
                const { categories, tags, sites, zones, usages } = arg;
                return _.reduce(response, (res, dataflow) => {
                    const dataflowspec = _.find(categories, { id: dataflow.dataflowspec });
                    const dataflowspec_tech = _.find(categories, { id: dataflow.dataflowspec_tech });
                    const site = _.find(sites, { id: dataflow.site });
                    const zone = _.find(zones, { id: dataflow.zone });
                    const usage = _.find(usages, { id: dataflow.usage }) || null;
                    const df_tags = _.reduce(tags, (res, tag) => {
                        if (_.includes(dataflow.tag_set, tag.id)) {
                            res.push(tag);
                        }
                        return res;
                    }, []);

                    res.push({
                        ...dataflow,
                        dataflowspec,
                        dataflowspec_tech,
                        site,
                        zone,
                        usage,
                        tag_set: df_tags
                    });
                    return res;

                }, []);
            }
        }),
        getLastValues: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, pk_list }) => {
                const current_org = _.get(org, 'name', null);
                return `dataflows/last_values?org=${current_org}&pk_list=${pk_list}&${refreshTimestampUrlParam()}`;
            },
        })

    }),
    overrideExisting: false,
});

export const { useGetDataflowsQuery, useGetLastValuesQuery } = dataflowApi;