import _ from "lodash";
//Don't forget to update this object when dataflowspec django update and/or add
export const mapper = {
    "1": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 12000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "2": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "3": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "4": {
        threshold_num: 3,
        colors: ["blue", "green", "red"],
        initialState: {
            minValue: -50,
            maxValue: 100,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "5": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 500,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "6": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 3000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "7": {
        threshold_num: 3,
        colors: ["red", "green", "blue"],
        initialState: {
            minValue: 0,
            maxValue: 100,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "8": {
        threshold_num: 3,
        colors: ["green", "green", "green"],
        initialState: {
            minValue: 0,
            maxValue: 100,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "9": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 10,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "10": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 50,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "11": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 3000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "12": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 12000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "13": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "14": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "15": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "16": {
        threshold_num: null,
        colors: null,
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    "28": {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1000,
            data: {},
            isFetching: true,
            errors: {}
        }
    }
};

export const mapping_Dftech_measurement = {
    elec: "p_act_import",
    elec_expert: "e_act_counter"
};

/**
 * check dataflow <=> measurementtype mapping for getting specific measurement
 * @function retrieveMeasurementFromMeasurementType
 * @param {object} flow - dataflow object
 * @param {array} measurements - list of measurements for flow
 */
export const retrieveMeasurementFromMeasurementType = (flow, measurements) => {
    if (_.isUndefined(flow)) {
        return undefined;
    }
    if (_.size(measurements) === 0) {
        return undefined;
    }

    const measure = _.chain(measurements);

    if (_.isEqual(flow.dataflowspec_tech.name, "pulse")) {
        /* PULSE CASE */
        /* check dataflowspec instead */
        switch (flow.dataflowspec.name) {
            case "elec":
            case "frigories":
            case "calories":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "p_act_import"))
                    .value();
            case "water":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "water_import"))
                    .value();
            case "gas":
                const elec_gas = measure
                    .find((item) => _.isEqual(item.measurementtype.name, "p_act_import"))
                    .value();
                const water_gas = measure
                    .find((item) => _.isEqual(item.measurementtype.name, "water_import"))
                    .value();
                const nm3_gas = measure
                    .find((item) => _.isEqual(item.measurementtype.name, "index_nm3"))
                    .value();
                return elec_gas || water_gas || nm3_gas || undefined;
            default:
                return undefined;
        }
    } else {
        switch (flow.dataflowspec_tech.name) {
            case "elec":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "p_act_import"))
                    .value();
            case "water":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "water_import"))
                    .value();
            case "temperature":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "temperature"))
                    .value();
            case "solar_irradiance":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "solar_irradiance"))
                    .value();
            case "light":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "light"))
                    .value();
            case "co2":
                return measure.find((item) => _.isEqual(item.measurementtype.name, "co2")).value();
            case "hygrometry":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "hygrometry"))
                    .value();
            case "motion":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "motion"))
                    .value();
            case "pm25":
                return measure.find((item) => _.isEqual(item.measurementtype.name, "pm25")).value();
            case "battery":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "battery"))
                    .value();
            case "elec_expert":
                // Power-modbus cas. We add p_act_import in elec_expert category in backend
                const p_act_import = measure
                    .find((item) => _.isEqual(item.measurementtype.name, "p_act_import"))
                    .value();

                // Display p_act_import if exists
                if (p_act_import) {
                    return p_act_import;
                }

                // Else e_act_counter used
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "e_act_counter"))
                    .value();
            case "gas":
            case "pulse":
            case "frigories":
            case "calories":
                return undefined;
            case "elec_predict":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "current"))
                    .value();
            case "di":
                return measure
                    .find((item) => _.isEqual(item.measurementtype.name, "di"))
                    .value();
            default:
                return undefined;
        }
    }
};
