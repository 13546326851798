import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Menu, Message, Sidebar } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { useGetSiteRightsMutation } from "modules/auth/authService";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { useGetOrganizationsQuery } from "modules/organization/organizationService";
import { useGetUserQuery } from "modules/user/userService";
import { otpVerified, setRights } from "modules/auth/authSlice";
import history_app from "history_app";

import SideBarItems from "./SideBarItems";
import NavBar from "./Navbar";
import RoutingLayer from "./RoutingLayer";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Maintenance from "modules/common/components/Maintenance";
import PasswordChange from "modules/user/components/PasswordChange";

const Main = (props) => {
    const dispatch = useDispatch();
    const [openPassword, setOpenPassword] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const { org, auth } = useSelector((state) => state);
    const organizations = useGetOrganizationsQuery(undefined, { skip: !auth.isAuthenticated });
    const [getSiteRights, rights] = useGetSiteRightsMutation();

    const user = useGetUserQuery(
        { org: org.current, user_id: auth.user?.user_id },
        { skip: !org.current || auth.user?.user_id === undefined || !rights.data?.pw_expired }
    );

    const current_org_id = org.current?.id ?? null;

    useEffect(() => {
        (async () => {
            if (organizations.isSuccess) {
                //Put organization in localstorage. Needed when client close his browser
                const local_org = localStorage.getItem("currentOrg");
                if (!local_org && _.size(organizations.data) > 0) {
                    localStorage.setItem("currentOrg", JSON.stringify(organizations.data?.first));
                    await dispatch(setCurrentOrg(organizations.data?.first));
                }
            }
        })();
    }, [dispatch, organizations]);

    useEffect(() => {
        (async () => {
            if (org.current) {
                //refetch getSiteRights when org.current changes
                await getSiteRights({ org: org.current, user_id: auth.user.user_id });
            }
        })();
        //eslint-disable-next-line
    }, [dispatch, getSiteRights, current_org_id, auth.user.user_id]);

    useEffect(() => {
        if (rights.isSuccess) {
            dispatch(setRights(rights.data));
            if (rights.data?.verified !== true) {
                if (rights.data?.two_factor === true) {
                    if (rights.data?.has_otp !== true) {
                        history_app.push("/otp/setup");
                    } else {
                        history_app.push("/otp/validate");
                    }
                } else {
                    dispatch(otpVerified(true));
                }
            } else {
                dispatch(otpVerified(true));
            }
        }
    }, [dispatch, rights]);

    const toggleSidebar = (state) => {
        setSidebar(state);
    };
    const { path } = props.match;

    return (
        <Sidebar.Pushable>
            <Sidebar
                as={Menu}
                icon="labeled"
                vertical
                animation="overlay"
                visible={sidebar}
                width="thin"
                onHide={() => toggleSidebar(false)}
                onClick={() => toggleSidebar(false)}
            >
                <SideBarItems path={path} />
            </Sidebar>
            <Sidebar.Pusher dimmed={sidebar}>
                <NavBar path={path} toggleSidebar={toggleSidebar} />
                <Maintenance />
                {organizations.isError && (
                    <MessageDisplay message={i18n._(t`error loading organizations`)} level="error" iconName="warning circle" isLoading={false} />
                )}
                {organizations.isSuccess && rights.isSuccess && _.size(organizations.data.organizations) === 0 && (
                    <MessageDisplay
                        message={i18n._(t`You don't have access to any organization`)}
                        level="warning"
                        iconName="warning circle"
                        isLoading={false}
                    />
                )}
                {organizations.isSuccess && rights.isSuccess && _.size(organizations.data.organizations) > 0 && org.current === null && (
                    <MessageDisplay message={i18n._(t`no organization selected`)} level="warning" iconName="warning circle" isLoading={false} />
                )}
                <div className="dimmed dimmable" style={{ minHeight: "800px" }}>
                    {rights.isSuccess && rights.data?.pw_expired === true && (
                        <div className={`ui dimmer active visible pwaPwexpired`}>
                            <div className="content">
                                <Message warning>
                                    <p>
                                        <Trans>
                                            Your password has expired.{" "}
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                    color: "blue",
                                                    textDecoration: "underline"
                                                }}
                                                onClick={() => setOpenPassword(true)}
                                            >
                                                Click here to change it
                                            </span>
                                        </Trans>
                                    </p>
                                </Message>
                            </div>
                        </div>
                    )}
                    {organizations.isSuccess && _.size(organizations.data.organizations) > 0 && org.current !== null && rights.isSuccess && (
                        <RoutingLayer path={path} auth={auth} org={org.current} />
                    )}
                </div>
                {user.data && openPassword && <PasswordChange item={user.data} setPasswordChange={setOpenPassword} getSiteRights={getSiteRights} />}
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

Main.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default React.memo(Main);
