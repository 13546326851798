import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from "lodash";
import { Grid, Segment, Header } from 'semantic-ui-react';
import { t, Trans } from '@lingui/macro';


import i18n from "modules/i18n/i18nConfig";
import { useGetSitesQuery } from 'modules/site/siteService';
import { useGetMeasurementsQuery } from 'modules/measurement/measurementService';
import { useGetEquipmentQuery, useGetEquipmentsQuery } from 'modules/equipment/equipmentService';
import { useGetUnitsQuery } from 'modules/unit/unitService';
import { useGetCategoriesQuery } from 'modules/category/categoryService';
import { useGetZonesQuery } from 'modules/area/areaService';
import { useGetUsagesQuery } from 'modules/usage/usageService';
import { useGetTagsQuery } from 'modules/tag/tagService';
import { useGetMeasurementtypesQuery } from 'modules/measurement/measurementtypeService';
import { useGetDataflowsQuery } from 'modules/dataflow/dataflowService';
import { useGetEquipmenttypesQuery } from 'modules/equipment/equipmenttypeService';
import { useGetSensortypesQuery } from 'modules/sensor/sensorTypeService';
import { useGetMachinesQuery } from 'modules/machine/machineService';
import { useGetMachinetypesQuery } from 'modules/machine/machinetypeService';
import { useGetComponentsQuery } from 'modules/machine/componentService';
import { useGetHealthscoretypesQuery } from 'modules/machine/healthscoretypeService';
import { resetOverview } from '../overviewSlice';

import Back from "modules/common/components/back";
import MessageDisplay from 'modules/common/components/MessageDisplay';
import Overview from 'modules/overview/components/Overview';


const PreloadOverview = (props) => {
    const dispatch = useDispatch();
    const { id_eqpmt } = useParams();
    const { org } = useSelector((state) => state);

    useEffect(() => {
        return async () => {
            await dispatch(resetOverview());
        };
    }, [dispatch]);


    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipmenttypes = useGetEquipmenttypesQuery({ org: org.current }, { skip: !org.current });
    const sensortypes = useGetSensortypesQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current });
    const components = useGetComponentsQuery({ org: org.current }, { skip: !org.current });
    const healthscoretypes = useGetHealthscoretypesQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        { skip: !org.current || categories.data === undefined || tags.data === undefined || sites.data === undefined || zones.data === undefined || usages.data === undefined }
    );
    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        { skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined, units: units.data }
    );

    const equipment = useGetEquipmentQuery({ org: org.current, data: id_eqpmt }, { refetchOnMountOrArgChange: true, skip: !org.current || id_eqpmt === undefined });

    const error_list = [
        measurements.isError,
        measurementtypes.isError,
        dataflows.isError,
        equipments.isError,
        equipmenttypes.isError,
        sites.isError,
        zones.isError,
        usages.isError,
        categories.isError,
        tags.isError,
        machines.isError,
        units.isError,
        sensortypes.isError,
        machinetypes.isError,
        components.isError,
        healthscoretypes.isError
    ];

    const status_list = [
        machines.isSuccess,
        measurements.isSuccess,
        measurementtypes.isSuccess,
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        dataflows.isSuccess,
        equipments.isSuccess,
        equipmenttypes.isSuccess,
        categories.isSuccess,
        sensortypes.isSuccess,
        machinetypes.isSuccess,
        components.isSuccess,
        healthscoretypes.isSuccess
    ];

    if (!id_eqpmt) {
        return (
            <MessageDisplay
                message={i18n._(t`unknow equipment`)}
                level="warning"
                iconName="warning circle"
                isLoading={true}
            />
        );
    }
    else if (_.some([
        ...error_list,
        equipment.isError
    ])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>overview</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

    else if (_.every([
        ...status_list,
        equipment.isSuccess
    ])) {
        return (
            <Overview equipment={equipment.data} />
        );
    }
    else {
        return (
            <MessageDisplay
                message={i18n._(t`loading data`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }

};

export default PreloadOverview;
