import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Dropdown, Modal, Button, Accordion, Table, Segment, Input, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";

import MessageDisplay from "modules/common/components/MessageDisplay";
import GenericPaginate from "modules/common/components/GenericPaginate";
import { Media } from "App";

const DropdownMeasurement = (props) => {
    const { node, formula, idx, input_name, changeForm } = props;

    //Current measure
    const [selectedMeasure, setSelectedMeasure] = useState(node.measure);

    //Pagination
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(5);
    //Filter
    const [searchName, setSearchName] = useState("");
    const [siteFilter, setSiteFilter] = useState([]);
    const [zoneFilter, setZoneFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [usageFilter, setUsageFilter] = useState([]);
    const [tagFilter, setTagFilter] = useState([]);
    const [mttypeFilter, setMttypeFilter] = useState([]);

    const { zone, tag, category, measurement, measurementtype, dataflow, equipment } = useSelector((state) => state);

    useEffect(() => {
        if (open) {
            const eqpt_site = props.site;
            eqpt_site && setSiteFilter([eqpt_site]);
            //Filter Measurements with output category
            const output_cat = props.output_cat;
            _.get(node, "type") === "measure" && output_cat && setCategoryFilter([output_cat]);
            //reset filter && page
            setZoneFilter([]);
            setUsageFilter([]);
            setTagFilter([]);
            if (props?.output_mtType) {
                setMttypeFilter([props.output_mtType]);
            } else {
                setMttypeFilter([]);
            }
            setPage(0);
            setRowPerPage(5);
            setSelectedMeasure(node.measure);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        setPage(0);
    }, [searchName, siteFilter, zoneFilter, categoryFilter, usageFilter, tagFilter, mttypeFilter]);

    const zoneOptions = useMemo(() => {
        return _.chain(zone.zones)
            .reduce((res, zone) => {
                if (_.size(siteFilter) === 0) {
                    res.push(zone);
                } else if (_.includes(siteFilter, zone.site_id)) {
                    res.push(zone);
                }
                return res;
            }, [])
            .map(({ key, text, value }) => ({ key, text, value, content: <Label color="teal">{text}</Label> }))
            .value();
    }, [siteFilter, zone.zones]);

    const siteOptions = useMemo(() => {
        return _.map(props.sites, ({ key, text, value }) => ({ key, text, value, content: <Label color="blue">{text}</Label> }));
    }, [props.sites]);

    const catOptions = useMemo(() => {
        return _.chain(category.categories)
            .reduce((res, { key, text, value, name }) => {
                if (_.includes(["elec", "gas", "water"], name)) {
                    res.push({ key, text: i18n._(text), value, content: <Label color="purple">{i18n._(text)}</Label> });
                }
                return res;
            }, [])
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [category.categories]);

    const mtTypesOptions = useMemo(() => {
        return _.chain(measurementtype.measurementtypes)
            .filter((item) => !_.includes([22, 19, 18, 8, 26, 13, 11, 14, 60], item.id)) // Remove MT_TYPE
            .filter((mt_type) => mt_type.datapoint_type === 3) //time_integral ?
            .filter((mt_type) => _.includes(["p_act_import", "index_nm3", "index_tv", "water_import"], mt_type.name))
            .map(({ key, text, value }) => ({ key, text: i18n._(text), value, content: <Label color="yellow">{i18n._(text)}</Label> }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [measurementtype.measurementtypes]);

    const usagesOptions = useMemo(() => {
        return _.map(props.usages, ({ key, text, value }) => ({ key, text, value, content: <Label color="violet">{text}</Label> }));
    }, [props.usages]);

    const tagsOptions = useMemo(() => {
        return _.map(tag.tags, ({ key, text, value }) => ({ key, text, value, content: <Label color="grey">{text}</Label> }));
    }, [tag.tags]);

    const measurementsOptions = useMemo(() => {
        return _.map(measurement.measurements, ({ key, text, value }) => ({ key, text, value: String(value) }));
    }, [measurement.measurements]);

    const filters = (
        <Grid verticalAlign="middle" centered>
            <Grid.Column width={16}>
                <Input
                    fluid
                    icon="search"
                    placeholder={i18n._(t`search identifier`)}
                    onChange={(e, { value }) => {
                        setSearchName(value);
                    }}
                    value={searchName}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Dropdown
                    fluid
                    options={siteOptions}
                    placeholder={i18n._(t`select sites`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setSiteFilter(value);
                        setZoneFilter([]);
                    }}
                    renderLabel={(label) => ({ color: "blue", content: label.text })}
                    value={siteFilter}
                    disabled={true}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Dropdown
                    fluid
                    disabled={_.size(siteFilter) === 0}
                    options={zoneOptions}
                    placeholder={i18n._(t`select zones`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setZoneFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "teal", content: label.text })}
                    value={zoneFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={catOptions}
                    placeholder={i18n._(t`select categories`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setCategoryFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "purple", content: label.text })}
                    value={categoryFilter}
                    disabled={props?.disable_category}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={mtTypesOptions}
                    placeholder={i18n._(t`select measurementtypes`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setMttypeFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "yellow", content: label.text })}
                    value={mttypeFilter}
                    disabled={props?.disable_mttype}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={usagesOptions}
                    placeholder={i18n._(t`select usages`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setUsageFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "violet", content: label.text })}
                    value={usageFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={tagsOptions}
                    placeholder={i18n._(t`select tags`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setTagFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "grey", content: label.text })}
                    value={tagFilter}
                />
            </Grid.Column>
        </Grid>
    );

    /******  PAGINATION BLOCK ******/

    const onHandlePageChange = (event, data) => {
        // Pagination activePage value
        const { activePage } = data;
        setPage(activePage - 1);
    };

    const onHandleChangeItemPageSize = (event, data) => {
        const { value } = data;
        setPage(0);
        setRowPerPage(parseInt(value));
    };

    const submitMeasurements = () => {
        if (selectedMeasure) {
            const new_formula = _.map(formula, (item, index) => {
                if (index !== idx) {
                    return item;
                }
                return { ...item, measure: selectedMeasure };
            });
            changeForm(input_name, new_formula);
        }
        setOpen(false);
    };

    const measures = useMemo(() => {
        return (
            _.chain(measurement.measurements)
                .reduce((res, measure) => {
                    const df = _.find(dataflow.dataflows, { id: measure.dataflow }) || null;
                    const eqpt = _.find(equipment.init_equipments, { id: _.get(df, "equipment", null) });
                    if (df && eqpt) {
                        const syndataflow = _.get(df, "syndataflow", null);
                        if (_.isNull(syndataflow)) {
                            res.push({
                                ...measure,
                                dataflow: df,
                                equipment: eqpt
                            });
                        }
                    }
                    return res;
                }, [])
                .filter((measure) => _.get(measure, "measurementtype.datapoint_type", null) === 3) //only time_integral ?
                .filter((measure) => _.get(measure, "measurementtype.name", null) !== "operating_hours") // For elec
                .reduce((res, measure) => {
                    if (searchName === "") {
                        res.push(measure);
                    } else if (measure.dataflow && _.includes(measure.dataflow.name.toLowerCase(), searchName.toLowerCase())) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(siteFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(siteFilter, _.get(measure, "dataflow.site.id", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(zoneFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(zoneFilter, _.get(measure, "dataflow.zone.id", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    /* Tags filter */
                    if (_.size(tagFilter) === 0) {
                        res.push(measure);
                        return res;
                    }
                    const count_tags = _.chain(measure)
                        .get("dataflow.tag_set")
                        .map((tag) => {
                            if (_.includes(tagFilter, tag.id)) return 1;
                            return 0;
                        })
                        .sum()
                        .value();
                    if (count_tags) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(usageFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(usageFilter, _.get(measure, "dataflow.usage.id", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(categoryFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(categoryFilter, _.get(measure, "dataflow.dataflowspec.id", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(mttypeFilter) === 0) {
                        res.push(measure);
                        return res;
                    } else if (_.includes(mttypeFilter, _.get(measure, "measurementtype.id"))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .value() || []
        );
    }, [
        measurement.measurements,
        dataflow.dataflows,
        equipment.init_equipments,
        categoryFilter,
        mttypeFilter,
        searchName,
        siteFilter,
        tagFilter,
        usageFilter,
        zoneFilter
    ]);

    const renderMeasures = useMemo(() => {
        return measures.slice(page * rowPerPage, page * rowPerPage + rowPerPage).map((item, idx) => {
            return (
                <Table.Row key={idx} style={{ cursor: "pointer" }}>
                    <Table.Cell
                        positive={selectedMeasure === String(item.id)}
                        onClick={(e) => {
                            setSelectedMeasure(String(item.id));
                        }}
                    >
                        {item.text}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }, [measures, page, rowPerPage, selectedMeasure]);

    return (
        <>
            <Dropdown
                fluid
                open={false}
                selection
                search
                options={measurementsOptions}
                value={node.measure}
                icon={null}
                onFocus={(event, data) => {
                    setOpen(true);
                }}
                error={!_.isUndefined(props.meas_err)}
            />
            <Modal open={open}>
                <Modal.Header>
                    <Trans>choose your measurement</Trans>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <Media lessThan="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Segment id="pwaControls" attached="top">
                                    <Accordion
                                        panels={[
                                            {
                                                key: "filters",
                                                title: i18n._(t`filters`),
                                                content: { content: filters }
                                            }
                                        ]}
                                    />
                                </Segment>
                            )
                        }
                    </Media>
                    <Media greaterThanOrEqual="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Segment id="pwaControls" attached="top">
                                    {filters}
                                </Segment>
                            )
                        }
                    </Media>
                    {_.size(measures) === 0 && (
                        <MessageDisplay message={i18n._(t`no data criteria`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                    <div style={{ overflowX: "auto" }}>
                        <Table striped celled unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="2" textAlign="right">
                                        <GenericPaginate
                                            rowsLength={_.size(measures)}
                                            rowPerPage={rowPerPage}
                                            page={page}
                                            onPageChange={onHandlePageChange}
                                            onChangeItemPageSize={onHandleChangeItemPageSize}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{renderMeasures}</Table.Body>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    {selectedMeasure === null && (
                        <Segment basic textAlign="center">
                            <MessageDisplay message={i18n._(t`select 1 measure`)} level="info" iconName="info circle" isLoading={false} />
                        </Segment>
                    )}
                    <Button
                        type="button"
                        negative
                        onClick={(e, data) => {
                            setOpen(false);
                        }}
                    >
                        <Trans>cancel</Trans>
                    </Button>
                    <Button
                        type="button"
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content={i18n._(t`validate`)}
                        onClick={(e) => {
                            e.preventDefault();
                            submitMeasurements();
                        }}
                        disabled={props.node.measure === selectedMeasure}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default React.memo(DropdownMeasurement);
