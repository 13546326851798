import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _, { isUndefined } from "lodash";
import { Grid, Icon, Divider } from "semantic-ui-react";

import BarGauge from "modules/common/components/graphic/BarGauge";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { dynamicValueUnit } from "modules/data/utils";
import i18n from "modules/i18n/i18nConfig";
import { mapper } from "../mapper";

/**
 * Generate threshold values for BarGauge Component
 * between min and max value
 * @function makeThreshold
 * @params No params
 * @returns {Array} list of threshold value like {color: red, value: 0}
 */
const makeThreshold = (flow, minValue, maxValue) => {
    if (_.isUndefined(flow) || _.isUndefined(minValue) || _.isUndefined(maxValue) || minValue > maxValue) {
        return undefined;
    }

    let res = [];
    const delta = maxValue - minValue;

    for (var i = 0; i < mapper[flow.dataflowspec.id].threshold_num; i++) {
        res.push({
            value: parseFloat((minValue + (delta * i) / mapper[flow.dataflowspec.id].threshold_num).toFixed(2)),
            color: mapper[flow.dataflowspec.id].colors[i]
        });
    }
    return res;
};

export const LastVal = React.memo((props) => {
    const { equipment, measure, lastValues, overview24h } = props;
    const current_lng = useSelector((state) => state.i18n.current);

    const overview_data = _.chain(overview24h).get("data.measurements").find({ measure: measure.id }).value();
    const gaugeNotUpdated = _.chain(overview_data)
        .get("datapoints")
        .map((item) => item.avg_24h)
        .every(_.isFinite)
        .value();

    if (_.includes(["loading", "idle"], lastValues.status)) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} />;
    }

    if (lastValues.status === "failed") {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    const flow = _.chain(equipment).get("dataflows").find({ id: measure.dataflow }).value();
    const thresholds = makeThreshold(flow, measure.minGauge, measure.maxGauge);

    if (_.isUndefined(thresholds)) {
        return <MessageDisplay message="threshold process error" level="warning" iconName="warning circle" isLoading={false} />;
    }
    const last_measurement = _.chain(lastValues)
        .get("data.dataflows")
        .find((item) => {
            return _.isEqual(item.id, measure.dataflow);
        })
        .get("measurements")
        .find({ id: measure.id })
        .value();

    if (_.isUndefined(last_measurement)) {
        return <MessageDisplay message={i18n._(t`unable to get last value`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    const intensive = _.get(last_measurement, "last_intensive");
    const extensive = _.get(last_measurement, "last_extensive");

    // associated intensive unit for extensive or symbol default
    const unit = _.get(measure, "display_unit.intensive") || _.get(measure, "display_unit.symbol");

    const auto_unit = _.get(measure, "auto_unit", true);

    // Dynamic unit for intensive value
    const { factor, new_unit } = dynamicValueUnit(intensive, unit, auto_unit);

    // Dynamic unit for extensive value
    const dyn_for_extensive = dynamicValueUnit(extensive, _.get(measure, "display_unit.symbol"), auto_unit);

    const mt_type_popup = () => {
        switch (_.get(measure, "measurementtype.name")) {
            case "e_act_counter":
            case "p_act_import":
                return i18n.use(current_lng)._(t`p_act`);
            case "water_import":
                return i18n.use(current_lng)._(t`water`);
            default:
                return i18n.use(current_lng)._(_.get(measure, "measurementtype.name"));
        }
    };

    return (
        <Grid
            centered
            stretched
            style={{
                filter: `${gaugeNotUpdated ? "grayscale(100%) " : "none"}`
            }}
        >
            <Grid.Column width={16} className="unpadded">
                <BarGauge
                    value={{
                        text: _.isFinite(intensive)
                            ? `${i18n.number(intensive * factor, {
                                  maximumFractionDigits: 1
                              })} ${new_unit || "N/A"}`
                            : "N/A",
                        numeric: _.isFinite(intensive) ? Math.round((intensive * factor + Number.EPSILON) * 100) / 100 : null
                    }}
                    minValue={measure.minGauge * factor}
                    maxValue={measure.maxGauge * factor}
                    thresholds={_.map(thresholds, (item) => ({
                        ...item,
                        value: item.value * factor
                    }))}
                    height={40}
                    width={200}
                    itemSpacing={2}
                    popupTitle={
                        <>
                            <Trans>
                                <span>last</span>&nbsp;{mt_type_popup}&nbsp;<span>value</span>
                            </Trans>
                            {gaugeNotUpdated && (
                                <>
                                    <Divider />
                                    <div
                                        style={{
                                            filter: "grayscale(0%)"
                                        }}
                                    >
                                        <Icon color="red" size="small" name="power off" />
                                        <Trans>the sensor is disconnected</Trans>
                                    </div>
                                </>
                            )}
                        </>
                    }
                />
            </Grid.Column>
            {!isUndefined(extensive) && (
                <Grid.Column width={16} textAlign="center" className="unpadded">
                    {`Index : ${i18n.number(extensive * dyn_for_extensive.factor, { maximumFractionDigits: 0 })} ${
                        dyn_for_extensive.new_unit || "N/A"
                    }`}
                </Grid.Column>
            )}
        </Grid>
    );
});

LastVal.propTypes = {
    measure: PropTypes.object.isRequired,
    equipment: PropTypes.object.isRequired,
    lastValues: PropTypes.object.isRequired
};

export default LastVal;
