import _ from "lodash";
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { exportApi } from "./exportService";


const initialState = {
    init_exports: [],
    exports: [],
    filter: {
        searchName: "",
        frequencyFilter: [],
        protocolFilter: [],
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
    exp: {
        current: null,
        default: {
            is_active: true,
            name: "",
            description: "",
            frequency: "0",
            export_type: 1,
            format: 1,
            instant_values: false,
            historic: 1,
            hours: "00:00",
            send_protocol: "email",
            mailinglist: [],
            measurements: [],
            ftp_server: "",
            ftp_port: "",
            ftp_user: "",
            ftp_password: ""
        }
    }
};


const exportSlice = createSlice({
    name: "expt",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setFrequencyFilter: (state, action) => {
            state.filter.frequencyFilter = action.payload;
        },
        setProtocolFilter: (state, action) => {
            state.filter.protocolFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.exports = state.init_exports;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(setSearchNameFilter, setFrequencyFilter, setProtocolFilter), (state, action) => {
                const filtered_expt = _.chain(state.init_exports)
                    .reduce((res, expt) => {
                        if (state.filter.searchName === "") {
                            res.push(expt);
                        } else if (
                            _.includes(expt.name.toLowerCase(), state.filter.searchName.toLowerCase())
                        ) {
                            res.push(expt);
                        }
                        return res;
                    }, [])
                    .reduce((res, expt) => {
                        if (_.size(state.filter.frequencyFilter) === 0) {
                            res.push(expt);
                        } else if (
                            _.includes(state.filter.frequencyFilter, expt.frequency)
                        ) {
                            res.push(expt);
                        }
                        return res;
                    }, [])
                    .reduce((res, expt) => {
                        if (_.size(state.filter.protocolFilter) === 0) {
                            res.push(expt);
                        } else if (
                            _.includes(state.filter.protocolFilter, expt.send_protocol)
                        ) {
                            res.push(expt);
                        }
                        return res;
                    }, [])
                    .value();

                state.exports = filtered_expt;
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(exportApi.endpoints.getExports.matchFulfilled, (state, action) => {
                state.init_exports = action.payload;
                state.exports = action.payload;
            })
            .addMatcher(exportApi.endpoints.getExport.matchFulfilled, (state, action) => {
                state.exp.current = action.payload;
            })
            .addMatcher(exportApi.endpoints.deleteExport.matchFulfilled, (state, action) => {
                const id = _.get(action, 'meta.arg.originalArgs.data');
                state.init_exports = _.filter(state.init_exports, (obj) => obj.id !== id);
                state.exports = _.filter(state.exports, (obj) => obj.id !== id);
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setSearchNameFilter, setFrequencyFilter, setProtocolFilter,
    setPage, setItemsPerPage, setStickPage, resetFilterWithPage,
    resetCurrentExport
} = exportSlice.actions;

export default exportSlice.reducer;