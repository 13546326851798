import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import { Grid, Header } from 'semantic-ui-react';

import { getDetail, getSummary, resetTab, setTime } from 'modules/overview/overviewSlice';
import { dateRangeOptions, getTimedelta } from 'modules/time/utils';
import TimePanel from 'modules/common/components/TimePanel';
import MessageDisplay from 'modules/common/components/MessageDisplay';
import i18n from 'modules/i18n/i18nConfig';
import GraphicDetail from '../graphic/GraphicDetail';
import GraphicSummary from '../graphic/GraphicSummary';


const TabCo2 = (props) => {
    const tab = 'tabco2';
    const { measure, default_time } = props;
    const dispatch = useDispatch();
    const { org } = useSelector(state => state);
    const { tabco2, powertime } = useSelector(state => state.overview);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(['24h', '48h', '72h'], item.value));
    const rangeTime = powertime.time ? {
        start: moment(powertime.time.start), end: moment(powertime.time.end), plage: powertime.time.plage
    } : default_time;

    useEffect(() => {
        (async () => {
            if (powertime.time) {
                const { start, end } = powertime.time;
                dispatch(getDetail({ org: org.current, measures: [measure], start, end, tab, type: "detail" }));
                const new_range_time = getTimedelta(start, end);
                dispatch(getSummary({ org: org.current, measure, start: new_range_time.start.format("YYYY-MM-DD"), end: new_range_time.end.format("YYYY-MM-DD"), tab }));
            } else {
                dispatch(getDetail({ org: org.current, measures: [measure], start: default_time.start.toISOString(), end: default_time.end.toISOString(), tab, type: "detail" }));
                const new_range_time = getTimedelta(default_time.start.toISOString(), default_time.end.toISOString());
                dispatch(getSummary({ org: org.current, measure, start: new_range_time.start.format("YYYY-MM-DD"), end: new_range_time.end.format("YYYY-MM-DD"), tab }));
            }
        })();
        return () => {
            dispatch(resetTab(tab));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powertime.time]);

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: 'powertime' }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    {/* CHARGE */}
                    {/*<DetailPanel flow={dataflow} measure={measure} />*/}
                    <Header as="h3" block textAlign="center" attached="top"><Trans>detail</Trans></Header>
                    {_.isUndefined(measure) && (<MessageDisplay message={i18n._(t`unknown measurement`)} level="warning" iconName="warning circle" isLoading={false} />)}
                    {tabco2.detail.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                    {tabco2.detail.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                    {tabco2.detail.status === "succeeded" && (
                        <GraphicDetail
                            time={rangeTime}
                            data={tabco2.detail.data}
                            csvName={_.get(props, 'equipment.name', 'export')}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph" >
                    {/*<MinAvgMaxPanel flow={dataflow} measure={measure} isExtensive={isExtensive}/>*/}
                    <Header as="h3" block textAlign="center" attached="top"><Trans>long term trend</Trans></Header>
                    {_.isUndefined(measure) && (<MessageDisplay message={i18n._(t`unknown measurement`)} level="warning" iconName="warning circle" isLoading={false} />)}
                    {tabco2.summary.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                    {tabco2.summary.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                    {tabco2.summary.status === "succeeded" && (
                        <GraphicSummary
                            time={rangeTime}
                            data={tabco2.summary.data}
                            csvName={_.get(props, 'equipment.name', 'export')}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TabCo2;
