import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Segment, Grid, Header, Button, Input, Icon, Dropdown, Message } from "semantic-ui-react";

import i18n, { options } from "modules/i18n/i18nConfig";
import { resetFilterWithPage, setDayFilter, setItemsPerPage, setPage, setSearchNameFilter, setStickPage } from "modules/alert/alert/alertSlice";
import history_app from "history_app";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteAlert from "modules/alert/alert/components/DeleteAlert";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { checkRights } from "modules/auth/utils";

export const daysOptions = [
    { key: "1", value: 1, text: t`monday` },
    { key: "2", value: 2, text: t`tuesday` },
    { key: "3", value: 3, text: t`wednesday` },
    { key: "4", value: 4, text: t`thursday` },
    { key: "5", value: 5, text: t`friday` },
    { key: "6", value: 6, text: t`saturday` },
    { key: "7", value: 7, text: t`sunday` }
];

const Alerts = (props) => {
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const [localSearchName, setLocalSearchName] = useState("");
    const { alert, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);

    useEffect(() => {
        (async () => {
            await setLocalSearchName(alert.filter.searchName);
            await dispatch(setSearchNameFilter(alert.filter.searchName));
            await dispatch(setDayFilter(alert.filter.dayFilter));
            //Change stick page after filter application.
            alert.pagination.stickPage && (await dispatch(setStickPage(false)));
            setReady(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!ready) {
        return <MessageDisplay message={i18n._(t`loading filter`)} level="info" iconName="circle notched" isLoading={true} />;
    }

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "start_date", label: i18n._(t`start_hour`), textAlign: "left", collapsing: true },
        { id: "end_date", label: i18n._(t`end_hour`), textAlign: "left" },
        { id: "days", label: i18n._(t`days`), textAlign: "left" },
        { id: "user", label: i18n._(t`user`), textAlign: "left" },
        { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const alerts_list = _.chain(alert.alerts)
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const alert_days =
                _.chain(item.days)
                    .map((day) => {
                        return options[current_lng]["shortDays"][day % 7];
                    })
                    .join("\xa0")
                    .value() || null;
            const rights = checkRights(item, auth.rights);

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                start_date:
                    item.validity === 0
                        ? {
                              render: <span style={customStyle}>{_.get(item, "start_date", "-")}</span>,
                              value: _.get(item, "start_date", ""),
                              textAlign: "left",
                              datatype: "string"
                          }
                        : null,
                end_date:
                    item.validity === 0
                        ? {
                              render: <span style={customStyle}>{_.get(item, "end_date", "-")}</span>,
                              value: _.get(item, "end_date", ""),
                              textAlign: "left",
                              datatype: "string"
                          }
                        : null,
                days: {
                    render: (
                        <span style={customStyle}>
                            {item.validity === 0 && alert_days}
                            {item.validity === 1 && (
                                <Message info>
                                    <Trans>Depending on site opening</Trans>
                                </Message>
                            )}
                            {item.validity === 2 && (
                                <Message info>
                                    <Trans>Depending on site closure</Trans>
                                </Message>
                            )}
                        </span>
                    ),
                    value: alert_days || "",
                    textAlign: item.validity === 0 ? "right" : "center",
                    colSpan: item.validity === 0 ? undefined : 3,
                    datatype: "string"
                },
                user: {
                    render: <span style={customStyle}>{_.get(item, "user", "-")}</span>,
                    value: _.get(item, "user", ""),
                    textAlign: "right",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <Button.Group>
                            <Button.Group size="small">
                                <Button
                                    icon="edit"
                                    onClick={async (e) => {
                                        await dispatch(setStickPage(true));
                                        history_app.push(`alerts/${item.id}`);
                                    }}
                                />
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete") && <DeleteAlert item={item} />}
                            </Button.Group>
                        </Button.Group>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>alerts management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search identifier`)}
                                    onChange={(e, { value }) => {
                                        setLocalSearchName(value);
                                        dispatch(setSearchNameFilter(value));
                                    }}
                                    value={localSearchName}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Dropdown
                                    fluid
                                    options={_.map(daysOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                    placeholder={i18n._(t`select days`)}
                                    multiple
                                    selection
                                    onChange={(e, { value }) => {
                                        dispatch(setDayFilter(value));
                                    }}
                                    value={alert.filter.dayFilter}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                {notification.srv_status.db_status === "rw" && (
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            await dispatch(resetFilterWithPage());
                                            history_app.push(`alerts/add`);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>add alert</Trans>
                                    </Button>
                                )}
                                <Button
                                    icon
                                    labelPosition="left"
                                    onClick={async (e) => {
                                        await dispatch(resetFilterWithPage());
                                        history_app.push(`alerts_activity`);
                                    }}
                                >
                                    <Icon name="bullhorn" />
                                    <Trans>alert activity</Trans>
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <TableEnhanced
                                    headCells={headCells}
                                    rows={alerts_list}
                                    textItemPerPages={i18n._(t`items per page`)}
                                    order="asc"
                                    orderBy="name"
                                    page={alert.pagination.page}
                                    rowsPerPage={alert.pagination.itemsPerPage}
                                    setPage={(page) => {
                                        dispatch(setPage(page));
                                    }}
                                    setItemsPerPage={(items) => {
                                        dispatch(setItemsPerPage(items));
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Alerts;
