import React from "react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Grid, Segment, Header, Divider } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMachinesQuery } from "modules/machine/machineService";
import { useGetSortedEquipmentsIdsQuery } from "../dashboardService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Dashboard from "modules/dashboard/components/Dashboard";

const PreloadDashboard = (props) => {
    const { org, dashboard } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        {
            skip:
                !org.current ||
                categories.data === undefined ||
                tags.data === undefined ||
                sites.data === undefined ||
                zones.data === undefined ||
                usages.data === undefined
        }
    );

    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        {
            skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined,
            units: units.data
        }
    );

    const sortedEquipments = useGetSortedEquipmentsIdsQuery(
        {
            org: org.current,
            category: dashboard.sort.category,
            unit: dashboard.sort.unit,
            isAsc: dashboard.sort.order,
            sites: dashboard.filter.siteFilter
        },
        {
            skip:
                !org.current ||
                dashboard.sort.category === undefined ||
                dashboard.sort.unit === undefined ||
                dashboard.sort.order === undefined ||
                dashboard.filter.siteFilter === undefined
        }
    );

    const status_list = [
        sites.isSuccess,
        measurements.isSuccess,
        equipments.isSuccess,
        units.isSuccess,
        categories.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        dataflows.isSuccess,
        measurementtypes.isSuccess,
        machines.isSuccess,
        sortedEquipments.isSuccess
    ];
    const error_list = [
        sites.isError,
        measurements.isError,
        equipments.isError,
        units.isError,
        categories.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        dataflows.isError,
        measurementtypes.isError,
        machines.isError,
        sortedEquipments.isError
    ];

    if (_.some(error_list)) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="top">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>Dashboard</Trans>
                            </Header>
                            <Divider />
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    } else if (_.every(status_list)) {
        return <Dashboard />;
    } else {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }
};

export default PreloadDashboard;
