import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import _ from "lodash";
import { Grid, Segment, Header } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetEquipmentQuery, useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetEquipmenttypesQuery } from "modules/equipment/equipmenttypeService";
import { useGetSensortypesQuery } from "modules/sensor/sensorTypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMachinesQuery } from "modules/machine/machineService";
import { useGetMachinetypesQuery } from "modules/machine/machinetypeService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Equipment from "modules/equipment/components/Equipment";

const PreloadEquipment = (props) => {
    const { id_eqpmt } = useParams(); //id_eqmt === undefined for add
    const { org } = useSelector((state) => state);

    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipmenttypes = useGetEquipmenttypesQuery({ org: org.current }, { skip: !org.current });
    const sensortypes = useGetSensortypesQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current });
    const equipement = useGetEquipmentQuery({ org: org.current, data: id_eqpmt }, { skip: !org.current || id_eqpmt === undefined });

    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        {
            skip:
                !org.current ||
                categories.data === undefined ||
                tags.data === undefined ||
                sites.data === undefined ||
                zones.data === undefined ||
                usages.data === undefined
        }
    );

    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        {
            skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined,
            units: units.data
        }
    );

    const fetching_list = [
        measurements.isFetching,
        measurementtypes.isFetching,
        dataflows.isFetching,
        equipments.isFetching,
        equipmenttypes.isFetching,
        sites.isFetching,
        zones.isFetching,
        usages.isFetching,
        categories.isFetching,
        tags.isFetching,
        machines.isFetching,
        units.isFetching,
        sensortypes.isFetching,
        machinetypes.isFetching,
        equipement.isFetching
    ];

    const error_list = [
        measurements.isError,
        measurementtypes.isError,
        dataflows.isError,
        equipments.isError,
        equipmenttypes.isError,
        sites.isError,
        zones.isError,
        usages.isError,
        categories.isError,
        tags.isError,
        machines.isError,
        units.isError,
        sensortypes.isError,
        machinetypes.isError,
        equipement.isError
    ];

    const status_list = [
        measurements.isSuccess,
        measurementtypes.isSuccess,
        dataflows.isSuccess,
        equipments.isSuccess,
        equipmenttypes.isSuccess,
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        categories.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        machines.isSuccess,
        sensortypes.isSuccess,
        machinetypes.isSuccess
    ];

    if (_.some(error_list)) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>equipment management</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    } else if (_.some(fetching_list)) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    } else if (id_eqpmt && _.every([...status_list, equipement.isSuccess])) {
        return <Equipment current_eqt={equipement.data} sites={sites.data} usages={usages.data} measurements={measurements.data} />;
    } else if (id_eqpmt === undefined && _.every(status_list)) {
        return <Equipment current_eqt={undefined} sites={sites.data} usages={usages.data} measurements={measurements.data} />;
    } else {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }
};

export default PreloadEquipment;
