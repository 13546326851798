import { createSlice } from "@reduxjs/toolkit";
import { defaultLng } from "./i18nConfig";

const initialState = {
    current: localStorage.getItem("lng") || defaultLng,
    options: [
        { key: "en", value: "en", flag: "gb", text: "EN" },
        { key: "fr", value: "fr", flag: "fr", text: "FR" }
        /*{ key: "de", value: "de", flag: "de", text: "DE" },
        { key: "it", value: "it", flag: "it", text: "IT" },
        { key: "fi", value: "fi", flag: "fi", text: "FI" }*/
    ]
};

export const i18nSlice = createSlice({
    name: "i18n",
    initialState,
    reducers: {
        setLng: (state, action) => {
            state.current = action.payload || defaultLng;
        }
    }
});

export const { setLng } = i18nSlice.actions;

export const current_lng = (state) => state.i18n.current;

export default i18nSlice.reducer;
