import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Button, Grid, Checkbox, Popup } from "semantic-ui-react";

import { resetSelectedEquipments, addAllEquipments } from "../dashboardSlice";
import InstantExport from "./actions/InstantExport";
import Comparison from "./actions/Comparison";

const DashboardActions = (props) => {
    const dispatch = useDispatch();
    const { dashboard } = useSelector((state) => state);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const { selected_equipments } = useSelector((state) => state.dashboard);
    const hasEquipmentsSelected = _.size(dashboard.selected_equipments) > 0;

    const handleChange = (e, data) => {
        if (data.checked) {
            dispatch(addAllEquipments(dashboard.equipments));
        } else {
            dispatch(resetSelectedEquipments());
        }
    };

    // unchecking the select all's checkbox if at least one element is unchecked
    useEffect(() => {
        if (selected_equipments.length !== dashboard.equipments.length) {
            setIsAllSelected(false);
        } else {
            setIsAllSelected(true);
        }
    }, [selected_equipments.length, dashboard.equipments.length]);

    // useful incase off all selected equipments => check dataflow_set
    const isSelectedEquipmentsMatch = dashboard.selected_equipments.every((equipment) => {
        const firstSelectedDataflowSet = dashboard?.selected_equipments[0]?.dataflowspec_set ?? [];
        return (
            _.chain(firstSelectedDataflowSet)
                .intersection(equipment?.dataflowspec_set ?? [])
                .size()
                .value() > 0
        );
    });

    return (
        hasEquipmentsSelected && (
            <Grid.Row style={{ padding: 0, margin: ".5rem 0 .5rem 0" }}>
                <Grid.Column mobile={16} computer={11}>
                    <Grid stackable verticalAlign="middle">
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <Checkbox
                                    style={{ padding: "0 0 0 .5rem" }}
                                    checked={isAllSelected}
                                    label="Select All"
                                    onChange={handleChange}
                                    onClick={() => setIsAllSelected(!isAllSelected)}
                                    disabled={dashboard.equipments.length === 0 && hasEquipmentsSelected}
                                />
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Comparison isDashboard={true} isSelectedEquipmentsMatch={isSelectedEquipmentsMatch} />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <InstantExport />
                            </Grid.Column>
                            <Grid.Column>
                                <Popup
                                    content="Reset selected equipments"
                                    trigger={
                                        <Button
                                            disabled={!hasEquipmentsSelected}
                                            basic={!hasEquipmentsSelected}
                                            icon="undo"
                                            fluid
                                            onClick={(e) => dispatch(resetSelectedEquipments())}
                                        />
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        )
    );
};

export default DashboardActions;
