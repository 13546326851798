import { configureStore } from "@reduxjs/toolkit";

import { i18nMiddleware } from "modules/i18n/i18nMiddleware";
import { authMiddleware } from "modules/auth/authMiddleware";
import { orgMiddleware } from "modules/organization/orgMiddleware";
import { notificationMiddleware } from "modules/notification/notificationMiddleware";

import rootReducer from "rootReducer";
import { dashboardMiddleware } from "modules/dashboard/dashboardMiddleware";
import { globalviewMiddleware } from "modules/globalview/globalviewMiddleware";
import { overviewMiddleware } from "modules/overview/overviewMiddleware";
import { siteApi } from "modules/site/siteService";
import { measurementApi } from "modules/measurement/measurementService";
import { unitApi } from "modules/unit/unitService";
import { sensorApi } from "modules/sensor/sensorService";
import { categoryApi } from "modules/category/categoryService";
import { zoneApi } from "modules/area/areaService";
import { usageApi } from "modules/usage/usageService";
import { tagApi } from "modules/tag/tagService";
import { measurementtypeApi } from "modules/measurement/measurementtypeService";
import { dataflowApi } from "modules/dataflow/dataflowService";
import { equipmenttypeApi } from "modules/equipment/equipmenttypeService";
import { machineApi } from "modules/machine/machineService";
import { machinetypeApi } from "modules/machine/machinetypeService";
import { componentApi } from "modules/machine/componentService";
import { componenttypeApi } from "modules/machine/componenttypeService";
import { healthscoretypeApi } from "modules/machine/healthscoretypeService";
import { alertApi } from "modules/alert/alert/alertService";
import { mailingApi } from "modules/mailing/mainlingService";
import { exportApi } from "modules/export/exportService";
import { notificationApi } from "modules/notification/notificationService";
import { analysisAdvancedApi } from "modules/analysisAdvanced/analysisAdvancedService";
import { reportApi } from "modules/report/reportService";
import { userApi } from "modules/user/userService";
import { authApi } from "modules/auth/authService";
import { orgApi } from "modules/organization/organizationService";
import { defaultApi } from "apis/PowerAdapt";
import { commonMiddleware } from "modules/common/commonMiddleware";
import { hierarchyApi } from "modules/hierarchy/hierarchyService";
import { energySavingApi } from "modules/energysaving/energySavingService";
import { calendarApi } from "modules/calendar/calendarService";
import { dashboardDndApi } from "modules/dashboardDragNDrop/dashboardDndService";
import { importApi } from "modules/import/importService";
import { fileImportApi } from "modules/import/fileImportsService";
import { dashboardApi } from "modules/dashboard/dashboardService";
import { apIImportApi } from "modules/import/ApiImportService";

const customMiddleware = [
    authApi.middleware,
    orgApi.middleware,
    userApi.middleware,
    i18nMiddleware.middleware,
    notificationApi.middleware,
    defaultApi.middleware,
    reportApi.middleware,
    siteApi.middleware,
    calendarApi.middleware,
    measurementApi.middleware,
    unitApi.middleware,
    sensorApi.middleware,
    categoryApi.middleware,
    zoneApi.middleware,
    usageApi.middleware,
    tagApi.middleware,
    measurementtypeApi.middleware,
    dataflowApi.middleware,
    equipmenttypeApi.middleware,
    machineApi.middleware,
    machinetypeApi.middleware,
    componentApi.middleware,
    componenttypeApi.middleware,
    healthscoretypeApi.middleware,
    alertApi.middleware,
    mailingApi.middleware,
    exportApi.middleware,
    analysisAdvancedApi.middleware,
    hierarchyApi.middleware,
    energySavingApi.middleware,
    dashboardDndApi.middleware,
    importApi.middleware,
    fileImportApi.middleware,
    dashboardApi.middleware,
    apIImportApi.middleware,
    notificationMiddleware,
    authMiddleware,
    orgMiddleware,
    commonMiddleware,
    dashboardMiddleware,
    globalviewMiddleware,
    overviewMiddleware
];

export default configureStore({
    reducer: {
        ...rootReducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [orgApi.reducerPath]: orgApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [siteApi.reducerPath]: siteApi.reducer,
        [calendarApi.reducerPath]: calendarApi.reducer,
        [measurementApi.reducerPath]: measurementApi.reducer,
        [unitApi.reducerPath]: unitApi.reducer,
        [sensorApi.reducerPath]: sensorApi.reducer,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [zoneApi.reducerPath]: zoneApi.reducer,
        [usageApi.reducerPath]: usageApi.reducer,
        [tagApi.reducerPath]: tagApi.reducer,
        [measurementtypeApi.reducerPath]: measurementtypeApi.reducer,
        [dataflowApi.reducerPath]: dataflowApi.reducer,
        [equipmenttypeApi.reducerPath]: equipmenttypeApi.reducer,
        [machineApi.reducerPath]: machineApi.reducer,
        [machinetypeApi.reducerPath]: machinetypeApi.reducer,
        [componentApi.reducerPath]: componentApi.reducer,
        [componenttypeApi.reducerPath]: componenttypeApi.reducer,
        [healthscoretypeApi.reducerPath]: healthscoretypeApi.reducer,
        [alertApi.reducerPath]: alertApi.reducer,
        [mailingApi.reducerPath]: mailingApi.reducer,
        [exportApi.reducerPath]: exportApi.reducer,
        [analysisAdvancedApi.reducerPath]: analysisAdvancedApi.reducer,
        [reportApi.reducerPath]: reportApi.reducer,
        [hierarchyApi.reducerPath]: hierarchyApi.reducer,
        [energySavingApi.reducerPath]: energySavingApi.reducer,
        [dashboardDndApi.reducerPath]: dashboardDndApi.reducer,
        [importApi.reducerPath]: importApi.reducer,
        [fileImportApi.reducerPath]: fileImportApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [apIImportApi.reducerPath]: apIImportApi.reducer
    },
    // Note: you can include options in the argument of the getDefaultMiddleware function call.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types (ex:generateExport.fulfilled.type)
                ignoredActions: []
            }
        }).concat(customMiddleware),
    devTools: process.env.NODE_ENV !== "production"
});
