import React, { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Dropdown, Icon, Menu, Modal } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { setCurrentOrg } from "../orgSlice";
import { useGetOrganizationsQuery } from "../organizationService";

export const DropdownOrg = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const {
        org: store_org,
        auth: { user }
    } = useSelector((state) => state);

    //Display current organization directly based on localStorage instead of Redux Store
    let org = null;
    if (localStorage.getItem("currentOrg")) {
        org = JSON.parse(localStorage.getItem("currentOrg"));
    }
    const named_org = org?.name;

    useEffect(() => {
        if (named_org) {
            if (store_org.current?.name !== named_org) {
                dispatch(setCurrentOrg(org));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, named_org]);

    const organizations = useGetOrganizationsQuery(undefined, { skip: !user });

    const onChangeOrganisation = (event, data) => {
        const selected = _.find(data.options, { value: data.value });
        if (selected) {
            setOpen(false);
            localStorage.setItem("currentOrg", JSON.stringify(selected));
            history_app.push("/");
            dispatch(setCurrentOrg(selected));
        }
    };

    return (
        <>
            {organizations.isError && (
                <Menu.Item>
                    <Icon name="warning" color="red" />
                </Menu.Item>
            )}
            {organizations.isFetching && (
                <Menu.Item>
                    <Icon loading name="circle notch" />
                </Menu.Item>
            )}
            {organizations.isSuccess && (
                <Modal
                    centered={false}
                    onClose={() => setOpen(false)}
                    onOpen={_.size(organizations.data.organizations) > 1 ? () => setOpen(true) : null}
                    open={open}
                    trigger={
                        <Menu.Item
                            style={
                                _.get(user, "is_superuser", false) === true
                                    ? {
                                          background: `repeating-linear-gradient(
        -55deg,
        #f6ba52,
        #f6ba52 10px,
        #ffd180 10px,
        #ffd180 20px
      )`
                                      }
                                    : {}
                            }
                        >
                            <Icon name="industry" />
                            {_.get(org, "text")}
                            {_.size(organizations.data.organizations) > 1 && <Icon name="sort" />}
                        </Menu.Item>
                    }
                >
                    <Modal.Header>
                        <Trans>Select an organization</Trans>
                    </Modal.Header>
                    <Modal.Content>
                        <Dropdown
                            searchInput={{ autoFocus: true }}
                            selection
                            search
                            fluid
                            selectOnNavigation={false}
                            options={organizations.data.organizations}
                            onChange={onChangeOrganisation}
                            value={_.get(org, "value")}
                            noResultsMessage={i18n._(t`no result found`)}
                        />
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
};

export default DropdownOrg;
