import React, { useEffect } from "react";
import { createMedia } from "@artsy/fresnel";
import { t } from "@lingui/macro";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";


import history from "./history_app";
import i18n from "modules/i18n/i18nConfig";


import PrivateRoute from "modules/common/components/PrivateRoute";
import Main from "modules/layout/Main";
import Login from "modules/auth/components/Login";
import ForgetPassword from "modules/auth/components/ForgetPassword";
import I18nLoader from "modules/i18n/components/i18nLoader";
import { useGetSrvStatusQuery } from "modules/notification/notificationService";
import { useSelector } from "react-redux";




export const AppMedia = createMedia({
    breakpoints: {
        zero: 0,
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        bigScreen: 1600,
        widescreen: 1920
    }
});

export const mediaStyles = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;


const MainRouter = () => {
    let location = useLocation();
    const current_lng = useSelector(state => state.i18n.current);

    useEffect(() => {
        const path = location.pathname;

        const title = path === "/" ? i18n._(t`home`) : i18n._(location.pathname.split('/')[1]);
        document.title = title;
    }, [location, current_lng]);



    return (
        <Switch>
            <Route path="/login" component={Login} exact />
            <Route path="/password/new" component={ForgetPassword} exact />
            <PrivateRoute path="/" component={Main} />
        </Switch>

    );
};




const App = () => {
    useGetSrvStatusQuery(undefined, { pollingInterval: 1000 * 60 * 10 });
    return (
        /*  Main App Router */
        <React.Fragment>
            <style>{mediaStyles}</style>
            <MediaContextProvider>
                <I18nLoader>
                    <Router history={history}>
                        <MainRouter />
                    </Router>
                    <ToastContainer />
                </I18nLoader>
            </MediaContextProvider>
        </React.Fragment>
    );
};

export default App;
