import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { toast } from 'react-toastify';

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteAlertMutation } from "../alertService";
import { useSelector } from "react-redux";

const DeleteAlert = (props) => {
    const [open, setOpen] = useState(false);
    const { org } = useSelector((state) => state);

    const { item } = props;

    const [deleteAlert, { isError, isLoading, isSuccess }] = useDeleteAlertMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`successful delete alert`), toast_options);
        }
        if (isError) {
            toast.error(i18n._(t`cannot delete alert`), toast_options_err);
        }
    }, [isError, isSuccess, isLoading]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon="delete" />}
        >
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete alert {item.name}</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    labelPosition="right"
                    icon
                    positive
                    onClick={async (e) => {
                        await setOpen(false);
                        deleteAlert({ org: org.current, data: item.id });
                    }}
                >
                    <Trans>validate</Trans>
                    <Icon name="trash" inverted />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DeleteAlert.propTypes = {
    item: PropTypes.object.isRequired,
};

export default DeleteAlert;
