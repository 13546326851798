import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Grid, Button } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { useGetConsumptionsQuery } from "modules/measurement/measurementService";

export const UsagePercent = React.memo((props) => {
    const { measure, overview24h } = props;
    const { org, dataflow, dashboard, measurement } = useSelector(state => state);

    const consumptions = useGetConsumptionsQuery({ org: org.current });
    const overview_data = _.chain(overview24h)
        .get("data.measurements")
        .find({ measure: measure.id })
        .value();

    const delta_24h = _.get(overview_data, 'delta_24h', null);

    const { calcByCat, calcByCatZone, category } = useMemo(() => {
        let calcByCat = null;
        let calcByCatZone = null;

        const df = _.find(dataflow.dataflows, { id: measure.dataflow });
        const category = _.get(df, "dataflowspec.name", "");

        if (!_.isFinite(delta_24h) || consumptions.isLoading || consumptions.isFetching || consumptions.isError) {
            return {
                calcByCat,
                calcByCatZone,
                category
            };
        }

        const resCat = _.chain(dashboard.equipments)
            .reduce((res, eqt) => {
                _.chain(measurement.measurements)
                    .filter((measure) => measure.equipment_id === eqt.id && _.get(measure, 'measurementtype.name') === _.get(props.measure, 'measurementtype.name'))
                    .each((measure) => {
                        const conso = _.get(consumptions, `data[${measure.id}]`);
                        if (conso) res.push(conso);
                    })
                    .value();
                return res;
            }, [])
            .sum()
            .value();

        if (resCat > 0) {
            calcByCat = (delta_24h / resCat) * 100;
        }

        //Filter by Cat and by Zone
        const resCatZone =
            _.chain(measurement.measurements)
                .reduce((res, measurement) => {
                    if (measurement.id === measure.id) {
                        res.push(measurement);
                        return res;
                    }
                    const same_zone = _.get(measurement, 'zone') === _.get(measure, 'zone');
                    const same_mt_type = _.get(measurement, 'measurementtype.id') === _.get(measure, 'measurementtype.id');
                    if (same_zone && same_mt_type) {
                        res.push(measurement);
                    }
                    return res;
                }, [])
                .filter((measure) =>
                    _.includes(["e_act_counter", "p_act_import", "water_import", "index Nm3"], _.get(measure, 'measurementtype.name'))
                )
                .map((measure) => {
                    return _.get(consumptions, `data[${measure.id}]`);
                })
                .sum()
                .value();

        if (resCatZone > 0) {
            calcByCatZone = (delta_24h / resCatZone) * 100;
        }

        return {
            calcByCat,
            calcByCatZone,
            category
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delta_24h, consumptions]);

    if (_.includes(['loading', 'idle'], overview24h.status) || consumptions.isLoading || consumptions.isFetching) {
        return (
            <MessageDisplay
                message={i18n._(t`loading`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }

    if (overview24h.status === "failed" || consumptions.isError) {
        return <MessageDisplay
            message={i18n._(t`error loading data`)}
            level="error"
            iconName="warning circle"
            isLoading={false}
            attached={false}
        />;
    }

    if (_.isUndefined(overview_data)) {
        return (
            <MessageDisplay
                message={i18n._(t`no data 24h`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    if (!_.isFinite(delta_24h)) {
        return (
            <MessageDisplay
                message={i18n._(t`no data 24h`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
            />
        );
    }

    return (
        <Grid centered>
            <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                <Trans>weight consumption</Trans> <b>{i18n._(category)}</b>
            </Grid.Column>
            <Grid.Column width={7}>
                <div className="right pwaUsagePercent">
                    <Button color="teal" circular>
                        {!_.isFinite(calcByCatZone) && "- %"}
                        {_.isFinite(calcByCatZone) && `${i18n.number(calcByCatZone, { maximumFractionDigits: 1 })} %`}
                    </Button>
                    <div className="pwaUsagePercentText"><Trans>in area</Trans></div>
                </div>
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={7}>
                <div className="left pwaUsagePercent">
                    <Button color="pink" circular>
                        {!_.isFinite(calcByCat) && "- %"}
                        {_.isFinite(calcByCat) && `${i18n.number(calcByCat, { maximumFractionDigits: 1 })} %`}
                    </Button>
                    <div className="pwaUsagePercentText"><Trans>in selection</Trans></div>
                </div>
            </Grid.Column>
        </Grid>
    );
});

UsagePercent.propTypes = {
    measure: PropTypes.object.isRequired,
    overview24h: PropTypes.object.isRequired,
};

export default UsagePercent;
