import React, { useMemo, useState } from "react";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Dropdown, Icon, Menu, Modal } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

export const DashboardChoice = (props) => {
    const { dashboards, id, setSelectedDashboard, current_dashboard, user_email } = props;
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const items = useMemo(() => {
        if (_.size(dashboards) === 0) {
            return [];
        }
        const group_by_user = _.chain(dashboards).groupBy("owner_email").value();
        const owner_dashboard = _.pick(group_by_user, user_email);
        const other_dashboard = _.omit(group_by_user, user_email);

        return _.reduce(
            { ...owner_dashboard, ...other_dashboard },
            (res, dashboard, email) => {
                if (_.size(dashboard) > 0) {
                    res.push(<Dropdown.Header key={email} icon="user" content={email} />);
                    const remap_dashboard = _.chain(dashboard)
                        .orderBy("default_date", "desc")
                        .reduce((res, item, idx) => {
                            const { key, text, value } = item;
                            if (search === "" || text.toLowerCase().includes(search.toLowerCase())) {
                                res.push(
                                    <Dropdown.Item
                                        key={key}
                                        text={text}
                                        value={value}
                                        icon={idx === 0 && user_email === email ? { name: "home" } : null}
                                        onClick={(e) => {
                                            setSelectedDashboard(item.id);
                                            setSearch("");
                                            setOpen(false);
                                        }}
                                    />
                                );
                            }
                            return res;
                        }, [])
                        .value();
                    if (_.size(remap_dashboard) === 0) {
                        res.push(<Dropdown.Item key={`${email}_null`} text={i18n._(t`no result found`)} />);
                    } else {
                        res.push(...remap_dashboard);
                    }
                }
                return res;
            },
            []
        );
    }, [dashboards, search, user_email, setSelectedDashboard]);

    return (
        <Modal
            centered={false}
            onClose={() => {
                setOpen(false);
                setSearch("");
            }}
            open={open}
            trigger={
                <Menu.Item onClick={(e) => setOpen(true)} disabled={id !== undefined || _.size(dashboards) === 0}>
                    {_.size(dashboards) > 0 && current_dashboard && (
                        <>
                            {current_dashboard?.name ?? "-"}
                            <Icon name="sort" />
                        </>
                    )}
                    {_.size(dashboards) === 0 && <Trans>You have no custom dashboard</Trans>}
                    {_.size(dashboards) > 0 && current_dashboard === undefined && (
                        <>
                            <Trans>Select a dashboard</Trans>
                            <Icon name="sort" />
                        </>
                    )}
                </Menu.Item>
            }
        >
            <Modal.Header>
                <Trans>Select a custom dashboard</Trans>
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    className="selection"
                    searchInput={{ autoFocus: true }}
                    search
                    fluid
                    onSearchChange={(e, data) => {
                        setSearch(data.searchQuery);
                    }}
                >
                    <Dropdown.Menu>{items}</Dropdown.Menu>
                </Dropdown>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(DashboardChoice);
