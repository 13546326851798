import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import cookie from "react-cookies";
import _ from "lodash";
import { defaultLng } from "modules/i18n/i18nConfig";

export const authApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_auth",
    keepUnusedDataFor: 600,
    endpoints: (build) => ({
        loginUser: build.mutation({
            query: ({ data }) => {
                return {
                    url: `/auth/token`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            transformResponse: (response) => {
                return response.access;
            }
        }),
        logoutUser: build.mutation({
            query: () => {
                return {
                    url: `/logout`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: {}
                };
            }
        }),
        getSiteRights: build.mutation({
            query: ({ org, user_id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/siterights/${user_id}`,
                    method: "GET",
                    params: {
                        org: current_org,
                        _: cookie.load("refresh_timestamp")
                    }
                };
            }
            //Uncomment to test expiration
            /*transformResponse: (response) => {
                return { ...response, pw_expired: true };
            }*/
        }),
        fetchResetPassword: build.mutation({
            query: ({ data, lng }) => {
                return {
                    url: `/auth/password_reset?lang=${lng}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            }
        })
    })
});

export const { useLoginUserMutation, useLogoutUserMutation, useGetSiteRightsMutation, useFetchResetPasswordMutation } = authApi;
