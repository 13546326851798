import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";
import { Segment, Grid, Header, Menu, Tab, Icon, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTemperatureFull, faDroplet, faCloud, faViruses } from "@fortawesome/free-solid-svg-icons";

import i18n from "modules/i18n/i18nConfig";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { getLastValues } from "../overviewSlice";
import { roundedDate } from "modules/time/utils";

import Back from "modules/common/components/back";
import Description from "modules/overview/description/components/Description";
import { Media } from "App";
import TabTemperature from "./tabs/TabTemperature";
import TabCo2 from "./tabs/TabCo2";
import TabHygro from "./tabs/TabHygro";
import TabPm25 from "./tabs/TabPm25";
import TabEnergy from "./tabs/TabEnergy";
import TabElecDistri from "./tabs/TabElecDistri";
import TabElecState from "./tabs/TabElecState";
import TabElecOperationnal from "./tabs/TabElecOperationnal";
import Infos from "modules/dashboard/components/widgets/Infos";
import DispoInfo from "modules/dashboard/components/widgets/DispoInfo";
import TabPredictSupplyAggregate from "./tabs/TabPredictSupplyAggregate";
import TabPredictOperatingPoints from "./tabs/TabPredictOperatingPoints";
import TabPredictHealthScore from "./tabs/TabPredictHealthScore";
import TabPumpEfficiency from "./tabs/TabPumpEfficiency";
import TabPumpMonitoring from "./tabs/TabPumpMonitoring";

const mt_type_elec_distri = [
    "voltage_detected",
    "voltage_imbalance",
    "sag",
    "sag_ph1",
    "sag_ph2",
    "sag_ph3",
    "swell",
    "swell_ph1",
    "swell_ph2",
    "swell_ph3",
    "trip",
    "i_moy_max",
    "i_moy_max_ph1",
    "i_moy_max_ph2",
    "i_moy_max_ph3"
];
const mt_type_elec_state = ["thd", "thd_ph1", "thd_ph2", "thd_ph3", "current_imbalance", "i0_ratio", "power_imbalance", "i_peak"];

const mt_type_elec_operationnal = ["p_act_import", "operating_hours", "cycles"];

const iconStyle = {
    margin: "0em 0.35714286em 0em 0em"
};

const Overview = (props) => {
    const dispatch = useDispatch();
    const { equipment } = props;
    const { category, machine, component, machinetype, equipmenttype, measurement } = useSelector((state) => state);
    const { org } = useSelector((state) => state);
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const my_machine = _.find(machine.machines, { id: _.get(equipment, "machine.id", null) });
    const my_components = _.filter(component.components, { machine: _.get(my_machine, "id", null) });
    const my_machinetype = _.find(machinetype.machinetypes, { value: _.get(my_machine, "machine_type", null) });
    const my_equipmenttype = _.find(equipmenttype.equipmenttypes, { value: _.get(equipment, "equipment_type", null) });

    const is_pump = _.includes([7], _.get(my_machinetype, "value")); // Only check centrifugal_pump (id:7)
    const pump_comp = _.find(my_components, { comp_type: 6 });
    const pump_description_empty = _.isEmpty(pump_comp?.description?.Hi ?? []);

    //use to manage index of tab
    const [tabIndex, setTabIndex] = useState(0);

    //list of id for last_sensors_values activity request
    const dataflow_list = _.chain(equipment)
        .get("dataflow_set")
        .map((df) => df.id)
        .toString()
        .value();

    const now = moment();
    const powertime = {
        start: roundedDate(now.clone().subtract(7, "days").startOf("days"), 10),
        end: roundedDate(now.clone().startOf("minute"), 10),
        plage: "7d"
    };
    const predicttime = {
        start: roundedDate(now.clone().subtract(7, "days").startOf("days"), 10),
        end: roundedDate(now.clone().startOf("minute"), 10),
        plage: "7d"
    };

    useEffect(() => {
        dataflow_list && dispatch(getLastValues({ dataflow_list }));
    }, [dispatch, dataflow_list]);

    const eqt_categories = _.chain(equipment)
        .get("dataflowspec_set")
        .reduce((res, cat) => {
            const full_cat = _.find(category.categories, { id: cat });
            if (full_cat) res.push(full_cat);
            return res;
        }, [])
        .map((category) => category.name)
        .value();

    let eqt_measurements = _.chain(measurement.measurements)
        .reduce((res, measure) => {
            if (measure.equipment_id === _.get(equipment, "id")) res.push(measure);
            return res;
        }, [])
        .value();

    // For energy Tab panel
    const has_eact_counter =
        _.chain(eqt_measurements)
            .filter((measure) => _.get(measure, "measurementtype_tech.name") === "e_act_counter")
            .size()
            .value() > 0;
    // Here Prevent conflict with PE6 expert/non expert
    eqt_measurements = _.reduce(
        eqt_measurements,
        (res, measure) => {
            if (
                has_eact_counter &&
                _.includes(["p_act_import", "p_react_import", "p_react_import+", "p_react_import-"], _.get(measure, "measurementtype_tech.name"))
            ) {
                return res;
            } else {
                res.push(measure);
            }
            return res;
        },
        []
    );

    const elec_distri_measurement = _.reduce(
        eqt_measurements,
        (res, measure) => {
            if (_.includes(mt_type_elec_distri, _.get(measure, "measurementtype.name"))) {
                res.push(measure);
            }
            return res;
        },
        []
    );

    const elec_state_measurement = _.reduce(
        eqt_measurements,
        (res, measure) => {
            if (_.includes(mt_type_elec_state, _.get(measure, "measurementtype.name"))) {
                res.push(measure);
            }
            return res;
        },
        []
    );

    const elec_operationnal_measurement = _.reduce(
        eqt_measurements,
        (res, measure) => {
            if (_.includes(mt_type_elec_operationnal, _.get(measure, "measurementtype.name"))) {
                res.push(measure);
            }
            return res;
        },
        []
    );

    const current_equipment = {
        ...equipment,
        equipment_type: my_equipmenttype,
        machine: _.get(equipment, "machine", null)
            ? {
                  ...my_machine,
                  machine_type: my_machinetype,
                  components: my_components
              }
            : null
    };

    /**
     * Display tab list for specific dataflowspec
     * visible - @array List of dataflowspec able to display tab
     * menuItem - @object - React element display on Tab
     * render - @function - Content display when click on tab
     */
    const panes = [
        {
            visible: ["co2"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="co2 detail" {...props}>
                        <Media lessThan="computer">
                            <Popup content={i18n._(t`co2`)} trigger={<FontAwesomeIcon icon={faCloud} className="icon" style={iconStyle} />} />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup content={i18n._(t`co2`)} trigger={<FontAwesomeIcon icon={faCloud} className="icon" style={iconStyle} />} />
                            )}
                            {active && (
                                <>
                                    <FontAwesomeIcon icon={faCloud} className="icon" style={iconStyle} />
                                    <Trans>co2</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <FontAwesomeIcon icon={faCloud} className="icon" style={iconStyle} />
                            <Trans>co2</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabCo2
                        equipment={current_equipment}
                        measure={_.find(eqt_measurements, (measure) => {
                            return _.get(measure, "measurementtype.name") === "co2";
                        })}
                        default_time={powertime}
                    />
                </Tab.Pane>
            )
        },
        {
            visible: ["temperature"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="temperature detail" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`temperature`)}
                                trigger={<FontAwesomeIcon icon={faTemperatureFull} className="icon" style={iconStyle} />}
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`temperature`)}
                                    trigger={<FontAwesomeIcon icon={faTemperatureFull} className="icon" style={iconStyle} />}
                                />
                            )}
                            {active && (
                                <>
                                    <FontAwesomeIcon icon={faTemperatureFull} className="icon" style={iconStyle} />
                                    <Trans>temperature</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <FontAwesomeIcon icon={faTemperatureFull} className="icon" style={iconStyle} />
                            <Trans>temperature</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabTemperature
                        equipment={current_equipment}
                        measure={_.find(eqt_measurements, (measure) => {
                            return _.get(measure, "measurementtype.name") === "temperature";
                        })}
                        default_time={powertime}
                    />
                </Tab.Pane>
            )
        },
        {
            visible: ["hygrometry"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="hygrometry detail" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`hygrometry`)}
                                trigger={<FontAwesomeIcon icon={faDroplet} className="icon" style={iconStyle} />}
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`hygrometry`)}
                                    trigger={<FontAwesomeIcon icon={faDroplet} className="icon" style={iconStyle} />}
                                />
                            )}
                            {active && (
                                <>
                                    <FontAwesomeIcon icon={faDroplet} className="icon" style={iconStyle} />
                                    <Trans>hygrometry</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <FontAwesomeIcon icon={faDroplet} className="icon" style={iconStyle} />
                            <Trans>hygrometry</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabHygro
                        equipment={current_equipment}
                        measure={_.find(eqt_measurements, (measure) => {
                            return _.get(measure, "measurementtype.name") === "hygrometry";
                        })}
                        default_time={powertime}
                    />
                </Tab.Pane>
            )
        },
        {
            visible: ["pm25"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="pm25 detail" {...props}>
                        <Media lessThan="computer">
                            <Popup content={i18n._(t`pm25`)} trigger={<FontAwesomeIcon icon={faViruses} className="icon" style={iconStyle} />} />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup content={i18n._(t`pm25`)} trigger={<FontAwesomeIcon icon={faViruses} className="icon" style={iconStyle} />} />
                            )}
                            {active && (
                                <>
                                    <FontAwesomeIcon icon={faViruses} className="icon" style={iconStyle} />
                                    <Trans>pm25</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <FontAwesomeIcon icon={faViruses} className="icon" style={iconStyle} />
                            <Trans>pm25</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPm25
                        equipment={current_equipment}
                        measure={_.find(eqt_measurements, (measure) => {
                            return _.get(measure, "measurementtype.name") === "pm25";
                        })}
                        default_time={powertime}
                    />
                </Tab.Pane>
            )
        },
        {
            visible: ["elec_predict"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="score representation" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`score representation`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="heartbeat" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`score representation`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="cog" />
                                            <Icon corner="top right" name="heartbeat" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="heartbeat" />
                                    </Icon.Group>
                                    <Trans>score representation</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="cog" />
                                <Icon corner="top right" name="heartbeat" />
                            </Icon.Group>
                            <Trans>score representation</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPredictHealthScore equipment={current_equipment} default_time={predicttime} />
                </Tab.Pane>
            )
        },
        {
            visible: ["elec_predict"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="predict operational" {...props}>
                        <Media lessThan="computer">
                            <Popup content={i18n._(t`your clusters`)} trigger={<Icon name="cogs" />} />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        {!active && <Popup content={i18n._(t`your clusters`)} trigger={<Icon name="cogs" />} />}
                                        {active && (
                                            <>
                                                <Icon name="cogs" />
                                                <Trans>your clusters</Trans>
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        <Icon name="cogs" />
                                        <Trans>your clusters</Trans>
                                    </>
                                )
                            }
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPredictOperatingPoints equipment={current_equipment} default_time={predicttime} />
                </Tab.Pane>
            )
        },
        {
            visible: ["elec", "gas", "calories", "frigories", "water"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="energetic efficiency" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`energetic efficiency`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`energetic efficiency`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                    <Trans>energetic efficiency</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="building" />
                                <Icon corner="top right" name="bolt" />
                            </Icon.Group>
                            <Trans>energetic efficiency</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabEnergy
                        equipment={current_equipment}
                        measures={_.filter(eqt_measurements, (measure) => {
                            return _.includes(
                                ["p_act_import", "p_react_import", "p_react_import+", "p_react_import-", "water_import", "index_nm3", "index_tv"],
                                _.get(measure, "measurementtype.name")
                            );
                        })}
                        default_time={powertime}
                    />
                </Tab.Pane>
            )
        },
        {
            visible: _.size(elec_distri_measurement) === 0 ? [] : ["elec"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="electrical distribution" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`electrical distribution`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="hubspot" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`electrical distribution`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="hubspot" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="hubspot" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                    <Trans>electrical distribution</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="hubspot" />
                                <Icon corner="top right" name="bolt" />
                            </Icon.Group>
                            <Trans>electrical distribution</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabElecDistri equipment={current_equipment} measures={elec_distri_measurement} default_time={powertime} />
                </Tab.Pane>
            )
        },
        {
            visible: _.size(elec_distri_measurement) === 0 ? [] : ["elec"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="power operational" {...props}>
                        <Media lessThan="computer">
                            <Popup content={i18n._(t`operational`)} trigger={<Icon name="cogs" />} />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && <Popup content={i18n._(t`operational`)} trigger={<Icon name="cogs" />} />}
                            {active && (
                                <>
                                    <Icon name="cogs" />
                                    <Trans>operational</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon name="cogs" />
                            <Trans>operational</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabElecOperationnal equipment={current_equipment} measures={elec_operationnal_measurement} default_time={powertime} />
                </Tab.Pane>
            )
        },
        {
            visible: ["elec_predict"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="predict supply state" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`predict supply state`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="bolt" />
                                        <Icon corner="top right" name="sitemap" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        {!active && (
                                            <Popup
                                                content={i18n._(t`predict supply state`)}
                                                trigger={
                                                    <Icon.Group>
                                                        <Icon name="bolt" />
                                                        <Icon corner="top right" name="sitemap" />
                                                    </Icon.Group>
                                                }
                                            />
                                        )}
                                        {active && (
                                            <>
                                                <Icon.Group>
                                                    <Icon name="bolt" />
                                                    <Icon corner="top right" name="sitemap" />
                                                </Icon.Group>
                                                <Trans>predict supply state</Trans>
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="bolt" />
                                            <Icon corner="top right" name="sitemap" />
                                        </Icon.Group>
                                        <Trans>predict supply state</Trans>
                                    </>
                                )
                            }
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPredictSupplyAggregate equipment={current_equipment} default_time={predicttime} />
                </Tab.Pane>
            )
        },
        {
            visible: is_pump ? ["elec", "elec_predict"] : [],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="pump efficiency" {...props} disabled={pump_comp === undefined}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`pump efficiency`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`pump efficiency`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="cog" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                    <Trans>pump efficiency</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="cog" />
                                <Icon corner="top right" name="bolt" />
                            </Icon.Group>
                            <Trans>pump efficiency</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPumpEfficiency equipment={current_equipment} default_time={powertime} pump_description_empty={pump_description_empty} />
                </Tab.Pane>
            )
        },
        {
            visible: is_pump ? ["elec", "elec_predict"] : [],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="pump operation" {...props} disabled={pump_comp === undefined}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`pump operation`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`pump operation`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="cog" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                    <Trans>pump operation</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="cog" />
                                <Icon corner="top right" name="bolt" />
                            </Icon.Group>
                            <Trans>pump operation</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPumpMonitoring equipment={current_equipment} default_time={powertime} pump_description_empty={pump_description_empty} />
                </Tab.Pane>
            )
        },
        {
            visible: _.size(elec_state_measurement) === 0 ? [] : ["elec"],
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="electrical state" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`electrical state`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="bolt" />
                                        <Icon corner="top right" name="heartbeat" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`electrical state`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="bolt" />
                                            <Icon corner="top right" name="heartbeat" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="bolt" />
                                        <Icon corner="top right" name="heartbeat" />
                                    </Icon.Group>
                                    <Trans>electrical state</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="bolt" />
                                <Icon corner="top right" name="heartbeat" />
                            </Icon.Group>
                            <Trans>electrical state</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabElecState equipment={current_equipment} measures={elec_state_measurement} default_time={powertime} />
                </Tab.Pane>
            )
        },
        {
            visible: null,
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="description" {...props}>
                        <Media lessThan="computer">
                            <Popup content={i18n._(t`Summary`)} trigger={<Icon name="info" />} />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && <Popup content={i18n._(t`Summary`)} trigger={<Icon name="info" />} />}
                            {active && (
                                <>
                                    <Icon name="info" />
                                    <Trans>Summary</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon name="info" />
                            <Trans>Summary</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <Description equipment={current_equipment} />
                </Tab.Pane>
            )
        }
    ];

    let filteredPanes = _.chain(panes)
        .filter((item) => {
            if (item.visible === null) return true;
            const common_categories = _.intersection(item.visible, eqt_categories);
            return _.size(common_categories) > 0;
        })
        .value();

    if (my_machine && my_machine?.is_predict === true) {
        //For predict we put 'summary' tab in first position
        const descriptionTab = filteredPanes.pop();
        filteredPanes.unshift(descriptionTab);
    }

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row>
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="center">
                        <Header as="h2">{_.get(equipment, "name", "-")}</Header>
                    </Grid.Column>
                    {/* ----- infos column ----- */}
                    {/* mobile */}
                    <Media lessThan="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Grid.Column mobile={5} floated="right" className="unpadded print-hidden">
                                    <Segment compact basic floated="right" className="nomargin">
                                        <Infos equipment={equipment} display="button" sites={sites.data} usages={usages.data} />
                                        <DispoInfo equipment={equipment} display="button" equipment_list={equipment?.id?.toString()} />
                                    </Segment>
                                </Grid.Column>
                            )
                        }
                    </Media>
                    {/* computer or wider */}
                    <Media greaterThanOrEqual="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Grid.Column computer={4} textAlign="right" className="no-print">
                                    <Infos equipment={equipment} display="label" sites={sites.data} usages={usages.data} />
                                </Grid.Column>
                            )
                        }
                    </Media>
                    <Media greaterThanOrEqual="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Grid.Column computer={2} className="print-hidden" textAlign="center">
                                    <DispoInfo equipment={equipment} display="button" equipment_list={equipment?.id?.toString()} />
                                </Grid.Column>
                            )
                        }
                    </Media>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Tab
                            panes={filteredPanes}
                            activeIndex={tabIndex}
                            onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
                            menu={{ attached: true, tabular: true, className: "pwaOverviewTabMenu" }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Overview;
